import React, {useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux'

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import {setCongressYears} from '../../../../Action'



export function DatePickerCongress(){
  const date = useSelector((state) => state.congress.congressYears);
  const [value, setValue] = React.useState(new Date(date));
  const dispatch = useDispatch();


  useEffect(() => {
      dispatch(setCongressYears(value));
    }, [value, dispatch]);
    

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
       <DatePicker
        views={['year']}
      label="-"
      value={value}
      onChange={setValue}
      renderInput={(params) => <TextField {...params} />}
    />
  </LocalizationProvider>
  )
}
