import React  from 'react';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';

  const colorSecondary = {
    "& .Mui-checked + .MuiSwitch-track": {
      backgroundColor: '#f16739'
    },
    "& .MuiSwitch-track" : {
      backgroundColor: '#f16739'
    },
    color: 'success.main',
   '& .MuiSwitch-thumb' : {
      backgroundColor: '#f16739'
    }
  }


export function SliderTweetsDisplay(props) {
  const {switch_content, set_switch_content} = props;  

  const handleChange = (event) => {
    set_switch_content(!switch_content);
  };

  return (
      <div className='slider'>
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item>Tweets</Grid>
          <Grid item>
            <Switch 
            color='default'
           sx={colorSecondary}
         value="tweets" checked={switch_content} onChange={handleChange} name="tweets" />
          </Grid>
          <Grid item>Shared Content</Grid>
        </Grid>
    </div>
  );
}