const initialState = {
  congressAllName: [],
  congressTreemapDrug: [],
  congressDrug: [],
  conferenceDrug: [],
  conferenceRSS: [],
  congressYears: new Date(),
  congressName: null,
};

export function getCongressBQ(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "GET_ALL_CONGRESS":
      nextState = {
        ...state,
        congressAllName: action.value,
      };
      return nextState || state;

    case "SET_CONGRESS_YEARS":
      nextState = {
        ...state,
        congressYears: action.value,
      };
      return nextState || state;

    case "SET_CONGRESS_NAME":
      nextState = {
        ...state,
        congressName: action.value,
      };
      return nextState || state;

    case "GET_CONGRESS_DRUG":
      nextState = {
        ...state,
        congressDrug: action.value,
      };
      return nextState || state;

    case "GET_CONGRESS_TREEMAP":
      nextState = {
        ...state,
        congressTreemapDrug: action.value,
      };
      return nextState || state;

    case "GET_DRUG_CONFERENCE":
      nextState = {
        ...state,
        conferenceDrug: action.value,
      };
      return nextState || state;

    case "GET_RSS_CONFERENCE":
      nextState = {
        ...state,
        conferenceRSS: action.value,
      };
      return nextState || state;

    case "ERROR_DATA_CONGRESS":
      return state;
    default:
      return state;
  }
}
