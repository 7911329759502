import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  AreaBarChartVolume,
  LoadingAnimation,
  MUItoogleBtn,
} from "../../components";

import {
  getCompetitiveGraph,
  setcompetitiveGraphLoadingAnimation,
  getCompetitiveGraphPersonas,
  getCompetitiveGraphDisease,
} from "../../Action";

import { getParamValueTabs } from "../../utils";

export function CompetitiveVolumeGraph(props) {
  const dispatch = useDispatch();
  const { my_className, is_percentage } = props;
  const id_drug_competive = useSelector(
    (state) => state.competitive.drugChooseForCompetitive
  );
  const disease = useSelector((state) => state.diease.diseaseDisplay);
  const data = useSelector((state) => state.competitive.competitiveGraphData);
  const load = useSelector((state) => state.config.graphCompetitiveLoading);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const action_btn = ["DRUG", "DISEASE", "PERSONA"];
  const monParametreValue = getParamValueTabs(
    "periods",
    params,
    action_btn.length
  );
  const [which_btn, setbtn] = useState(monParametreValue);

  useEffect(() => {
    if (action_btn[which_btn] === "DRUG")
      dispatch(getCompetitiveGraph(id_drug_competive, 'quarter'));
    else if (action_btn[which_btn] === "DISEASE")
      dispatch(
        getCompetitiveGraphDisease(id_drug_competive, disease)
      );
    else if (action_btn[which_btn] === "PERSONA")
      dispatch(
        getCompetitiveGraphPersonas(id_drug_competive, 'HCP')
      );
    dispatch(setcompetitiveGraphLoadingAnimation(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_drug_competive,disease, action_btn[which_btn], dispatch]);

  return (
    <div className={my_className}>
      <div>
        <MUItoogleBtn
          action_btn={action_btn}
          setbtn={setbtn}
          which_btn={which_btn}
        />
      </div>
      {load === false ? (
        <AreaBarChartVolume
          data={data}
          is_percentage={is_percentage}
          display_all={true}
        />
      ) : (
        <div className="loading-animation" style={{ height: "100%" }}>
          <LoadingAnimation type={"linear"} />
        </div>
      )}
    </div>
  );
}
