import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getCompetitiveTreeMap,
  setTMCompetDrugLoadingAnimation,
} from "../../Action";
import { TreeMapSentiment, LoadingAnimation, LegendTreemap } from "../../components";

export function TreeMapCompetitiveDrug(props) {
  const dispatch = useDispatch();
  const id_drug_competive = useSelector(
    (state) => state.competitive.drugChooseForCompetitive
  );
  const date = useSelector((state) => state.config.date);
  const load = useSelector((state) => state.config.treemapCompetDrugLoading);
  const data = useSelector((state) => state.competitive.treemap);

  useEffect(() => {
    dispatch(
      getCompetitiveTreeMap(
        date,
        id_drug_competive,
        "volume_last_week",
        "sentiment",
        "quarter"
      )
    );
    dispatch(setTMCompetDrugLoadingAnimation(true));
  }, [date, id_drug_competive, dispatch]);
  
  return (
    <div>
      {load === false ? data.length !== 0 ?
       ( <div className="tree-map-competitive-drug">
          <TreeMapSentiment data={data} filter={"navigation"} />
          <LegendTreemap my_className={""}/>
        </div>
        
      ) : null : (
        <div className="loading-animation" style={{height: data.length === 0 ? null : "370px"}}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
