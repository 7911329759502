import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { GlobalLrxAnimation } from "../../components";

import {
  CompanyGraphPannel,
  ControlPannel,
  CompanyCardPannel,
} from "../../layout";

export function CompanyPage() {
  const { id } = useParams();
  const load = useSelector((state) => state.config.globalMetaDataLoading);

  return (
    <div>
      {!load ? (
        <div className="dashboard">
          <div className="dashboard--pannel">
            <ControlPannel value={"CompanyPage"} />
          </div>
          <div className="dashboard--side">
            {id !== "-1" ? (
              <div>
                <CompanyGraphPannel companyId={id} />
              </div>
            ) : (
              <div>
                <CompanyCardPannel/>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="dashboard--loading">
          <div className="dashboard--pannel">
            <ControlPannel value={"DrugPage"} />
          </div>
          <div className="global-loading">
            <GlobalLrxAnimation />
          </div>
        </div>
      )}
    </div>
  );
}
