import React from "react";
import { useSelector } from "react-redux";
import { VerticalBarChart, LoadingAnimation } from "../../components";

function compterPhasesParFranchise(tableau) {
  const resultat = {};

  tableau.forEach(({ display_name, phase }) => {
    const franchises = display_name.split(" / ");
    franchises.forEach((franchise) => {
      if (!resultat[franchise]) {
        resultat[franchise] = {};
      }
      resultat[franchise][phase] = (resultat[franchise][phase] || 0) + 1;
    });
  });
  return resultat;
}

function transformObject(obj) {
  const transformedArray = Object.entries(obj)
    .filter(([key, values]) => key !== "No Name" && key !== "-")
    .map(([th, values]) => {
      return { name: th, ...values };
    });
  return transformedArray;
}

function sortAndFilterTable(data) {
  const sortedData = [...data].sort((a, b) => {
    const aPhases = Object.keys(a).filter(key => key !== "name" && a[key] !== 1).length;
    const bPhases = Object.keys(b).filter(key => key !== "name" && b[key] !== 1).length;

    return bPhases - aPhases;
  });

  return sortedData.slice(0, 15);
}

export function AllNCTTargetCompetitiveDetailByPhase(props) {
  const info = useSelector((state) => state.drug.NCTTab);
  const load = useSelector((state) => state.config.tabNCTDiseaseLoading);
  const resultat = compterPhasesParFranchise(info);
  const transformedArray = transformObject(resultat);
  const sortedData = sortAndFilterTable(transformedArray);
  const datakeys = [
    "phase 1",
    "phase 1/phase 2",
    "phase 2",
    "phase 3",
    "nda/bla",
    "approved",
  ];

  const colors = [
    "#ad345c",
    "#e2823d",
    "#e2823d",
    "#27789E",
    "#2B6530",
    "#59AB46",
  ];
  
  return (
    <div className="company-graph-pannel__treatement-tabs__details-graph__phase-graph--chart">
      {load === false ?
        <VerticalBarChart
          data={sortedData}
          keys={datakeys}
          colors={colors}
        />
        : (
          <div className="company-graph-pannel__treatement-tabs__details-graph__phase-graph--loading-container">
            <LoadingAnimation type={"circular"} />
          </div>
        )}
    </div>
  )
}
