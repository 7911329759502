import React from "react";

import './style/legend_treemap.css';

export function LegendTreemap(props){
      const {my_className, legend_value} = props
      return (
        <span className={my_className}>
        <div  className="legend_treemap-gradiant" style={{ marginBottom: legend_value === 1 ? "1%" : null}}>
          <span className="legend_treemap-cross-left">negative </span>
          <span className="legend_treemap-gradiant-color"> </span>
          <span className="legend_treemap-cross-right">positive </span>
        </div>
        </span>
      );
}