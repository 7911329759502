import React from "react";

import Rating from '@mui/material/Rating';

export function MuiRating(props){
    const {name, onChange, size, readOnly = false, value, precision} = props

    return (
      <Rating
        name={name}
        onChange={onChange}
        size={size}
        readOnly={readOnly}
        value={value}
        precision={precision}
      />
    );
  }