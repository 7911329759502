import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  DolRankingTable,
  MuiCard,
  ModalNeedSocialAccess,
  ChartIcon,
  LrxBtn,
  MuiPagination,
} from "../../components";
import { is_departement_is_social } from "../../utils";
import dolranking from "../../../Asset/dolranking.png";

export function DolRankingPannel() {
  const user = useSelector((state) => state.auth.auth_name);
  const [is_open, set_open] = useState(false);
  const handleChange = (open) => set_open(open);
  const [begin_page, set_begin_page] = useState(1);

  return (
    <div>
      {is_departement_is_social(user) ? (
        <div className="dol-table-pannel">
          <MuiCard>
            <div className="dol-table-pannel__table-container">
              <DolRankingTable pagination={begin_page} />
              <MuiPagination page={begin_page} setPage={set_begin_page}/>
            </div>
          </MuiCard>
        </div>
      ) : (
        <div className="locked-screen-trending-pannel">
          <ModalNeedSocialAccess more_info={is_open} onChange={handleChange} />
          <img
            className="locked-screen-trending-pannel__img"
            src={dolranking}
            alt="background-dolranking"
          />
          <div className="locked-screen-trending-pannel--icon-button">
            <div className="locked-screen-trending-pannel--icon-button__icon">
              <ChartIcon logo={"LockedIcon"} />
            </div>
            <div className="need-premium-modal__validate">
              <LrxBtn
                action_btn={() => handleChange(true)}
                logo={"ActionSucess"}
                text_btn={"More Info"}
                my_className={"need-premium-modal__validate--btn"}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
