import React, { useState } from "react";
//import {useSelector} from 'react-redux'
import Chart from "react-google-charts";
import { useNavigate } from "react-router-dom";
import { ModalInfoNCT } from "../../../../components";

export function TimeLineChart(props) {
  function calculateTableHeight(myObject, size) {
    const headerHeight = 20;
    const bottomHeight = 32;
    const baseRowHeight = 31;
    let totalHeight = headerHeight + bottomHeight;

    Object.keys(myObject).forEach(function (key) {
      let size_line = 0;
      if (myObject[key] === 1) {
        size_line = baseRowHeight;
      } else if (myObject[key] > 1) {
        if (display !== "last") size_line = myObject[key] * 25;
        else size_line = myObject[key] * 15;
      } else {
        size_line = myObject[key] * 15;
      }
      totalHeight += size_line;
    });
    const sizeThreshold = 38;
    if (size > sizeThreshold) {
      const sizeOverThreshold = size - sizeThreshold;
      const numberOfChunks = Math.ceil(sizeOverThreshold / sizeThreshold);
      totalHeight -= numberOfChunks * 150;
    }
    if (size > 100) {
      totalHeight -= size * 4.5;
    }
    return totalHeight;
  }

  function countDuplicates(tableData) {
    const map = new Map();
    tableData.forEach((subTable, index) => {
      if (index !== 0) {
        const drug = subTable[0];
        if (map.has(drug)) {
          map.set(drug, map.get(drug) + 1);
        } else {
          map.set(drug, 1);
        }
      }
    });
    const result = {};
    map.forEach((value, key) => {
      result[key] = value;
    });
    return result;
  }

  const go_to = (id_drug) => {
    if (id_drug) {
      navigate(`/drug/${id_drug}`);
    }
  };

  const {
    dataAD,
    content = "drug",
    infoDrug = null,
    display = "last",
    TimeLineStart = null,
    TimeLineEnd = null,
  } = props;
  const navigate = useNavigate();
  const tableData = countDuplicates(dataAD);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [nct_choose, setNCT] = useState("");
  //const load = useSelector (state =>  state.config.graphNCTLoading);

  const chartEvents = infoDrug
    ? [
        {
          eventName: "select",
          callback({ chartWrapper }) {
            if (content === "drug") {
              const id_selected =
                infoDrug[chartWrapper.getChart().getSelection()[0].row + 1][11];
              go_to(id_selected);
            }
            if (content === "treatment") {
              const id_selected =
                dataAD[chartWrapper.getChart().getSelection()[0].row + 1][1];
              const filteredData = infoDrug.filter(
                (item) => item[1] === id_selected
              )[0];
              setNCT(filteredData[6]);
              handleOpen();
            }
          },
        },
      ]
    : [];

  const option = {
    colors: [
      "#ad345c",
      "#e2823d",
      "#27789E",
      "#59AB46",
    ],
    timeline: {
      barLabelStyle: { fontSize: 9 },
      groupByRowLabel: true,
      rowLabelStyle: { fontSize: 12 },
      showBarLabels: true,
      bar: { groupWidth: "100%" },
    },
    hAxis: {
      minValue: TimeLineStart,
      maxValue: TimeLineEnd,
    },
  };

  return (
    <div
      style={{
        height: calculateTableHeight(tableData, dataAD.length, display) + "px",
        maxHeight: "80vh",
        minHeight: "100px",
      }}
    >
      <ModalInfoNCT open={open} handleClose={handleClose} nct={nct_choose} />
      <Chart
        width={"100%"}
        height={"100%"}
        chartType="Timeline"
        data={dataAD}
        options={option}
        chartEvents={chartEvents}
        rootProps={{ "data-testid": "2" }}
      />
    </div>
  );
}
