const initialState = {
  globalMetaDataLoading: true,
  date: new Date("2024-06-09"), //setToMonday(new Date()),
  treemapTDDisease: [],
  TDmetric1: "volume_last_week",
  TDmetric2: "sentiment",
  TopicsSet: "",
  diseaseWcloud: "",
  treemapLoading: false,
  treemapCompetDrugLoading: false,
  treemapTTLoading: false,
  dataTTLoading: false,
  dataLoading: false,
  volumeDiseaseTab: false,
  infoLoading: false,
  graphLoading: false,
  graphDrugLoading: false,
  radarDrugLoading: false,
  tabShareLoading: false,
  BigtabShareLoading: false,
  tabDrugCompanyLoading: false,
  graphDrugCompanyLoading: false,
  tabCongressDrugLoading: false,
  treempaCongressTDLoading: false,
  tabCompetitiveDataLoading: false,
  tabRSSDiseaseLoading: false,
  graphCompetitiveLoading: false,
  graphTargetCompetitiveLoading: false,
  graphCompanyCompetitiveLoading: false,
  graphTypeCompetitiveLoading: false,
  graphNCTLoading: false,
  graphNCTDiseaseLoading: false,
  graphNCTCompanyLoading: false,
  tabNCTLoading: false,
  tabNCTDiseaseLoading: false,
  tabNCTCompanyLoading: false,
  tabAlertNCTLoading: false,
  tabRSSLoading: false,
  tabRSSCompanyLoading: false,
  tabAlertPublicationLoading: false,
  tabAlertRSSLoading: false,
  tabAlertTreatmentLoading: false,
  dynamicSearchDrugLoading: false,
  wordCloudLoading: false,
  tweetLoading: false,
  congressAllCardsLoading: false,
  companyNCTHistoByPhase: false,
  companyCatalystTabLoading: false,
  drugAllCongressLoading: false,
  nctTreatmentDetailsGraphLoading: false,
  tabCatalystCompetitiveLoading: false,
  graphPhaseDrugLoading: false,
  graphTreatmentLoading: false,
  graphPhaseCompanyLoading: false,
  graphCompetitiveDataDrugLoading: false,
  dataDOLRankingTabLoading: false,
  dataDOLRankingStatLoading: false,
  dataDOLRankingByDrugTabLoading: false,
  dataDOLRankingByDiseaseTabLoading: false,
  dataDOLProfileLoading: false,
  worldcloudDOLProfileLoading: false,
  graphicsYearsDOLProfileLoading: false,
};

//function setToMonday(date) {
//  var day = date.getDay() || 7;
//  if (day !== 0) date.setHours(-24 * (day - 0));
//  return date;
//}

export function ConfigGraph(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "SET_TREEMAP_TD_DISEASE":
      const e = state.treemapTDDisease.findIndex(
        (item) => item === action.value
      );
      if (e !== -1) {
        nextState = {
          ...state,
          treemapTDDisease: state.treemapTDDisease.filter(
            (item) => item !== action.value
          ),
        };
      } else {
        nextState = {
          ...state,
          treemapTDDisease: [...state.treemapTDDisease, action.value],
        };
      }
      return nextState || state;

    case "SET_GLOBAL_LOADING":
      nextState = {
        ...state,
        globalMetaDataLoading: action.value,
      };
      return nextState || state;

    case "SET_TOPICS_SELECTED":
      nextState = {
        ...state,
        TopicsSet: action.value,
      };
      return nextState || state;

    case "SET_DISEASE_WORD_CLOUD_SELECTED":
      nextState = {
        ...state,
        diseaseWcloud: action.value,
      };
      return nextState || state;

    case "SET_TD_METRIC1":
      nextState = {
        ...state,
        TDmetric1: action.value,
      };
      return nextState || state;
    case "SET_TD_METRIC2":
      nextState = {
        ...state,
        TDmetric2: action.value,
      };
      return nextState || state;

    case "SET_INFO_LOADING_ANIMATION":
      nextState = {
        ...state,
        infoLoading: action.value,
      };
      return nextState || state;

    case "SET_DATE_RANGE":
      nextState = {
        ...state,
        date: action.value,
      };
      return nextState || state;

    case "SET_TM_LOADING_ANIMATION":
      nextState = {
        ...state,
        treemapLoading: action.value,
      };
      return nextState || state;

    case "SET_TMTT_LOADING_ANIMATION":
      nextState = {
        ...state,
        treemapTTLoading: action.value,
      };
      return nextState || state;

    case "SET_DATA_LOADING_ANIMATION":
      nextState = {
        ...state,
        dataLoading: action.value,
      };
      return nextState || state;

    case "SET_DATA_TT_LOADING_ANIMATION":
      nextState = {
        ...state,
        dataTTLoading: action.value,
      };
      return nextState || state;

    case "SET_GRAPH_LOADING_ANIMATION":
      nextState = {
        ...state,
        graphLoading: action.value,
      };
      return nextState || state;

    case "SET_GRAPH_DRUG_LOADING_ANIMATION":
      nextState = {
        ...state,
        graphDrugLoading: action.value,
      };
      return nextState || state;

    case "SET_GRAPH_RADAR_LOADING_ANIMATION":
      nextState = {
        ...state,
        radarDrugLoading: action.value,
      };
      return nextState || state;

    case "SET_TAB_SHARE_LOADING_ANIMATION":
      nextState = {
        ...state,
        tabShareLoading: action.value,
      };
      return nextState || state;

    case "SET_BIG_TAB_SHARE_LOADING_ANIMATION":
      nextState = {
        ...state,
        BigtabShareLoading: action.value,
      };
      return nextState || state;

    case "SET_TAB_COMPANY_DRUG_LOADING_ANIMATION":
      nextState = {
        ...state,
        tabDrugCompanyLoading: action.value,
      };
      return nextState || state;

    case "SET_TAB_CONGRESS_DRUG_LOADING_ANIMATION":
      nextState = {
        ...state,
        tabCongressDrugLoading: action.value,
      };
      return nextState || state;

    case "SET_TM_CONGRESS_LOADING_ANIMATION":
      nextState = {
        ...state,
        treempaCongressTDLoading: action.value,
      };
      return nextState || state;

    case "SET_COMPETITIVE_DATA_LOADING_ANIMATION":
      nextState = {
        ...state,
        tabCompetitiveDataLoading: action.value,
      };
      return nextState || state;

    case "SET_COMPETITIVE_GRAPH_TARGET_LOADING_ANIMATION":
      nextState = {
        ...state,
        graphTargetCompetitiveLoading: action.value,
      };
      return nextState || state;

    case "SET_COMPETITIVE_GRAPH_COMPANY_LOADING_ANIMATION":
      nextState = {
        ...state,
        graphCompanyCompetitiveLoading: action.value,
      };
      return nextState || state;

    case "SET_COMPETITIVE_GRAPH_TYPE_LOADING_ANIMATION":
      nextState = {
        ...state,
        graphTypeCompetitiveLoading: action.value,
      };
      return nextState || state;

    case "SET_COMPETITIVE_GRAPH_LOADING_ANIMATION":
      nextState = {
        ...state,
        graphCompetitiveLoading: action.value,
      };
      return nextState || state;

    case "SET_NCT_GRAPH_LOADING_ANIMATION":
      nextState = {
        ...state,
        graphNCTLoading: action.value,
      };
      return nextState || state;
    case "SET_NCT_TAB_LOADING_ANIMATION":
      nextState = {
        ...state,
        tabNCTLoading: action.value,
      };
      return nextState || state;

    case "SET_RSS_TAB_LOADING_ANIMATION":
      nextState = {
        ...state,
        tabRSSLoading: action.value,
      };
      return nextState || state;

    case "SET_RSS_DISEASE_LOADING_ANIMATION":
      nextState = {
        ...state,
        tabRSSDiseaseLoading: action.value,
      };
      return nextState || state;

    case "SET_ALERT_RSS_LOADING_ANIMATION":
      nextState = {
        ...state,
        tabAlertRSSLoading: action.value,
      };
      return nextState || state;

    case "SET_ALERT_PUBLICATION_LOADING_ANIMATION":
      nextState = {
        ...state,
        tabAlertPublicationLoading: action.value,
      };
      return nextState || state;

    case "SET_DRUG_DYNAMIC_SEARCH_LOADING_ANIMATION":
      nextState = {
        ...state,
        dynamicSearchDrugLoading: action.value,
      };

      return nextState || state;

    case "SET_WROLD_CLOUD_DRUG_DISEASE_LOADING_ANIMATION":
      nextState = {
        ...state,
        wordCloudLoading: action.value,
      };

      return nextState || state;

    case "SET_DRUG_TWEET_DATA_LOADING_ANIMATION":
      nextState = {
        ...state,
        tweetLoading: action.value,
      };

      return nextState || state;

    case "SET_CONGRESS_ALL_CARDS_LOADING_ANIMATION":
      nextState = {
        ...state,
        congressAllCardsLoading: action.value,
      };

      return nextState || state;

    case "SET_DETAILS_COMPANY_GRAPH_ANIMATION":
      nextState = {
        ...state,
        companyNCTHistoByPhase: action.value,
      };

      return nextState || state;

    case "SET_COMPANY_CATALYST_TAB_LOADING_ANIMATION":
      nextState = {
        ...state,
        companyCatalystTabLoading: action.value,
      };

      return nextState || state;

    case "SET_NCT_TREATMENT_DETAILS_GRAPH_LOADING_ANIMATION":
      nextState = {
        ...state,
        nctTreatmentDetailsGraphLoading: action.value,
      };

      return nextState || state;

    case "SET_TAB_CATALYST_COMPETITIVE_LOADING_ANIMATION":
      nextState = {
        ...state,
        tabCatalystCompetitiveLoading: action.value,
      };

      return nextState || state;

    case "SET_TREATMENT_TAB_LOADING_ANIMATION":
      nextState = {
        ...state,
        tabAlertTreatmentLoading: action.value,
      };

      return nextState || state;

    case "SET_ALERT_NCT_TAB_LOADING_ANIMATION":
      nextState = {
        ...state,
        tabAlertNCTLoading: action.value,
      };

      return nextState || state;

    case "SET_GRAPH_PHASE_DRUG_LOADING_ANIMATION":
      nextState = {
        ...state,
        graphPhaseDrugLoading: action.value,
      };

      return nextState || state;

    case "SET_GRAPH_TREATMENT_LOADING_ANIMATION":
      nextState = {
        ...state,
        graphTreatmentLoading: action.value,
      };

      return nextState || state;

    case "SET_GRAPH_PHASE_COMPANY_LOADING_ANIMATION":
      nextState = {
        ...state,
        graphPhaseCompanyLoading: action.value,
      };

      return nextState || state;

    case "SET_GRAPH_DRUG_COMPANY_LOADING_ANIMATION":
      nextState = {
        ...state,
        graphDrugCompanyLoading: action.value,
      };

      return nextState || state;

    case "SET_NCT_COMPANY_GRAPH_LOADING_ANIMATION":
      nextState = {
        ...state,
        graphNCTCompanyLoading: action.value,
      };

      return nextState || state;

    case "SET_NCT_COMPANY_TAB_LOADING_ANIMATION":
      nextState = {
        ...state,
        tabNCTCompanyLoading: action.value,
      };

      return nextState || state;

    case "SET_GRAPH_COMPETITIVE_DATA_DRUG_LOADING_ANIMATION":
      nextState = {
        ...state,
        graphCompetitiveDataDrugLoading: action.value,
      };

      return nextState || state;

    case "SET_GRAPH_NCT_DISEASE_LOADING_ANIMATION":
      nextState = {
        ...state,
        graphNCTDiseaseLoading: action.value,
      };

      return nextState || state;

    case "SET_NCT_DISEASE_TAB_LOADING_ANIMATION":
      nextState = {
        ...state,
        tabNCTDiseaseLoading: action.value,
      };

      return nextState || state;

    case "SET_COMPETITIVE_TREEMAP_LOADING_ANIMATION":
      nextState = {
        ...state,
        treemapCompetDrugLoading: action.value,
      };

      return nextState || state;

    case "SET_VOLUME_DISEASE_TAB_LOADING_ANIMATION":
      nextState = {
        ...state,
        volumeDiseaseTab: action.value,
      };

      return nextState || state;

    case "SET_RSS_COMPANY_TAB_LOADING_ANIMATION":
      nextState = {
        ...state,
        tabRSSCompanyLoading: action.value,
      };

      return nextState || state;

    case "SET_DRUG_CONFERENCE_ANIMATION":
      nextState = {
        ...state,
        drugAllCongressLoading: action.value,
      };

      return nextState || state;

    case "SET_DATA_DOL_RANKING_TAB_LOADING_ANIMATION":
      nextState = {
        ...state,
        dataDOLRankingTabLoading: action.value,
      };

      return nextState || state;

    case "SET_DATA_DOL_RANKING_STAT_LOADING_ANIMATION":
      nextState = {
        ...state,
        dataDOLRankingStatLoading: action.value,
      };

      return nextState || state;

    case "SET_DATA_DOL_RANKING_BY_DRUG_TAB_LOADING_ANIMATION":
      nextState = {
        ...state,
        dataDOLRankingByDrugTabLoading: action.value,
      };

      return nextState || state;

    case "SET_DATA_DOL_RANKING_BY_DISEASE_TAB_LOADING_ANIMATION":
      nextState = {
        ...state,
        dataDOLRankingByDiseaseTabLoading: action.value,
      };

      return nextState || state;

    case "SET_DATA_DOL_PROFILE_LOADING_ANIMATION":
      nextState = {
        ...state,
        dataDOLProfileLoading: action.value,
      };

      return nextState || state;

    case "SET_DATA_DOL_PROFILE_WORLDCLOUD_LOADING_ANIMATION":
      nextState = {
        ...state,
        worldcloudDOLProfileLoading: action.value,
      };

      return nextState || state;

    case "SET_DATA_DOL_PROFILE_GRAPHICS_LOADING_ANIMATION":
      nextState = {
        ...state,
        graphicsYearsDOLProfileLoading: action.value,
      };

      return nextState || state;

    case "ERROR_CONFIG":
      return state;

    default:
      return state;
  }
}
