import React, { useState, useEffect } from "react";
import { FormControlLabel } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setFilterTD } from "../../../Action";


export function BtnPhaseDisplay() {
  const updateURLParams = (paramName, paramValue) => {
    const params = new URLSearchParams(window.location.search);
    params.set(paramName, paramValue);
    const nouvelleURL = `${window.location.pathname}?${params.toString()}`;
    navigate(nouvelleURL);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const static_depth = [
    "off-patent",
    "approved",
    "nda/bla",
    "phase 3",
    "phase 2",
    "phase 1",
    "preclinical",
  ];

  let depth = useSelector((state) => state.diease.statusDrugDisplay);
  const missingPhase = static_depth.filter(
    (element) => !depth.includes(element)
  );

  let phase_sent = missingPhase.join(";");

  const [state, setState] = useState({
    "off-patent": !!depth.find((el) => el === "off-patent"),
    approved: !!depth.find((el) => el === "approved"),
    "nda/bla": !!depth.find((el) => el === "nda/bla"),
    "phase 3": !!depth.find((el) => el === "phase 3"),
    "phase 2": !!depth.find((el) => el === "phase 2"),
    "phase 1": !!depth.find((el) => el === "phase 1"),
    preclinical: !!depth.find((el) => el === "preclinical"),
  });

  useEffect(() => {
    setState({
      "off-patent": !!depth.find((el) => el === "off-patent"),
      approved: !!depth.find((el) => el === "approved"),
      "nda/bla": !!depth.find((el) => el === "nda/bla"),
      "phase 3": !!depth.find((el) => el === "phase 3"),
      "phase 2": !!depth.find((el) => el === "phase 2"),
      "phase 1": !!depth.find((el) => el === "phase 1"),
      preclinical: !!depth.find((el) => el === "preclinical"),
    });
  }, [dispatch, depth]);

  useEffect(() => {
    updateURLParams("phases", phase_sent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phase_sent]);

  const handleChange = (event) => {
    dispatch(setFilterTD(event.target.name));
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div className="control-pannel-search-entity">
    <h3 className="control-pannel-search-entity--title">Current Phase </h3><br />
      <div className="phase-list">
        {static_depth.map((el, i) => (
          <FormControlLabel
            key={i}
            control={
              <label class="btn-phase-container">
                <input type="checkbox" checked={state[el]} onChange={handleChange} name={el}></input>
                <svg viewBox="0 0 64 64" height="2em" width="2em">
                  <path d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16" pathLength="575.0541381835938" class="path"></path>
                </svg>
              </label>
            }
            label={el}
          />
        ))}
      </div>
    </div>
  );
}