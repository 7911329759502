import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import {
  getInfoDrug,
  getInfoCompany,
  getDolProfileData,
  setInfoLoadingAnimation,
  setdataDOLProfileAnimation,
  getDolRankingStatData,
  setdataDOLRankingStatAnimation,
} from "../../Action";
import {
  OtherNameBox,
  SpecDrugBox,
  RankBox,
  RankBoxCompany,
  RankDrugBox,
  BuyBackCompany,
  SpecCompanyBox,
  RankCompanyBox,
  LoadingAnimation,
  MuiCard,
  ActionIconDrug,
  DetailsPhaseBox,
  DetailsTherapeuticsBox,
  RankCompanyNewsBox,
  LrxChips,
  DetailsCompetitivePhaseBox,
  DetailsCompetitiveTherapeuticsBox,
  RankCompetitiveNewsBox,
  MuiCollapse,
  MuiRating,
  DolLrxChips,
  PieChartPercentage,
} from "../../components";

import { get_nb_drug_by_phase_by_years } from "../../utils";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton } from "@mui/material";
import numeral from "numeral";

function get_true_name(info) {
  if (info.drug_brand_name !== "-1" && info.drug_brand_name !== "-")
    return info.drug_brand_name;
  if (info.drug_inn !== "-1" && info.drug_inn !== "-") return info.drug_inn;
  return info.drug_display_name;
}

function InfoPannelDrugMetricsSelector(props) {
  const { type_of_content, value } = props;

  const data_active = useSelector((state) => state.drug.TreatmentGraph);
  const info = useSelector((state) => state.drug.NCTTab);
  const infoNews = useSelector((state) => state.drug.RSSTab);
  const data_concat =
    data_active.length !== 0
      ? data_active[0].concat(data_active[1].slice(1))
      : [];
  const result = get_nb_drug_by_phase_by_years(2024, data_concat);
  const keyOrder = ["approved", "nda/bla", "phase 3", "phase 2", "phase 1"];

  const compteurs = {
    "Phase 3": 0,
    "Phase 2": 0,
    "Phase 1": 0,
  };

  info.reduce((acc, i) => {
    const { phase } = i;
    if (phase === "phase 1") acc["Phase 1"]++;
    else if (phase === "phase 2") acc["Phase 2"]++;
    else if (phase === "phase 1/phase 2") acc["Phase 2"]++;
    else if (phase === "phase 3") acc["Phase 3"]++;
    return acc;
  }, compteurs);
  if (type_of_content === 0)
    return (
      <div>
        {" "}
        <div className="info-pannel__drug-pannel__box-container">
          <div className="info-pannel__drug-pannel__box-container--info-box">
            {" "}
            <OtherNameBox value={value} />{" "}
          </div>
          <div className="info-pannel__drug-pannel__box-container--info-box">
            {" "}
            <SpecDrugBox value={value} />{" "}
          </div>
          <div className="info-pannel__drug-pannel__box-container--info-box">
            {" "}
            <RankBox value={value} />{" "}
          </div>
          <div className="info-pannel__drug-pannel__box-container--info-box">
            {" "}
            <RankDrugBox value={value} />{" "}
          </div>
        </div>{" "}
      </div>
    );
  if (type_of_content === 1)
    return (
      <div className="info-pannel__drug-pannel__box-container">
        <div className="info-pannel__drug-pannel__box-container--info-box">
          {" "}
          <OtherNameBox value={value} />{" "}
        </div>
        <div className="info-pannel__drug-pannel__box-container--info-box">
            {" "}
            <SpecDrugBox value={value} />{" "}
          </div>
        <div className="info-pannel__drug-pannel__box-container--info-box">
          <div className="info-pannel__company-pannel__details">
            {keyOrder.map(
              (keyName, i) =>
                keyName !== "name" &&
                result.hasOwnProperty(keyName) && (
                  <div
                    className="info-pannel__competitive-pannel__details"
                    key={i}
                  >
                    <div className="info-pannel__competitive-pannel__details--rank">
                      {result[keyName]}
                    </div>
                    <div>{keyName}</div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    );
  if (type_of_content === 2)
    return (
      <div className="info-pannel__drug-pannel__box-container">
        <div className="info-pannel__drug-pannel__box-container--info-box">
          {" "}
          <OtherNameBox value={value} />{" "}
        </div>
        <div className="info-pannel__drug-pannel__box-container--info-box">
            {" "}
            <SpecDrugBox value={value} />{" "}
          </div>
        <div className="info-pannel__drug-pannel__box-container--info-box">
          <div className="info-pannel__company-pannel__details">
            {Object.keys(compteurs).map((keyName, i) => (
              <div className="info-pannel__competitive-pannel__details">
                <div className="info-pannel__competitive-pannel__details--rank">
                  {" "}
                  {compteurs[keyName]}
                </div>
                <div> {keyName} </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  else
    return (
      <div className="info-pannel__drug-pannel__box-container">
        <div className="info-pannel__drug-pannel__box-container--info-box">
          {" "}
          <OtherNameBox value={value} />{" "}
        </div>
        <div className="info-pannel__drug-pannel__box-container--info-box">
            {" "}
            <SpecDrugBox value={value} />{" "}
          </div>
        <div className="info-pannel__drug-pannel__box-container--info-box">
          <div className="info-pannel__competitive-pannel__details">
            <div className="info-pannel__competitive-pannel__details--rank">
              {" "}
              {infoNews.length}
            </div>
            <div> News </div>
          </div>
        </div>
      </div>
    );
}

function InfoDrug(props) {
  const { value, type_of_content } = props;
  const info = useSelector((state) => state.drug.drugInfo);
  const load = useSelector((state) => state.config.infoLoading);
  const [open, setOpen] = useState(false);
  const [isRotated, setIsRotated] = useState(false);
  const toggleRotation = () => {
    setOpen(!open);
    setIsRotated(!isRotated);
  };
  return (
    <div className="info-pannel__drug-pannel">
      {load === false ? (
        info.drugInfo ? (
          <div>
            <div className="info-pannel__drug-pannel__header-title">
              <h3> Drug : </h3>
              <div className="info-pannel__drug-pannel__header-title__title-container">
                {" "}
                <h1 className="info-pannel__drug-pannel__header-title__title-container--drug-title">
                  {get_true_name(info.drugInfo)}{" "}
                </h1>{" "}
                <ActionIconDrug />
              </div>
            </div>
            <MuiCard mui_class={""}>
              <InfoPannelDrugMetricsSelector
                type_of_content={type_of_content}
                value={value}
              />
              {info.drugInfo.description ? (
                <div className="info-pannel__description-box">
                  <div className="info-pannel__description-box__header-box">
                    <div className="info-pannel__description-box__header-box__title">
                      <span className="info-pannel_box_name_subtitle">
                        {" "}
                        Description{" "}
                      </span>
                    </div>
                    <div className="info-pannel__description-box__header-box--expandIcon">
                      <IconButton
                        className={`info-pannel__description-box__header-box--expandIcon ${
                          isRotated ? "rotated" : ""
                        }`}
                        onClick={toggleRotation}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </div>
                  </div>
                  <MuiCollapse is_open={open}>
                    <h3 className="info-pannel__description-box--desciption-text">
                      {info.drugInfo.description}
                    </h3>
                  </MuiCollapse>
                </div>
              ) : null}
            </MuiCard>
          </div>
        ) : null
      ) : (
        <div class="loading-animation" style={{ height: "273px" }}>
          <LoadingAnimation type={"linear"} />
        </div>
      )}
    </div>
  );
}

function InfoPannelCompanyMetricsSelector(props) {
  const { type_of_content } = props;
  if (type_of_content === 0)
    return (
      <div className="info-pannel__company-pannel__box-container">
        <div className="info-pannel__company-pannel__box-container--info-box">
          {" "}
          <RankBoxCompany />{" "}
        </div>
        <div className="info-pannel__company-pannel__box-container--info-box">
          {" "}
          <RankCompanyBox />{" "}
        </div>
      </div>
    );
  if (type_of_content === 1)
    return (
      <div className="info-pannel__company-pannel__box-container">
        <div className="info-pannel__company-pannel__box-container--info-box">
          {" "}
          <DetailsPhaseBox />{" "}
        </div>
      </div>
    );
  if (type_of_content === 2)
    return (
      <div className="info-pannel__company-pannel__box-container">
        <div className="info-pannel__company-pannel__box-container--info-box">
          {" "}
          <DetailsTherapeuticsBox />{" "}
        </div>
      </div>
    );
  else
    return (
      <div className="info-pannel__company-pannel__box-container--info-box">
        {" "}
        <RankCompanyNewsBox />{" "}
      </div>
    );
}

function InfoCompany(props) {
  const { type_of_content } = props;
  const load = useSelector((state) => state.config.infoLoading);
  const info = useSelector((state) => state.company.companyInfo);
  const [open, setOpen] = useState(false);
  const [isRotated, setIsRotated] = useState(false);

  const toggleRotation = () => {
    setOpen(!open);
    setIsRotated(!isRotated);
  };

  return (
    <div className="info-pannel__company-pannel">
      {load === false ? (
        info.length !== 0 ? (
          <div>
            <div className="info-pannel__company-pannel__header-title">
              <h3> Company : </h3>
              <div className="info-pannel__company-pannel__header-title">
                <h1>{info.company_display_name}</h1>
              </div>
            </div>
            <MuiCard mui_class={""}>
              <div className="info-pannel__company-pannel__box-container">
                <div className="info-pannel__company-pannel__box-container--info-box">
                  {" "}
                  <SpecCompanyBox />{" "}
                </div>
                <div className="info-pannel__company-pannel__box-container--info-box">
                  {info.acquirer_company_id ? (
                    <BuyBackCompany />
                  ) : (
                    <img
                      className="info-pannel__company-pannel__box-container--image-box"
                      src={"https://logo.clearbit.com/https:/" + info.website}
                      alt=""
                    ></img>
                  )}
                </div>
                <InfoPannelCompanyMetricsSelector
                  type_of_content={type_of_content}
                />
                {/*<div className="info-pannel__company-pannel__box-container--info-box">
                  {" "}
                  <RankBoxCompany value={value} />{" "}
                </div>
                <div className="info-pannel__company-pannel__box-container--info-box">
                  {" "}
                  <RankCompanyBox />{" "}
                  </div>*/}
              </div>
              {info.description ? (
                <div className="info-pannel__description-box">
                  <div className="info-pannel__description-box__header-box">
                    <div className="info-pannel__description-box__header-box__title">
                      <span className="info-pannel_box_name_subtitle">
                        {" "}
                        Description{" "}
                      </span>
                    </div>
                    <div className="info-pannel__description-box__header-box--expandIcon">
                      <IconButton
                        className={`info-pannel__description-box__header-box--expandIcon ${
                          isRotated ? "rotated" : ""
                        }`}
                        onClick={toggleRotation}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </div>
                  </div>
                  <MuiCollapse is_open={open}>
                    <h3 className="info-pannel__description-box--desciption-text">
                      {info.description}
                    </h3>
                  </MuiCollapse>
                </div>
              ) : null}
            </MuiCard>
          </div>
        ) : null
      ) : (
        <div className="loading-animation" style={{ height: "273px" }}>
          <LoadingAnimation type={"linear"} />
        </div>
      )}
    </div>
  );
}

function InfoPannelCompetitiveMetricsSelector(props) {
  const { type_of_content } = props;
  if (type_of_content === 0) return <div> </div>;
  if (type_of_content === 1)
    return (
      <div className="info-pannel__company-pannel__box-container">
        <div className="info-pannel__company-pannel__box-container--info-box">
          {" "}
          <DetailsCompetitivePhaseBox />{" "}
        </div>
      </div>
    );
  if (type_of_content === 2)
    return (
      <div className="info-pannel__company-pannel__box-container">
        <div className="info-pannel__company-pannel__box-container--info-box">
          {" "}
          <DetailsCompetitiveTherapeuticsBox />{" "}
        </div>
      </div>
    );
  else
    return (
      <div className="info-pannel__company-pannel__box-container">
        <div className="info-pannel__company-pannel__box-container--info-box">
          {" "}
          <RankCompetitiveNewsBox />{" "}
        </div>
      </div>
    );
}

function InfoCompetitive(props) {
  const { type_of_content } = props;
  const load = false;
  const info = useSelector((state) => state.company.companyInfo);
  const disease = useSelector((state) => state.diease.diseaseDisplay);
  const targetDisplay = useSelector((state) => state.diease.drugTargetDisplay);
  const is_disease_selected = disease.length + targetDisplay.length;

  return (
    <div className="info-pannel__company-pannel">
      {load === false ? (
        info.length !== 0 ? (
          <div>
            <div className="info-pannel__company-pannel__header-title">
              <h3>
                {" "}
                Competitive Intelligence: <LrxChips />
              </h3>
            </div>
            <MuiCard mui_class={""}>
              <div className="info-pannel__company-pannel__presentation-box">
                <div className="info-pannel__company-pannel__presentation-box__info-box-left">
                  {is_disease_selected === 0 ? (
                    <h3 class="info-pannel__company-pannel__presentation-box__info-box-left--description-disease">
                      {" "}
                      Please select a disease or target{" "}
                    </h3>
                  ) : (
                    disease.map((el) => (
                      <div className="info-pannel__company-pannel__presentation-box__info-box-left__description">
                        <h2 className="info-pannel__company-pannel__presentation-box__info-box-left__description--title">
                          {el.disease_display_name}
                        </h2>
                        <h3 className="info-pannel__company-pannel__presentation-box__info-box-left__description--paragraph">
                          {el.description}
                        </h3>
                      </div>
                    ))
                  )}
                </div>
                <div className="info-pannel__company-pannel__presentation-box__info-box-right">
                  <InfoPannelCompetitiveMetricsSelector
                    type_of_content={type_of_content}
                  />
                </div>
              </div>
            </MuiCard>
          </div>
        ) : null
      ) : (
        <LoadingAnimation type={"linear"} />
      )}
    </div>
  );
}

function InfoDolProfile(props) {
  const info = useSelector((state) => state.dol.dolprofile_info);
  const load = useSelector((state) => state.config.dataDOLProfileLoading);

  const listRatingsName = [
    { name: "Research", rating: info.research_stars },
    { name: "Active", rating: info.activity_stars },
    { name: "Impact", rating: info.engagement_stars },
    { name: "Audience Profile", rating: info.audience_stars },
  ];

  const go_to = (name, id) => {
    if (name === "x_profile") {
      const win = window.open("https://twitter.com/" + id, "_blank");
      if (win != null) win.focus();
    }
  };

  return (
    <div className="info-pannel__dol-profile-pannel">
      {load === false ? (
        <div>
          <div>
            <h3> D.O.L Profile:</h3>
            <h1>{info.user_name}</h1>
          </div>{" "}
          <MuiCard mui_class={""}>
            <div className="info-pannel__dol-profile-pannel__info-box">
              <div className="info-pannel__dol-profile-pannel__info-box__info-general">
                <div className="info-pannel__dol-profile-pannel__info-box__info-general__details_pic_container">
                  <img
                    className="info-pannel__dol-profile-pannel__info-box__info-general__details_pic_container__pic-container"
                    src={`https://unavatar.io/twitter/${info.screen_name}`}
                    alt="user-twitter-profile"
                  />
                  <div className="info-pannel__dol-profile-pannel__info-box__info-general__details_pic_container__info-details-container">
                    <div className="info-pannel__dol-profile-pannel__info-box__info-general__details_pic_container__info-details-container--columns1">
                      <div className="">
                        <span className="info-pannel_box_name_subtitle">
                          {" "}
                          Persona :{" "}
                        </span>
                        {info.persona}
                      </div>
                      <div className="">
                        <span className="info-pannel_box_name_subtitle">
                          {" "}
                          Profession :{" "}
                        </span>
                        <span> {info.profession_display_name}</span>
                      </div>
                      <div className="">
                        <span className="info-pannel_box_name_subtitle">
                          {" "}
                          Diseases :{" "}
                        </span>
                        <span> {info.speciality_disease_name}</span>
                      </div>
                      <div className="">
                        <span className="info-pannel_box_name_subtitle">
                          {" "}
                          Companies Affiliates :{" "}
                        </span>
                        <span> {info.company_display_name}</span>
                      </div>
                      <div className="">
                        <span className="info-pannel_box_name_subtitle">
                          {" "}
                          Congress :{" "}
                        </span>
                        <span> {info.acronym} </span>
                      </div>
                    </div>
                    <div className="info-pannel__dol-profile-pannel__info-box__info-general__details_pic_container__info-details-container--columns2">
                      <div className="">
                        <span className="info-pannel_box_name_subtitle">
                          {" "}
                          Location :{" "}
                        </span>
                        <span> {info.location}</span>
                      </div>
                      <div className="">
                        <span className="info-pannel_box_name_subtitle">
                          {" "}
                          Profession Group :{" "}
                        </span>
                        <span> {info.profession_group_display_name}</span>
                      </div>
                      <div className="">
                        <span className="info-pannel_box_name_subtitle">
                          {" "}
                          Twitter :{" "}
                        </span>
                        <span
                          className="title-link"
                          onClick={() => go_to("x_profile", info.screen_name)}
                        >
                          {"@" + info.screen_name}
                        </span>
                      </div>
                      <div className="">
                        <span className="info-pannel_box_name_subtitle">
                          {" "}
                          Followers :{" "}
                        </span>
                        <span>
                          {" "}
                          {numeral(info.user_followers_count).format(
                            "0,0"
                          )}{" "}
                        </span>
                      </div>
                      <div className="">
                        <span className="info-pannel_box_name_subtitle">
                          {" "}
                          Tweets :{" "}
                        </span>
                        <span> - </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <span className="info-pannel_box_name_subtitle">
                    {" "}
                    Description :{" "}
                  </span>
                  <h3>{info.description}</h3>
                </div>
              </div>
              <div className="info-pannel__dol-profile-pannel__info-box__ratings-container">
                {listRatingsName.map((el) => (
                  <div className="info-pannel__dol-profile-pannel__info-box__ratings-container__rating-box">
                    <h2>{el.name}</h2>
                    <MuiRating
                      name={el.name}
                      readOnly={true}
                      value={el.rating}
                      precision={0.1}
                    />
                  </div>
                ))}
              </div>
            </div>
          </MuiCard>{" "}
        </div>
      ) : (
        <div className="loading-animation" style={{ height: "323px" }}>
          <LoadingAnimation type={"linear"} />
        </div>
      )}
    </div>
  );
}

function InfoGlobalDolProfile(props) {
  const info = useSelector((state) => state.dol.info_ranking_dol);
  const load = useSelector((state) => state.config.dataDOLRankingStatLoading);
  return (
    <div className="info-pannel__dol-profile-pannel">
      {load === false ? (
        <div>
          <div>
            <h3>
              {" "}
              D.O.L Ranking : <DolLrxChips />{" "}
            </h3>
          </div>{" "}
          <MuiCard mui_class={""}>
            <div className="info-pannel__drug-pannel__box-container">
              <div className="info-pannel__drug-pannel__box-container--info-box">
                {" "}
                <div
                  className="info-pannel__competitive-pannel__details"
                  style={{
                    paddingTop: "30px",
                  }}
                >
                  <div className="info-pannel__competitive-pannel__details--rank">
                    {numeral(info.nb_profile).format("0,0")}
                  </div>
                  <div> {"number of profile"} </div>
                </div>
              </div>
              <div className="info-pannel__drug-pannel__box-container--info-box">
                {" "}
                <div className="info-pannel__competitive-pannel__details">
                  <PieChartPercentage data={info.type_profile} />
                </div>
              </div>
            </div>
          </MuiCard>{" "}
        </div>
      ) : (
        <div className="loading-animation" style={{ height: "323px" }}>
          <LoadingAnimation type={"linear"} />
        </div>
      )}
    </div>
  );
}

export function InfoPannel(props) {
  const { id } = useParams();
  const { value, type_of_content } = props;
  const dispatch = useDispatch();
  const my_profession = useSelector((state) => state.diease.speciality_user);
  const my_profession_group = useSelector(
    (state) => state.diease.profession_group_user
  );
  const myLocation = useSelector((state) => state.diease.location_user);
  const my_type = useSelector((state) => state.diease.drugTypeDisplay);
  const my_target = useSelector((state) => state.diease.drugTargetDisplay);
  const my_theraFranchise = useSelector(
    (state) => state.diease.theraFranchiseDisplay
  );
  const disease = useSelector((state) => state.diease.diseaseDisplay);
  const personas = useSelector((state) => state.dol.list_personas);

  function list_name_personas(object) {
    const personasSelected = Object.keys(object)
      .filter((persona) => object[persona] === true)
      .map((persona) => persona.toUpperCase());
    return personasSelected;
  }

  useEffect(() => {
    if (value === 0) {
      dispatch(getInfoDrug(id));
      dispatch(setInfoLoadingAnimation(true));
    } else if (value === 3) {
      dispatch(getDolProfileData(id));
      dispatch(setdataDOLProfileAnimation(true));
    } else if (value === 4) {
      dispatch(
        getDolRankingStatData(
          my_profession_group,
          my_profession,
          myLocation,
          my_type,
          my_target,
          my_theraFranchise,
          disease,
          list_name_personas(personas)
        )
      );
      dispatch(setdataDOLRankingStatAnimation(true));
    } else {
      dispatch(getInfoCompany(id));
      dispatch(setInfoLoadingAnimation(true));
    }
  }, [
    dispatch,
    value,
    id,
    my_profession_group,
    my_profession,
    myLocation,
    my_type,
    my_target,
    my_theraFranchise,
    disease,
    personas,
  ]);

  return (
    <div>
      {id !== "-1" ? (
        value === 0 ? (
          <InfoDrug value={value} type_of_content={type_of_content} />
        ) : value === 1 ? (
          <InfoCompany type_of_content={type_of_content} />
        ) : value === 2 ? (
          <InfoCompetitive type_of_content={type_of_content} />
        ) : value === 4 ? (
          <InfoGlobalDolProfile />
        ) : (
          <InfoDolProfile />
        )
      ) : (
        <h2> {null}</h2>
      )}
    </div>
  );
}
