import { api } from "../config";
import { format } from "date-fns";

export const setDrugCompetiveChoose = (drug) => (dispatch) => {
  dispatch({
    type: "SET_DRUG_COMPETITIVE_CHOOSE",
    value: drug,
  });
};

export const getCompetitiveGraph = (id_drug, period) => async (dispatch) => {
  let id_drug_sent = id_drug.join(";");
  try {
    const { data } = await api.get(`data/volume/drug/competitive/graph`, {
      params: {
        id_drug: id_drug_sent,
        period: period,
      },
    });
    dispatch({
      type: "GET_COMPETITIVE_GRAPH",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DRUG",
      value: err,
    });
  }
  dispatch({
    type: "SET_COMPETITIVE_GRAPH_LOADING_ANIMATION",
    value: false,
  });
};

export const getCompetitiveGraphDisease =
  (id_drug, disease) => async (dispatch) => {
    let id_drug_sent = id_drug.join(";");
    let disease_sent = disease
      .map(function (el) {
        return el["id_disease"];
      })
      .join(";");
    try {
      const { data } = await api.get(`data/volume/drug/competitive/disease`, {
        params: {
          id_drug: id_drug_sent,
          id_disease: disease_sent,
        },
      });
      dispatch({
        type: "GET_COMPETITIVE_GRAPH",
        value: data,
      });
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_DRUG",
        value: err,
      });
    }
    dispatch({
      type: "SET_COMPETITIVE_GRAPH_LOADING_ANIMATION",
      value: false,
    });
  };

export const getCompetitiveGraphPersonas =
  (id_drug) => async (dispatch) => {
    let id_drug_sent = id_drug.join(";");
    try {
      const { data } = await api.get(`data/volume/drug/competitive/dol`, {
        params: {
          id_drug: id_drug_sent,
        },
      });
      dispatch({
        type: "GET_COMPETITIVE_GRAPH",
        value: data,
      });
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_DRUG",
        value: err,
      });
    }
    dispatch({
      type: "SET_COMPETITIVE_GRAPH_LOADING_ANIMATION",
      value: false,
    });
  };

export const getCompetitiveTreeMap =
  (date, id_drug, metric1, metric2, period = "quarter") =>
  async (dispatch) => {
    let id_drug_sent = id_drug.join(";");
    try {
      const { data } = await api.get(`data/volume/drug/competitive/treemap`, {
        params: {
          date: format(new Date(date), "yyyy-MM-dd"),
          id_drug: id_drug_sent,
          metric1: metric1,
          metric2: metric2,
          period: period,
        },
      });
      dispatch({
        type: "GET_COMPETITIVE_TREEMAP",
        value: data,
      });
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_DRUG",
        value: err,
      });
    }
    dispatch({
      type: "SET_COMPETITIVE_TREEMAP_LOADING_ANIMATION",
      value: false,
    });
  };

export const getCompetitiveGraphByTarget =
  (depth, disease, target, type, theraFranchise) => async (dispatch) => {
    let disease_sent = disease
      .map(function (el) {
        return el["id_disease"];
      })
      .join(";");
    let target_sent = target
      .map(function (el) {
        return el["id_drug_target"];
      })
      .join(";");

    let theraFranchise_sent = theraFranchise
      .map(function (el) {
        return el["id_therapeutic_franchise"];
      })
      .join(";");
    let type_sent = type.join(";");
    let phase_sent = depth.join(";");

    try {
      const { data } = await api.get(
        `data/volume/drug/competitive/target/graph`,
        {
          params: {
            disease: disease_sent,
            target: target_sent,
            theraFranchise: theraFranchise_sent,
            type: type_sent,
            phase: phase_sent,
          },
        }
      );
      dispatch({
        type: "GET_COMPETITIVE_GRAPH_BY_TARGET",
        value: data,
      });
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_DRUG",
        value: err,
      });
    }
    dispatch({
      type: "SET_COMPETITIVE_GRAPH_TARGET_LOADING_ANIMATION",
      value: false,
    });
  };

export const getCompetitiveGraphByType =
  (depth, disease, target, type, theraFranchise) => async (dispatch) => {
    let disease_sent = disease
      .map(function (el) {
        return el["id_disease"];
      })
      .join(";");
    let target_sent = target
      .map(function (el) {
        return el["id_drug_target"];
      })
      .join(";");

    let theraFranchise_sent = theraFranchise
      .map(function (el) {
        return el["id_therapeutic_franchise"];
      })
      .join(";");

    let type_sent = type
      .map(function (el) {
        return el["id_drug_type"];
      })
      .join(";");

    let phase_sent = depth.join(";");

    try {
      const { data } = await api.get(
        `data/volume/drug/competitive/type/graph`,
        {
          params: {
            disease: disease_sent,
            target: target_sent,
            theraFranchise: theraFranchise_sent,
            drug_type: type_sent,
            phase: phase_sent,
          },
        }
      );
      dispatch({
        type: "GET_COMPETITIVE_GRAPH_BY_TYPE",
        value: data,
      });
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_DRUG",
        value: err,
      });
    }
    dispatch({
      type: "SET_COMPETITIVE_GRAPH_TYPE_LOADING_ANIMATION",
      value: false,
    });
  };

export const getCompetitiveGraphByCompany =
  (depth, disease, target, type, theraFranchise) => async (dispatch) => {
    let disease_sent = disease
      .map(function (el) {
        return el["id_disease"];
      })
      .join(";");
    let target_sent = target
      .map(function (el) {
        return el["id_drug_target"];
      })
      .join(";");

    let theraFranchise_sent = theraFranchise
      .map(function (el) {
        return el["id_therapeutic_franchise"];
      })
      .join(";");
    let type_sent = type.join(";");
    let phase_sent = depth.join(";");

    try {
      const { data } = await api.get(
        `data/volume/drug/competitive/company/graph`,
        {
          params: {
            disease: disease_sent,
            target: target_sent,
            theraFranchise: theraFranchise_sent,
            type: type_sent,
            phase: phase_sent,
          },
        }
      );
      dispatch({
        type: "GET_COMPETITIVE_GRAPH_BY_COMPANY",
        value: data,
      });
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_DRUG",
        value: err,
      });
    }
    dispatch({
      type: "SET_COMPETITIVE_GRAPH_COMPANY_LOADING_ANIMATION",
      value: false,
    });
  };

export const getCompetitiveData = (disease, target) => async (dispatch) => {
  let disease_sent = disease
    .map(function (el) {
      return el["id_disease"];
    })
    .join(";");
  let target_sent = target
    .map(function (el) {
      return el["id_drug_target"];
    })
    .join(";");
  try {
    const { data } = await api.get(`data/volume/drug/competitive/data`, {
      params: {
        disease: disease_sent,
        target: target_sent,
      },
    });
    dispatch({
      type: "GET_COMPETITIVE_DATA",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DRUG",
      value: err,
    });
  }
  dispatch({
    type: "SET_COMPETITIVE_DATA_LOADING_ANIMATION",
    value: false,
  });
};

export const getCompetitiveDataDrugGraph =
  (disease, target) => async (dispatch) => {
    let disease_sent = disease
      .map(function (el) {
        return el["id_disease"];
      })
      .join(";");
    let target_sent = target
      .map(function (el) {
        return el["id_drug_target"];
      })
      .join(";");
    try {
      const { data } = await api.get(
        `data/volume/drug/competitive/timechartData`,
        {
          params: {
            disease: disease_sent,
            target: target_sent,
          },
        }
      );
      dispatch({
        type: "GET_COMPETITIVE_TIMECHART_DATA",
        value: data,
      });
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_DRUG",
        value: err,
      });
    }
    dispatch({
      type: "SET_GRAPH_COMPETITIVE_DATA_DRUG_LOADING_ANIMATION",
      value: false,
    });
  };

export const getNCTDiseaseGraph = (disease, target) => async (dispatch) => {
  let disease_sent = disease
    .map(function (el) {
      return el["id_disease"];
    })
    .join(";");
  let target_sent = target
    .map(function (el) {
      return el["id_drug_target"];
    })
    .join(";");
  try {
    const { data } = await api.get(`data/NCT/treatment/graph`, {
      params: {
        disease: disease_sent,
        target: target_sent,
      },
    });
    dispatch({
      type: "GET_NCT_GRAPH",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DRUG",
      value: err,
    });
  }
  dispatch({
    type: "SET_GRAPH_NCT_DISEASE_LOADING_ANIMATION",
    value: false,
  });
};

export const getNCTDiseaseTab = (disease, target) => async (dispatch) => {
  let disease_sent = disease
    .map(function (el) {
      return el["id_disease"];
    })
    .join(";");
  let target_sent = target
    .map(function (el) {
      return el["id_drug_target"];
    })
    .join(";");

  try {
    const { data } = await api.get(`data/NCT/treatment/tab`, {
      params: {
        disease: disease_sent,
        target: target_sent,
      },
    });
    dispatch({
      type: "GET_NCT_TAB",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DRUG",
      value: err,
    });
  }
  dispatch({
    type: "SET_NCT_DISEASE_TAB_LOADING_ANIMATION",
    value: false,
  });
};

export const getCatalystCompetitiveTab =
  (date, disease, target) => async (dispatch) => {
    let disease_sent = disease
      .map(function (el) {
        return el["id_disease"];
      })
      .join(";");
    let target_sent = target
      .map(function (el) {
        return el["id_drug_target"];
      })
      .join(";");

    try {
      const { data } = await api.get(`data/competitive/catalyst/table`, {
        params: {
          date: format(new Date(date), "yyyy-MM-dd"),
          disease: disease_sent,
          target: target_sent,
        },
      });
      dispatch({
        type: "GET_CATALYST_COMPANY_TAB",
        value: data,
      });
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_DRUG",
        value: err,
      });
    }
    dispatch({
      type: "SET_TAB_CATALYST_COMPETITIVE_LOADING_ANIMATION",
      value: false,
    });
  };

export const getNCTTreatmentDetailsGraph =
  (disease, target) => async (dispatch) => {
    let disease_sent = disease
      .map(function (el) {
        return el["id_disease"];
      })
      .join(";");
    let target_sent = target
      .map(function (el) {
        return el["id_drug_target"];
      })
      .join(";");

    try {
      const { data } = await api.get("data/NCT/treatment/details", {
        params: {
          disease: disease_sent,
          target: target_sent,
        },
      });
      dispatch({
        type: "GET_DETAILS_COMPANY_GRAPH",
        value: data,
      });
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_DRUG",
        value: err,
      });
    }
    dispatch({
      type: "SET_NCT_TREATMENT_DETAILS_GRAPH_LOADING_ANIMATION",
      value: false,
    });
  };
