import React from "react";
import { useSelector } from "react-redux";

export function DetailsPhaseBox() {
  const info = useSelector((state) => state.company.allDrugTab);
  const compteurs = {
    Approved: 0,
    Filed: 0,
    "Phase 3": 0,
    "Phase 2": 0,
    "Phase 1": 0,
  };

  info.reduce((acc, i) => {
    const { status } = i;
    if (status === "phase 1") acc["Phase 1"]++;
    else if (status === "phase 2") acc["Phase 2"]++;
    else if (status === "phase 1/phase 2") acc["Phase 2"]++;
    else if (status === "phase 3") acc["Phase 3"]++;
    if (status === "nda/bla") acc.Filed++;
    else if (status === "approved") acc.Approved++;
    return acc;
  }, compteurs);

  return (
    <div className="info-pannel__company-pannel__details--rank">
      {Object.keys(compteurs).map((keyName, i) => (
        <div className="info-pannel__competitive-pannel__details">
          <div className="info-pannel__competitive-pannel__details--rank">
            {" "}
            {compteurs[keyName]}
          </div>
          <div> {keyName} </div>
        </div>
      ))}
    </div>
  );
}
