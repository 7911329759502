import React ,{useState,  useEffect} from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDispatch , useSelector } from 'react-redux'

import {setFilterTT } from '../../../Action'

export function BtnMarketCapDisplay() {

  const dispatch = useDispatch();
  const depth = useSelector(state => state.diease.statusCompanyDisplay);
  const static_depth = ['Nano-cap', 'Micro-cap', 'Small-cap', 'Mid-cap', 'Big-cap', 'Listed','Private']
  const [state, setState] = useState({
    'Nano-cap': !!depth.find((el) => el === 'Nano-cap'),
    'Micro-cap' : !!depth.find((el) => el === 'Micro-cap'),
    'Small-cap' : !!depth.find((el) => el === 'Small-cap'),
    'Mid-cap' : !!depth.find((el) => el === 'Mid-cap'),
    'Big-cap': !!depth.find((el) => el === 'Big-cap'),
    'Listed': !!depth.find((el) => el === 'Listed'),
    'Private': !!depth.find((el) => el === 'Private'),
  });

  useEffect(() => {
    setState({
      'Nano-cap': !!depth.find((el) => el === 'Nano-cap'),
      'Micro-cap' : !!depth.find((el) => el === 'Micro-cap'),
      'Small-cap' : !!depth.find((el) => el === 'Small-cap'),
      'Mid-cap' : !!depth.find((el) => el === 'Mid-cap'),
      'Big-cap': !!depth.find((el) => el === 'Big-cap'),
      'Listed': !!depth.find((el) => el === 'Listed'),
      'Private': !!depth.find((el) => el === 'Private'),
    });
  }, [dispatch, depth]);

  const handleChange = (event) => {
    dispatch(setFilterTT(event.target.name))
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div className="control-pannel-search-entity">
      <h3 className='control-pannel-search-entity--title'>Market Cap </h3><br />
      <div className='phase-list'>
        {static_depth.map((el, i) => (
          <FormControlLabel 
          key={i}
          control={
            <label class="btn-phase-container">
              <input type="checkbox" checked={state[el]} onChange={handleChange} name={el}></input>
              <svg viewBox="0 0 64 64" height="2em" width="2em">
                <path d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16" pathLength="575.0541381835938" class="path"></path>
              </svg>
            </label>            
          }
          label={el}
          />
        ))}
      </div>
    </div>
  );
}