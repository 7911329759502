import React  from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';


export function MUIChevron(){


  return (
    <ChevronLeftIcon className="congress_pannel_chevron-left" fontSize="large" />
  );
} 