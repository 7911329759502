import { format } from "date-fns";

import { api } from "../config";

export const getTdDrugData =
  (date, filtre, nb = 100, period = "week") =>
  async (dispatch) => {
    try {
      const { data } = await api.get("data/volume/drug/trend", {
        params: {
          date: format(new Date(date), "yyyy-MM-dd"),
          filtre: filtre,
          nb: nb,
          period: period,
        },
      });
      dispatch({
        type: "GET_ALL_TD_DATA",
        value: data,
      });
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_DRUG",
        value: err,
      });
    }
    dispatch({
      type: "SET_DATA_LOADING_ANIMATION",
      value: false,
    });
  };

export const getTweetDrugData =
  (drugId, date, period = "week") =>
  async (dispatch) => {
    try {
      const { data } = await api.get(`data/volume/drug/${drugId}/tweet`, {
        params: {
          date: format(new Date(date), "yyyy-MM-dd"),
          period: period,
        },
      });
      dispatch({
        type: "GET_DRUG_TWEET",
        value: data,
      });
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_DRUG",
        value: err,
      });
    }
    dispatch({
      type: "SET_DRUG_TWEET_DATA_LOADING_ANIMATION",
      value: false,
    });
  };

export const getAllDrug = () => async (dispatch) => {
  try {
    const { data } = await api.get("data/drug");
    dispatch({
      type: "GET_ALL_DRUG",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DRUG",
      value: err,
    });
  }
};

export const getAllDrugCompetitive = () => async (dispatch) => {
  try {
    const { data } = await api.get("data/drug");
    dispatch({
      type: "GET_ALL_DRUG",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DRUG",
      value: err,
    });
  }
  dispatch({
    type: "SET_COMPETITIVE_DATA_LOADING_ANIMATION",
    value: false,
  });
};

export const getDynamicDrug =
  (name_drug, theraFranchise, disease, MOA) => async (dispatch) => {
    let disease_sent = disease
      .map(function (el) {
        return el["id_disease"];
      })
      .join(";");
    let moa_sent = MOA.map(function (el) {
      return el["id_moa"];
    }).join(";");
    let t_sent = theraFranchise
      .map(function (el) {
        return el["id_moa"];
      })
      .join(";");
    try {
      const { data } = await api.get("data/drug/dynamic_search", {
        params: {
          drug_name: name_drug,
          disease: disease_sent,
          moa: moa_sent,
          thera: t_sent,
        },
      });
      dispatch({
        type: "GET_DRUG_DYNAMIC_SEARCH",
        value: data,
      });
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_DRUG",
        value: err,
      });
    }
    dispatch({
      type: "SET_DRUG_DYNAMIC_SEARCH_LOADING_ANIMATION",
      value: false,
    });
  };

export const getInfoDrug = (name) => async (dispatch) => {
  try {
    const { data } = await api.get(`data/drug/${name}`);
    dispatch({
      type: "GET_INFO_BY_DRUG",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DRUG",
      value: err,
    });
  }
  dispatch({
    type: "SET_INFO_LOADING_ANIMATION",
    value: false,
  });
};

export const getDataByDrug =
  (name, disease, period = "day", is_disease) =>
  async (dispatch) => {
    let disease_sent = disease
      .map(function (el) {
        return el["id_disease"];
      })
      .join(";");
    try {
      const { data } = await api.get(`data/volume/drug/${name}`, {
        params: {
          period: period,
          disease_selected: disease_sent,
        },
      });

      if (!is_disease) {
        dispatch({
          type: "GET_DATA_BY_DRUG",
          value: data,
        });
      }
      else{
        dispatch({
          type: "GET_DATA_BY_DRUG_DISEASE",
          value: data,
        });
      }
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_DRUG",
        value: err,
      });
    }
    dispatch({
      type: "SET_GRAPH_DRUG_LOADING_ANIMATION",
      value: false,
    });
  };



  export const getDataByDrugDol =
  (name,period = "day", personas) =>
  async (dispatch) => {
    try {
      const { data } = await api.get(`data/volume/drug/${name}/dol`, {
        params: {
          period: period,
          personas: personas
        },
      });
        dispatch({
          type: "GET_DATA_BY_DRUG_DOL",
          value: data,
        });
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_DRUG",
        value: err,
      });
    }
    dispatch({
      type: "SET_GRAPH_DRUG_LOADING_ANIMATION",
      value: false,
    });
  };

  export const getDataByDrugDolDisease =
  (name, disease, period = "day", personas) =>
  async (dispatch) => {
    let disease_sent = disease
      .map(function (el) {
        return el["id_disease"];
      })
      .join(";");
    try {
      const { data } = await api.get(`data/volume/drug/${name}/disease/dol`, {
        params: {
          period: period,
          disease_selected: disease_sent,
          personas: personas
        },
      });
        dispatch({
          type: "GET_DATA_BY_DRUG_DISEASE_DOL",
          value: data,
        });
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_DRUG",
        value: err,
      });
    }
    dispatch({
      type: "SET_GRAPH_DRUG_LOADING_ANIMATION",
      value: false,
    });
  };


export const getDataDisease =
  (name, period = "day") =>
  async (dispatch) => {
    try {
      const { data } = await api.get(`data/volume/disease/${name}`, {
        params: {
          period: period,
        },
      });
      dispatch({
        type: "GET_DATA_VOLUME_BY_DISEASE",
        value: data,
      });
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_DRUG",
        value: err,
      });
    }
    dispatch({
      type: "SET_GRAPH_DRUG_LOADING_ANIMATION",
      value: false,
    });
  };

export const getDataDiseaseTable =
  (date, idDisease, period = "week") =>
  async (dispatch) => {
    try {
      const { data } = await api.get(`data/volume/disease/${idDisease}/table`, {
        params: {
          date: format(new Date(date), "yyyy-MM-dd"),
          period: period,
        },
      });
      dispatch({
        type: "GET_DATA_TABLE_VOLUME_BY_DISEASE",
        value: data,
      });
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_DRUG",
        value: err,
      });
    }
    dispatch({
      type: "SET_VOLUME_DISEASE_TAB_LOADING_ANIMATION",
      value: false,
    });
  };

export const getDataTreeMapTD =
  (date, nb_drugs, filtre, metric1, metric2, period = "day") =>
  async (dispatch) => {
    try {
      const { data } = await api.get("data/volume/drug/treemap", {
        params: {
          date: format(new Date(date), "yyyy-MM-dd"),
          nb: nb_drugs,
          filtre: filtre,
          metric1: metric1,
          metric2: metric2,
          period: period,
        },
      });
      dispatch({
        type: "GET_DATA_TREEMAP_TD",
        value: data,
      });
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_DRUG",
        value: err,
      });
    }
    dispatch({
      type: "SET_TM_LOADING_ANIMATION",
      value: false,
    });
  };

export const getAlertPublications = (date) => async (dispatch) => {
  try {
    const { data } = await api.get(`data/publication/alert`, {
      params: { date: format(new Date(date), "yyyy-MM-dd") },
    });
    dispatch({
      type: "GET_ALERTS_PUBLICATION_DATA",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DRUG",
      value: err,
    });
  }
  dispatch({
    type: "SET_ALERT_PUBLICATION_LOADING_ANIMATION",
    value: false,
  });
};

export const getAlertRSS = (date) => async (dispatch) => {
  try {
    const { data } = await api.get(`data/RSS/drug`, {
      params: {
        date: format(new Date(date), "yyyy-MM-dd"),
      },
    });
    dispatch({
      type: "GET_ALERTS_RSS_DATA",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DRUG",
      value: err,
    });
  }
  dispatch({
    type: "SET_ALERT_RSS_LOADING_ANIMATION",
    value: false,
  });
};

export const getAlertNCT = (date) => async (dispatch) => {
  try {
    const { data } = await api.get(`data/NCT/alert`, {
      params: {
        date: format(new Date(date), "yyyy-MM-dd"),
      },
    });
    dispatch({
      type: "GET_ALERTS_NCT_DATA",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DRUG",
      value: err,
    });
  }
  dispatch({
    type: "SET_ALERT_NCT_TAB_LOADING_ANIMATION",
    value: false,
  });
};

export const getAlertTreatment = (date) => async (dispatch) => {
  try {
    const { data } = await api.get(`data/treatment/alert`, {
      params: {
        date: format(new Date(date), "yyyy-MM-dd"),
      },
    });
    dispatch({
      type: "GET_ALERTS_TREATMENT_DATA",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DRUG",
      value: err,
    });
  }
  dispatch({
    type: "SET_TREATMENT_TAB_LOADING_ANIMATION",
    value: false,
  });
};

export const getDataTopics = (date, drugId, period, persona) => async (dispatch) => {
  try {
    const { data } = await api.get(`data/topic/drug/${drugId}`, {
      params: {
        date: format(new Date(date), "yyyy-MM-dd"),
        period: period,
        persona: persona
      },
    });
    dispatch({
      type: "GET_TOPICS",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DRUG",
      value: err,
    });
  }
  dispatch({
    type: "SET_GRAPH_RADAR_LOADING_ANIMATION",
    value: false,
  });
};

export const getPublications =
  (date, drugId, all_id, period = "None") =>
  async (dispatch) => {
    let all_id_sent = all_id.join(",");
    try {
      const { data } = await api.get(`data/publication/drug/${drugId}`, {
        params: {
          date: date ? format(new Date(date), "yyyy-MM-dd") : "",
          id_drug_competitve: all_id_sent,
          period: period,
        },
      });
      if (date) {
        dispatch({
          type: "GET_PUBLICATIONS",
          value: data,
        });
      } else {
        dispatch({
          type: "GET_ALL_PUBLICATIONS",
          value: data,
        });
      }
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_DRUG",
        value: err,
      });
    }
    date !== null
      ? dispatch({
          type: "SET_TAB_SHARE_LOADING_ANIMATION",
          value: false,
        })
      : dispatch({
          type: "SET_BIG_TAB_SHARE_LOADING_ANIMATION",
          value: false,
        });
  };

export const getWorldCloudData = (drugId, date, persona, period) => async (dispatch) => {
  try {
    const { data } = await api.get(`data/worldcloud/drug/${drugId}/disease`, {
      params: {
        date: date ? format(new Date(date), "yyyy-MM-dd") : "",
        period: period,
        persona : persona
      },
    });
    dispatch({
      type: "GET_WORLD_CLOUD_DRUG_DISEASE",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DRUG",
      value: err,
    });
  }
  dispatch({
    type: "SET_WROLD_CLOUD_DRUG_DISEASE_LOADING_ANIMATION",
    value: false,
  });
};

export const setNumberDrugsDisplay = (nb_drug_display) => async (dispatch) => {
  dispatch({
    type: "SET_NB_DRUGS_DISPLAY",
    value: nb_drug_display,
  });
};

export const setTDMetric1 = (metric) => (dispatch) => {
  dispatch({
    type: "SET_TD_METRIC1",
    value: metric,
  });
};

export const setTDMetric2 = (metric) => (dispatch) => {
  dispatch({
    type: "SET_TD_METRIC2",
    value: metric,
  });
};

export const setTopicsSelected = (topic) => (dispatch) => {
  dispatch({
    type: "SET_TOPICS_SELECTED",
    value: topic,
  });
};

export const setDiseaseWCloudSelected = (disease) => (dispatch) => {
  dispatch({
    type: "SET_DISEASE_WORD_CLOUD_SELECTED",
    value: disease,
  });
};

export const setFilterTD = (phase) => async (dispatch) => {
  dispatch({
    type: "SET_TREEMAP_TD_DEPTH",
    value: phase,
  });
};
export const setFilterDiseaseTD = (disease) => async (dispatch) => {
  dispatch({
    type: "SET_TREEMAP_TD_DISEASE",
    value: disease,
  });
};

export const getTreatmentGraph = (drugId) => async (dispatch) => {
  try {
    const { data } = await api.get(`data/drug/${drugId}/treatment/graph`);
    dispatch({
      type: "GET_DRUG_TREATMENT_GRAPH",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DRUG",
      value: err,
    });
  }
  dispatch({
    type: "SET_GRAPH_TREATMENT_LOADING_ANIMATION",
    value: false,
  });
};

export const getNCTGraph = (drugId) => async (dispatch) => {
  try {
    const { data } = await api.get(`data/NCT/drug/${drugId}/graph`);
    dispatch({
      type: "GET_NCT_GRAPH",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DRUG",
      value: err,
    });
  }
  dispatch({
    type: "SET_NCT_GRAPH_LOADING_ANIMATION",
    value: false,
  });
};

export const getPhaseGraph = (drugId) => async (dispatch) => {
  try {
    const { data } = await api.get(`data/drug/${drugId}/phase`);
    dispatch({
      type: "GET_PHASE_TIMELINE_GRAPH",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DRUG",
      value: err,
    });
  }
  dispatch({
    type: "SET_GRAPH_PHASE_DRUG_LOADING_ANIMATION",
    value: false,
  });
};

export const get_info_by_nct = (nctNumber) => async (dispatch) => {
  try {
    const { data } = await api.get(`data/NCT/${nctNumber}`);
    dispatch({
      type: "GET_INFO_BY_NCT",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DRUG",
      value: err,
    });
  }
  dispatch({
    type: "SET_NCT_GRAPH_LOADING_ANIMATION",
    value: false,
  });
};

export const getNCTTab = (drugId) => async (dispatch) => {
  try {
    const { data } = await api.get(`data/NCT/drug/${drugId}`);
    dispatch({
      type: "GET_NCT_TAB",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DRUG",
      value: err,
    });
  }
  dispatch({
    type: "SET_NCT_TAB_LOADING_ANIMATION",
    value: false,
  });
};

export const getRSSTab = (drugId) => async (dispatch) => {
  try {
    const { data } = await api.get(`data/RSS/drug/${drugId}`);
    dispatch({
      type: "GET_RSS_TAB",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DRUG",
      value: err,
    });
  }
  dispatch({
    type: "SET_RSS_TAB_LOADING_ANIMATION",
    value: false,
  });
};

export const getRSSDiseaseTab = (disease, target) => async (dispatch) => {
  let disease_sent = disease
    .map(function (el) {
      return el["id_disease"];
    })
    .join(",");
  let target_sent = target
    .map(function (el) {
      return el["id_drug_target"];
    })
    .join(",");
  try {
    const { data } = await api.get(`data/RSS/disease`, {
      params: {
        disease: disease_sent,
        target: target_sent,
      },
    });
    dispatch({
      type: "GET_RSS_DISEASE_TAB",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DRUG",
      value: err,
    });
  }
  dispatch({
    type: "SET_RSS_DISEASE_LOADING_ANIMATION",
    value: false,
  });
};
