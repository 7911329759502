import React from "react";
import { useSelector } from "react-redux";

export function DetailsCompetitiveTherapeuticsBox() {
  const info = useSelector((state) => state.drug.NCTTab);

  const compteurs = {
    'Phase 3': 0,
    'Phase 2': 0,
    'Phase 1': 0,
  };

  info.reduce((acc, i) => {
    const { phase } = i;
    if (phase === "phase 1") acc['Phase 1']++;
    else if (phase === "phase 2") acc['Phase 2']++;
    else if (phase === "phase 1/phase 2") acc['Phase 2']++;
    else if (phase === "phase 3") acc['Phase 3']++;
    return acc;
  }, compteurs);

  return (
    <div className="info-pannel__company-pannel__details">
      {Object.keys(compteurs).map((keyName, i) => (
        <div className="info-pannel__competitive-pannel__details"> 
        <div className="info-pannel__competitive-pannel__details--rank"> {compteurs[keyName]}</div>
        <div> {keyName} </div>
      </div>
      ))}
    </div>
  );
}