import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";

import { DataFilterTable, LoadingAnimation } from "..";

import { get_filter_key } from "../../utils";
import { getCatalystCompetitiveTab, setTabCatalystCompetitiveLoadingAnimation } from "../../Action";

export function CatalystCompetitiveTable(props) {
  const dispatch = useDispatch();
  const date = useSelector((state) => state.config.date);
  const data = useSelector((state) => state.company.catalystTab);
  const disease = useSelector((state) => state.diease.diseaseDisplay);
  const target = useSelector((state) => state.diease.drugTargetDisplay);
  const navigate = useNavigate();
  const load = useSelector((state) => state.config.tabCatalystCompetitiveLoading);

  const go_to = (name, id) => {
    if (name === "drug") {
      navigate(`/drug/${id}`);
    }
    if (name === "nct") {
      const win = window.open(
        "https://www.clinicaltrials.gov/ct2/show/" + id,
        "_blank"
      );
      if (win != null) win.focus();
    }
  };

  useEffect(() => {
    dispatch(getCatalystCompetitiveTab(date, disease, target));
    dispatch(setTabCatalystCompetitiveLoadingAnimation(true));
  }, [date, disease, target, dispatch]);

  const columns = [
    {
      name: "catalyst_date",
      header: "Catalyst Date",
      defaultFlex: 1,
      maxWidth: 133,
      minWidth: 100,
    },
    {
      name: "nct",
      header: "NCT",
      defaultFlex: 1,
      minWidth: 100,
      maxWidth: 100,
      render: ({ value, data }) => (
        <span className="title-link" onClick={() => go_to("nct", data.nct)}>
          {value}
        </span>
      ),
    },
    {
      name: "drug_display_name",
      header: "Therapeutics",
      defaultFlex: 1,
      minWidth: 145,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("drug_display_name", data),
      },
      render: ({ value, data }) => (
        <span
          className="title-link"
          onClick={() => go_to("drug", data.id_drug)}
        >
          {value}
        </span>
      ),
    },
    {
      name: "catalyst_type",
      header: "Catalyst",
      defaultFlex: 1,
      minWidth: 220,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("catalyst_type", data),
      },
    },
    {
      name: "trial_phase",
      header: "Phase",
      defaultFlex: 1,
      minWidth: 120,
      maxWidth: 180,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("trial_phase", data),
      },
    },
    {
      name: "company_display_name",
      header: "Company",
      defaultFlex: 1,
      minWidth: 110,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("company_display_name", data),
      },
      render: ({ value, data }) => (
        <span
          className="title-link"
          onClick={() => go_to("company", data.id_company)}
        >
          {value}
        </span>
      ),
    },
    {
      name: "value_date",
      header: "Announcement",
      defaultFlex: 1,
      minWidth: 145,
      maxWidth: 180,
    },
  ];

  const filterValue = [
    {
      name: "drug_display_name",
      operator: "inlist",
      type: "select",
      value: "",
    },
    { name: "catalyst_type", operator: "inlist", type: "select", value: "" },
    { name: "trial_phase", operator: "inlist", type: "select", value: "" },
    {
      name: "company_display_name",
      operator: "inlist",
      type: "select",
      value: "",
    },
    { name: "partnership_role", operator: "inlist", type: "select", value: "" },
    { name: "display_name", operator: "inlist", type: "select", value: "" },
    //{
    //  name: "disease_name",
    //  operator: "inlist",
    //  type: "select",
    //  value: "",
    //},
  ];

  return (
    <div className="competitive-graph-pannel__news-tab--catalyst-table">
      {load === false ? (
        <DataFilterTable
          dataSource={data}
          columns={columns}
          enableFiltering={true}
          filterValue={filterValue}
          defaultFilterName={[]}
        />
      ) : (
        <div className="loading-animation" style={{height:"100%"}}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
