import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  removeMyDisease,
  remove_profession_group,
  remove_speciality,
  removeMyTarget,
  removeMyDrugType,
  removeMyTheraFranchise,
} from "../../../../../Action";

function Chip(props) {
  const { label, action } = props;
  return (
    <div className="chip">
      <div className="chip__content">{label}</div>
      <button className="chip__button" onClick={action}>
        <div className="chip__button-line chip__button-line--first"></div>
        <div className="chip__button-line chip__button-line--second"></div>
      </button>
    </div>
  );
}

export function DolLrxChips() {
  const disease = useSelector((state) => state.diease.diseaseDisplay);
  const speciality = useSelector((state) => state.diease.speciality_user);

  const speciality_group = useSelector(
    (state) => state.diease.profession_group_user
  );
  const theraFranchise = useSelector(
    (state) => state.diease.theraFranchiseDisplay
  );
  const target = useSelector((state) => state.diease.drugTargetDisplay);
  const type = useSelector((state) => state.diease.drugTypeDisplay);

  const dispatch = useDispatch();

  return (
    <span>
      {speciality_group.map((el, i) => (
        <Chip
          key={i}
          label={el.profession_group_display_name}
          action={() =>
            dispatch(remove_profession_group(el.id_profession_group))
          }
        />
      ))}
      {speciality.map((el, i) => (
        <Chip
          key={i}
          label={el.profession_display_name}
          action={() => dispatch(remove_speciality(el.id_profession))}
        />
      ))}
      {theraFranchise.map((el, i) => (
        <Chip
          key={i}
          label={el.therapeutic_franchise_name}
          action={() =>
            dispatch(
              removeMyTheraFranchise(parseInt(el.id_therapeutic_franchise))
            )
          }
        />
      ))}
      {disease.map((el, i) => (
        <Chip
          key={i}
          label={el.disease_display_name}
          action={() => dispatch(removeMyDisease(el.id_disease))}
        />
      ))}
      {target.map((el, i) => (
        <Chip
          key={i}
          label={el.display_name}
          action={() => dispatch(removeMyTarget(parseInt(el.id_drug_target)))}
        />
      ))}

      {type.map((el, i) => (
        <Chip
          key={i}
          label={el.drug_type}
          action={() => dispatch(removeMyDrugType(el.id_drug_type))}
        />
      ))}
    </span>
  );
}
