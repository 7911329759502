import React from "react";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { setTopicsSelected } from "../../../../../Action";
import { useDispatch } from "react-redux";

export function PieChartTopics(props) {
  const { data, fontSize, innerRadius, outerRadius, displayName } = props;
  const dispatch = useDispatch();

  const getArcLabel = (params) => {
    if(displayName) {
      return params.label;
    } else{
      return null;
    }
  };

  const change_topics = (event, itemIdentifier, item) => {
    if (event) {
      dispatch(setTopicsSelected(item.label));
    }
  };

  return (
    <PieChart
      series={[
        {
          data,
          highlightScope: {faded: 'global', highlighted: 'item'},
          highlighted: {additionalRadius: 15},
          arcLabel: getArcLabel,
          arcLabelMinAngle: 7,
          innerRadius: innerRadius,
          outerRadius: outerRadius,
          paddingAngle: 1,
          cornerRadius: 3,
        },
      ]}
      onClick={change_topics}
      margin={{ left: 110 }}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: "black",
          fontSize: fontSize,
          width: 100,
          height: "100%",
        },
      }}
      slotProps={{
        legend: { hidden: true },
      }}
    ></PieChart>
  );
}
