import React, { useEffect} from "react";
import { useDispatch , useSelector} from 'react-redux'

import {LoadingAnimation} from '../../components'
import { filterNCTPhaseCondition } from "../../utils";
import {TimeLineChart} from '../../components'
import {getNCTGraph, setNCTGraphAnimation} from '../../Action';
 
export function NCTGraph(props) {
  const {drugId} = props;
  const dispatch = useDispatch();
  const load = useSelector (state =>  state.config.graphNCTLoading);
  const depth = useSelector((state) => state.diease.statusDrugDisplay);
  const dataNCT = useSelector (state =>  state.drug.NCTgraph);

  const filterData = dataNCT
  ? filterNCTPhaseCondition(
    dataNCT,
      depth,
    )
  : [];

  filterData.map((l,index) => {
    if (index !== 0) {
      l[3] = new Date(l[3])
      l[4] = new Date(l[4])
    }
    return ([l[3],l[4]])
  })

  useEffect(() => {
        dispatch(getNCTGraph(drugId));
        dispatch(setNCTGraphAnimation(true))
  }, [drugId ,dispatch]);

  return (
    <div>
      {load === false ?
        <div style={{marginTop: "1%", marginBottom:'1%'}}>
          <TimeLineChart dataAD={filterData} content={"treatment"} infoDrug={dataNCT} display={'all'}/> 
        </div>
      :
        <div className="loading-animation" style={{height:"200px"}}>
          <LoadingAnimation type={'circular'}/>
        </div>
      }
    </div>
    )
}