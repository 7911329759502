import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { LoadingAnimation } from "../../components";
import { DataFilterTable } from "../../components";
import {
  getAllCompanyDrugTab,
  setCompanyDrugTabLoadingAnimation,
} from "../../Action";

import { filterDataDrug, get_filter_key } from "../../utils";

import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";

export function AllDrugCompanyTable(props) {
  const { filtre } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const d = useSelector((state) => state.company.allDrugTab);
  const load = useSelector((state) => state.config.tabDrugCompanyLoading);

  const depth = useSelector((state) => state.diease.statusDrugDisplay);
  const disease = useSelector((state) => state.diease.diseaseDisplay);
  const type = useSelector((state) => state.diease.drugTypeDisplay);
  const MOADisplay = useSelector((state) => state.diease.MOADisplay);
  const theraFranchise = useSelector(
    (state) => state.diease.theraFranchiseDisplay
  );
  const targetDisplay = useSelector((state) => state.diease.drugTargetDisplay);

  const data = filterDataDrug(
    d,
    depth,
    disease,
    type,
    MOADisplay,
    theraFranchise,
    targetDisplay
  );

  useEffect(() => {
    if (filtre !== -1) {
      dispatch(getAllCompanyDrugTab(filtre));
      dispatch(setCompanyDrugTabLoadingAnimation(true));
    }
  }, [filtre, dispatch]);

  const go_to = (name, id) => {
    if (name === "drug") {
      navigate(`/drug/${id}`);
    }
  };
  
  const columns = [
    {
      name: "is_lead",
      header: "Status",
      defaultFlex: 1,
      minWidth: 85,
      maxWidth: window.innerWidth === 1280 ? 85 : 90,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("is_lead", data),
      },
    },
    {
      name: "drug_true_name",
      header: "Drug",
      defaultFlex: 1,
      minWidth: 85,
      render: ({ value, data }) => (
        <span className="title-link" onClick={() => go_to("drug", data.id_drug)}>{value}</span>
      ),
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("drug_true_name", data),
      },
    },
    {
      name: "drug_inn",
      header: "INN",
      defaultFlex: 1,
      minWidth: 90,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("drug_inn", data),
      },
    },
    {
      name: "disease_display_name",
      header: "Disease",
      defaultFlex: 1,
      minWidth: 100, 
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("disease_display_name", data),
      },
    },
    {
      name: "status",
      header: "Phase",
      minWidth: 110,
      maxWidth: 120,
      defaultFlex: 1,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("status", data),
      },
    },
    {
      name: "date",
      header: "Phase Start",
      minWidth: 85,
      maxWidth: 122,
      defaultFlex: 1,
    },
    {
      name: "target",
      header: "Biological Target",
      minWidth: 90,
      defaultFlex: 1,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("target", data),
      },
    },
    {
      name: "drug_type",
      header: "Drug Type",
      minWidth: 100,
      defaultFlex: 1,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("drug_type", data),
      },
    },
    {
      name: "therapeutic_franchise_name",
      header: "Therapeutic Franchise Name",
      minWidth: window.innerWidth === 1280 ? 110 : 230,
      defaultFlex: 1,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("therapeutic_franchise_name", data),
      },
    },
  ];

  const filterValue = [
    { name: "is_lead", operator: "inlist", type: "select", value: "" },
    { name: "drug_true_name", operator: "inlist", type: "select", value: "" },
    { name: "drug_inn", operator: "inlist", type: "select", value: "" },
    {
      name: "disease_display_name",
      operator: "inlist",
      type: "select",
      value: "",
    },
    { name: "drug_type", operator: "inlist", type: "select", value: "" },
    { name: "status", operator: "inlist", type: "select", value: "" },
    { name: "target", operator: "inlist", type: "select", value: "" },
    {
      name: "therapeutic_franchise_name",
      operator: "inlist",
      type: "select",
      value: "",
    },
  ];

  return (
    <div className="all-treatment-drug-company-table">
      {load === false ? (
        <DataFilterTable
          dataSource={data}
          columns={columns}
          filterValue={filterValue}
          defaultFilterName={[]}
        />
      ) : (
        <div className="loading-animation" style={{height:"583.2px"}}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
