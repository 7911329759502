import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";

import { DataFilterTable, LoadingAnimation } from "../../components";
import { get_filter_key } from "../../utils";
import { getRSSCongressTab, setRSSTabAnimation } from "../../Action";

export function RSSCongressTable(props) {
  const { congressId, year } = props;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.congress.conferenceRSS);
  const load = useSelector((state) => state.config.tabRSSLoading);

  useEffect(() => {
    dispatch(getRSSCongressTab(congressId, year));
    dispatch(setRSSTabAnimation(true));
  }, [congressId, year, dispatch]);

  const go_to = (name, id) => {
    if (name === "link") {
      const win = window.open(id, "_blank");
      if (win != null) win.focus();
    }
  };

  const columns = [
    {
      name: "published_at",
      header: "Date",
      defaultFlex: 1,
      minWidth: 85,
      maxWidth: 85,
    },
    {
      name: "drug_display_name",
      header: "Drug",
      defaultFlex: 1,
      minWidth: 120,
      maxWidth: 120,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("drug_display_name", data),
      },
    },
    {
      name: "company_display_name",
      header: "Company",
      minWidth: 120,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("company_display_name", data),
      },
    },
    
    {
      name: "topics",
      header: "Topics",
      minWidth: 120,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("topics", data),
      },
    },
    {
      name: "title",
      header: "Title",
      defaultFlex: 1,
      minWidth: 350,
      render: ({ value, data }) => (
        <span className="title-link" onClick={() => go_to("link", data.link)}>
          {value}
        </span>
      ),
    },
  ];

  const filterValue = [
    { name: "title", operator: "contains", type: "string", value: "" },
    {
      name: "company_display_name",
      operator: "inlist",
      type: "select",
      value: "",
    },
    {
      name: "drug_display_name",
      operator: "inlist",
      type: "select",
      value: "",
    },
    { name: "topics", operator: "inlist", type: "select", value: "" },
  ];

  return (
    <div style={{ height: 400, width: "100%", position: "relative" }}>
      {load === false ? (
        <DataFilterTable
          dataSource={data}
          columns={columns}
          filterValue={filterValue}
          defaultFilterName={[]}
          enableFiltering={true}
          enablePagination={true}
        />
      ) : (
        <div className="loading-animation" style={{ height: "100%" }}>
          <LoadingAnimation type={"linear"} />
        </div>
      )}
    </div>
  );
}
