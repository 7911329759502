import React from "react";
import { useSelector } from "react-redux";
import { VerticalBarChart, LoadingAnimation } from "../../components";
import {
  filterDataDrugWithConditionNoClean,
  get_nb_drug_by_phase_by_years,
} from "../../utils";

export function CompetitiveDetailsByPhase(props) {
  const data_active = useSelector(
    (state) => state.competitive.timeChartcompetitiveData
  );
  const data_concat =
    data_active.length !== 0
      ? data_active[0].concat(data_active[1].slice(1))
      : [];

  const uniqueValues = [];
  const filteredArrays = [];

  data_concat.forEach((subArray) => {
    const firstValue = subArray[0];
    if (!uniqueValues.includes(firstValue)) {
      uniqueValues.push(firstValue);
      filteredArrays.push(subArray);
    }
  });

  const size_graph = filteredArrays.length;
  const load = useSelector((state) => state.config.graphCompetitiveDataDrugLoading);
  const depth = useSelector((state) => state.diease.statusDrugDisplay);
  const MOA = useSelector((state) => state.diease.MOADisplay);
  const target = useSelector((state) => state.diease.drugTargetDisplay);
  const type = useSelector((state) => state.diease.drugTypeDisplay);
  const theraFranchise = useSelector(
    (state) => state.diease.theraFranchiseDisplay
  );
  const dataAD = data_concat
    ? filterDataDrugWithConditionNoClean(
        data_concat,
        depth,
        [],
        type,
        MOA,
        theraFranchise,
        target
      )
    : [];

  const tabsYear = ["2016", "2017", "2018", "2019", "2020", "2021", "2022"];
  const transformedArray = [];

  for (const year of tabsYear) {
    const result = get_nb_drug_by_phase_by_years(parseInt(year), dataAD);
    transformedArray.push(result);
  }
  const datakeys = ["phase 1", "phase 2", "phase 3", "nda/bla", "approved"];
  const colors = ["#ad345c", "#e2823d", "#27789E", "#2B6530", "#59AB46"];

  return (
    <div className="all-drug-company-details-by-phase--details-graph">
      {load === false ? (
      <VerticalBarChart
        data={transformedArray}
        keys={datakeys}
        colors={colors}
        size_graph={size_graph}
      />
      ) : (
        <div style={{height: "316px", width: "400px", marginBottom: "84px", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
