import React, {useEffect, useState}  from "react";
import { useSelector , useDispatch } from 'react-redux'
import {CompanyCard, LimitDisplayByPage, LoadingAnimation, LrxMenuInput, LrxChips } from '../../components'
import { filterDataDrug } from "../../utils";
import {getAllCompany,getAllDrug} from '../../Action';

export function CompanyCardPannel(){
  const nameItem = useSelector (state => state.company.nameCompany);
  const alldrug =  useSelector ( state => state.drug.nameDrug);
  const depth = useSelector (state => state.diease.statusCompanyDisplay);
  //const MOA = useSelector ( state => state.diease.MOADisplay);
  const type = useSelector ( state => state.diease.drugTypeDisplay);
  const disease = useSelector ( state => state.diease.diseaseDisplay);
  const theraFranchise = useSelector(state => state.diease.theraFranchiseDisplay);
  const depth_drug = useSelector (state =>state.diease.statusDrugDisplay);
  const targetDisplay = useSelector ( state => state.diease.drugTargetDisplay);
  const list_drug = filterDataDrug(alldrug , depth_drug, disease,type,[], theraFranchise, targetDisplay);
  const dispatch = useDispatch();
  const [page, setPage] = useState(100);

  useEffect(() => {
    if (nameItem && nameItem.length === 0)
      dispatch(getAllCompany())
    if (alldrug  && alldrug.length === 0) {
      dispatch(getAllDrug())
  }
  }, [dispatch, nameItem, alldrug ]);

  const filter_drug_by_company = (id_company, data) => {
    if (disease.length !== 0 || targetDisplay.length!== 0 || theraFranchise.length !== 0 || type.length !== 0)
      return data.filter(e => e.id_company_lead.split('/').flat().map(elem=> parseInt(elem, 10)).includes(id_company) || e.id_company_partner.split('/').flat().map(elem=> parseInt(elem, 10)).includes(id_company))
    return []
  }

  const setAllFilter = (data) => {
    let fcompany = data.filter(e => e.company_market_cap_type === depth.find(element => element === e.company_market_cap_type)) 
    if (disease.length !== 0 || targetDisplay.length!== 0 || theraFranchise.length !== 0 || type.length !== 0) {
      let company_in_drug= (list_drug.map(function (el) { return el['id_company_lead'].split('/');})).filter(function(item, pos, self) { return self.indexOf(item) === pos;});
      let company_partner = (list_drug.map(function (el) { return el['id_partner_company'];})).filter(function(item, pos, self) { return self.indexOf(item) === pos;});
      company_in_drug = company_in_drug.flat().map(elem=> parseInt(elem, 10))
      company_in_drug=company_in_drug.concat(company_partner)
      fcompany = fcompany.filter(e => company_in_drug.includes(e.id_company))
    }
    return fcompany
  }
  
  const [searchValue, setSearchValue] = useState("");

  const companyListFiltered = nameItem.filter((user) =>
    user.company_display_name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const addCard = (nb) => {
    setPage(nb);
  };

   useEffect(() => {
    addCard(100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [searchValue])

   return (
    <div>
      <div className="company-card-pannel-header">
        <LrxMenuInput
          action_input={setSearchValue}
          name={"Search for a company"}
          sx={{width:"550px"}}
        />
        <div className="company-card-pannel-header--chips-container">
          <LrxChips />
        </div>
        <h3 style={{display: nameItem && nameItem.length !== 0 ? null : "None"}}>
          {" "}
          {setAllFilter(companyListFiltered).length === 0
            ? "Company not found !"
            : setAllFilter(companyListFiltered).length + " Companies available"}{" "}
        </h3>
      </div>
      {nameItem && nameItem.length !== 0 ? (
        <div>
          <div className="company-card-pannel">
            {setAllFilter(companyListFiltered).slice(0, page).map((el, i)=> (
              <CompanyCard 
                key={i}
                content={el} 
                allDrug={filter_drug_by_company(el.id_company,  list_drug)}
              />
            ))}
          </div>
          {setAllFilter(companyListFiltered).length <= page ?
            null 
          :
            <LimitDisplayByPage setPage={setPage} page={page} /> 
          }
        </div>
      ) : ( 
        <div className="company-card-pannel">
          <div className="loading-animation" style={{height: "60vh", width: "100%"}}>
            <LoadingAnimation type={"linear"}/>
          </div>
        </div>
      )}
    </div>
  );
}