import React from "react";
import { useSelector } from "react-redux";
import { ControlPannel, CompetitiveGraphPannel } from "../../layout";
import { GlobalLrxAnimation } from "../../components";

export function CompetitivePage() {
  const load = useSelector((state) => state.config.globalMetaDataLoading);
  return (
    <div>
      {!load ? (
        <div className="dashboard">
          <div className="dashboard--pannel">
            <ControlPannel value={"CompetitivePage"} />
          </div>
          <div className="dashboard--side">
            <CompetitiveGraphPannel />
          </div>
        </div>
      ) : (
        <div className="dashboard--loading">
          <div className="dashboard--pannel">
            <ControlPannel value={"DrugPage"} />
          </div>
          <div className="global-loading">
            <GlobalLrxAnimation />
          </div>
        </div>
      )}
    </div>
  );
}
