import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDataDisease, setGraphDrugLoadingAnimation } from "../../Action";
import {
  LoadingAnimation,
  LineChartVolume,
  MUItoogleBtn,
} from "../../components";

export function VolumeDiseaseGraph(props) {
  const { setbtn, action_btn, which_btn } = props;
  const dispatch = useDispatch();
  const load = useSelector((state) => state.config.graphDrugLoading);
  const databyId = useSelector((state) => state.drug.dataDisease);
  const disease = useSelector((state) => state.diease.diseaseDisplay);
  const idDisease = disease.length === 0 ? 0 : disease[0].id_disease;

  useEffect(() => {
    dispatch(getDataDisease(idDisease, action_btn[which_btn]));
    dispatch(setGraphDrugLoadingAnimation(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idDisease, action_btn[which_btn], dispatch]);

  return (
    <div className="sentiment-volume-drug-graph">
      <div style={{ paddingBottom: "5px"}}>
        <MUItoogleBtn
          action_btn={action_btn}
          setbtn={setbtn}
          which_btn={which_btn}
        />
      </div>
      {load === false ? (
        <div>
          <LineChartVolume
            data={databyId}
            dataRSS={[]}
            period={action_btn[which_btn]}
          />
        </div>
      ) : (
        <div className="sentiment-volume-drug-graph--center-animation-loader">
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
