import React from "react";
import { ExplainVizPannel } from "../../layout";

export function HomePage() {
  return (
    <div className="home-page">
        <div>
            <ExplainVizPannel/>
        </div>
    </div>
  );
}
