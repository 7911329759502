import React from "react";
import {
  BarChart,
  Bar,
  LabelList,
  YAxis,
  Tooltip,
  XAxis,
  CartesianGrid,
} from "recharts";

export function VerticalBarChart(props) {
  const { data, keys, colors } = props;

  const RenderCustomizedLabel = (props) => {
    const { x, y, height, width, value /* k*/ } = props;
    return (
      <g>
        <text
          x={x + width / 2}
          y={y + height / 2}
          fill="#212121"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {value}
        </text>
      </g>
    );
  };

  return (
    <BarChart width={400} height={400} overflow={"visible"} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <YAxis hide={true} type="number" domain={[0, "dataMax + 1"]} />
      <XAxis dataKey="name"  interval={0} angle={-35} textAnchor="end" height={80}   />
      <Tooltip />
      {keys.map((entry, index) => {
        return (
          <Bar key={index} dataKey={entry}  stackId="a" fill={colors[index]} isAnimationActive={false}>
            <LabelList
              dataKey={entry}
              content={<RenderCustomizedLabel k={entry} />}
            />
          </Bar>
        );
      })}
    </BarChart>
  );
}
