const initialState = {
  rankinf_dol_tab: [],
  info_ranking_dol: {
    nb_profile: 200000,
    total_nb_profile : 93000,
    type_profile: [
      { name: "STOCK_PICKER", value: 1 },
      { name: "HCP", value: 1 },
      { name: "PATIENT_GROUP", value: 1 },
      { name: "JOURNALIST", value: 1 },
    ],
  },

  dolprofile_info: {},
  ranking_dol_filter: {
    Research: null,
    Active: null,
    Impact: null,
    "Audience Profile": null,
  },
  list_personas: {
    HCP: false,
    "STOCK_PICKER": false,
    "PATIENT_GROUP": false,
    "JOURNALIST": false,
  },
  worldcloud_dolprofile_info: {
    total_drug: [],
    total_disease: [],
    nct: [],
  },
  graphics_years_dolprofile_info: {
    follower: [],
    activity: [],
    engagement: [],
  },
  dolprofile_graph_year: 0,
  dol_ranking_by_drug_tab: [],
  dol_ranking_by_disease_tab: [],
};

export function getDolBQ(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "GET_DOL_RANKING_TAB_DATA":
      nextState = {
        ...state,
        rankinf_dol_tab: action.value,
      };
      return nextState || state;

    case "GET_DOL_RANKING_STATS_DATA":
      nextState = {
        ...state,
        info_ranking_dol: action.value,
      };
      return nextState || state;

    case "GET_DOLPROFILE_INFO_DATA":
      nextState = {
        ...state,
        dolprofile_info: action.value,
      };
      return nextState || state;

    case "GET_DOL_RANKING_BY_DRUG_TAB_DATA":
      nextState = {
        ...state,
        dol_ranking_by_drug_tab: action.value,
      };
      return nextState || state;

    case "GET_DOL_RANKING_BY_DISEASE_TAB_DATA":
      nextState = {
        ...state,
        dol_ranking_by_disease_tab: action.value,
      };
      return nextState || state;

    case "GET_WORLDCLOUD_DOLPROFILE_INFO_DATA":
      nextState = {
        ...state,
        worldcloud_dolprofile_info: action.value,
      };
      return nextState || state;

    case "GET_GRAPHICS_YEARS_DOLPROFILE_INFO_DATA":
      nextState = {
        ...state,
        graphics_years_dolprofile_info: action.value,
      };
      return nextState || state;

    case "SET_RANKING_DOL_USER":
      nextState = {
        ...state,
        ranking_dol_filter: action.value,
      };
      return nextState || state;

    case "SET_PERSONAS_USER":
      nextState = {
        ...state,
        list_personas: action.value,
      };
      return nextState || state;

    case "SET_DOLPROFILE_GRAPH_YEAR":
      nextState = {
        ...state,
        dolprofile_graph_year: action.value,
      };
      return nextState || state;

    case "ERROR_DATA_DOL":
      return state;

    default:
      return state;
  }
}
