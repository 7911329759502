import React, { useEffect} from "react";
import { useDispatch , useSelector} from 'react-redux'

import {LoadingAnimation} from '../../components'
import {TimeLineChart} from '../../components'
import {getTreatmentGraph, setGraphTreatmentAnimation} from '../../Action';
 
export function DiseasePhaseGraph(props) {
  const {drugId, is_active} = props;
  const dispatch = useDispatch();
  const load = useSelector (state =>  state.config.graphTreatmentLoading);
  const dataNCT = useSelector (state =>  state.drug.TreatmentGraph[is_active]);
  const filterData = dataNCT ? dataNCT : []

  filterData.map((l,index) => {
    if (index !== 0) {
      l[3] = new Date(l[3])
      l[4] = new Date(l[4])
    }
    return ([l[3],l[4]])
  })

  useEffect(() => {
        dispatch(getTreatmentGraph(drugId));
        dispatch(setGraphTreatmentAnimation(true))
  }, [drugId ,dispatch]);

    return (
    <div>
      {load === false ?
        <TimeLineChart dataAD={filterData} content={"treatment"}/> 
      :
        <div className="loading-animation" style={{height:"100px"}}>
          <LoadingAnimation type={'circular'}/>
        </div>
      }
    </div>
    )
}

