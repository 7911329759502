import {api} from '../config';

export const handleLogin= (dataUser, openSnackbar, openSnackbarFail) => async (dispatch) => {
    let data1 = new URLSearchParams();
    data1.append('username', dataUser.username);
    data1.append('password', dataUser.password);
    try {
        const {data}=  await api.post('authentication/token-auth', data1)
        await dispatch({
          type : "LOGIN_SUCCESS",
          value : data
        })
        openSnackbar('Successful connection')
    }
  catch (err) {
    dispatch({
      type: "LOGIN_FAIL",
      value: err,
    })
    openSnackbarFail('err : wrong credentials')
  }
  dispatch({
    type: "SET_AUTH_LOADING_ANIMATION",
    value: false,
})
};

export const checkConnection = () => async (dispatch) => {
  try {
      const {data}=  await api.get('authentication/current_user')
      await dispatch({
        type : "CHECK_CONNECTION",
        value : data
      })
  }
catch (err) {
  dispatch({
    type: "LOGOUT_SUCCESS",
    value: err,
  })
}
};

export const setAuthLoadingAnimation = (bool) => async (dispatch) => {
  dispatch({
      type: "SET_AUTH_LOADING_ANIMATION",
      value: bool,
  })
};

export const handleLogout= () => async (dispatch) => {
  dispatch({
    type : "LOGOUT_SUCCESS",
    value : null
  })
};

