import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import { DataFilterTable, LoadingAnimation, ChartIcon } from "../../components";

import { get_filter_key } from "../../utils";
import { getDolRankingData, setdataDOLRankingTabAnimation } from "../../Action";
import numeral from "numeral";


export function DolRankingTable(props) {
  const {pagination} = props 
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const load = useSelector((state) => state.config.dataDOLRankingTabLoading);
  const data = useSelector((state) => state.dol.rankinf_dol_tab);
  const my_profession = useSelector((state) => state.diease.speciality_user);
  const my_profession_group = useSelector((state) => state.diease.profession_group_user);
  const myLocation = useSelector((state) => state.diease.location_user);
  const my_type = useSelector((state) => state.diease.drugTypeDisplay);
  const my_target = useSelector((state) => state.diease.drugTargetDisplay);
  const my_theraFranchise = useSelector((state) => state.diease.theraFranchiseDisplay);
  const disease = useSelector((state) => state.diease.diseaseDisplay);
  const ranking_filter = useSelector((state) => state.dol.ranking_dol_filter);
  const personas = useSelector((state) => state.dol.list_personas);
  
  function list_name_personas(object) {
    const personasSelected = Object.keys(object).filter(persona => object[persona] === true).map(persona => persona.toUpperCase());
    return personasSelected;
  }

  useEffect(() => {
    dispatch(getDolRankingData(my_profession_group, my_profession, myLocation, my_type, my_target, my_theraFranchise, disease, list_name_personas(personas), pagination, Object.values(ranking_filter)));
    dispatch(setdataDOLRankingTabAnimation(true));
  }, [dispatch, my_profession_group, my_profession, myLocation, my_type, my_target, my_theraFranchise, disease, personas , pagination, ranking_filter]);

  const go_to = (name, id) => {
    if (name === "dol_profile") {
      navigate(`/dolprofile/${id}`);
    }
  };

  const columns = [
    {
      name: "user_name",
      header: "Author ",
      defaultFlex: 1,
      minWidth: 130,
      render: ({ value, data }) => (
        <span
          className="title-link"
          onClick={() => go_to("dol_profile", data.id_dolprofile)}
        >
          {value}
        </span>
      ),
    },
    {
      name: "location",
      header: "Location",
      defaultFlex: 1,
      minWidth: 110,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("location", data),
      },
    },
    {
      name: "persona",
      header: "Persona",
      minWidth: 120,
      defaultFlex: 1,
    },
    {
      name: "profession_group_display_name",
      header: "Profession group",
      minWidth: 120,
      defaultFlex: 1,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("profession_group_display_name", data),
      },
    },
    {
      name: "speciality_disease_name",
      header: "Disease",
      minWidth: 230,
      defaultFlex: 2,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("speciality_disease_name", data),
      },
    },
    {
      name: "research_stars",
      header: "Research",
      maxWidth: 100,
      filterEditor: NumberFilter,
      onRender: (cellProps, { data }) => {
        cellProps.style.display = "flex";
        cellProps.style.textAlign = "center";
      },
      render: ({ value }) => {
        return (
          <div className="rating-stars-container">
            {Array.from(Array(5).keys()).map((_, i) => (
              <ChartIcon logo={"StarIcon"} />
            ))}
            <div
              className="rating-stars-container--overlay"
              style={{
                width:
                  value === 0
                    ? "100%"
                    : value === 1
                    ? "80%"
                    : value === 2
                    ? "60%"
                    : value === 3
                    ? "40%"
                    : value === 4
                    ? "20%"
                    : "0%",
              }}
            />
          </div>
        );
      },
    },
    {
      name: "activity_stars",
      header: "Active",
      maxWidth: 100,
      filterEditor: NumberFilter,
      onRender: (cellProps, { data }) => {
        cellProps.style.display = "flex";
        cellProps.style.textAlign = "center";
      },
      render: ({ value }) => {
        return (
          <div className="rating-stars-container">
            {Array.from(Array(5).keys()).map((_, i) => (
              <ChartIcon logo={"StarIcon"} />
            ))}
            <div
              className="rating-stars-container--overlay"
              style={{
                width:
                  value === 0
                    ? "100%"
                    : value === 1
                    ? "80%"
                    : value === 2
                    ? "60%"
                    : value === 3
                    ? "40%"
                    : value === 4
                    ? "20%"
                    : "0%",
              }}
            />
          </div>
        );
      },
    },
    {
      name: "engagement_stars",
      header: "Impact",
      maxWidth: 100,
      filterEditor: NumberFilter,
      onRender: (cellProps, { data }) => {
        cellProps.style.display = "flex";
        cellProps.style.textAlign = "center";
      },
      render: ({ value }) => {
        return (
          <div className="rating-stars-container">
            {Array.from(Array(5).keys()).map((_, i) => (
              <ChartIcon logo={"StarIcon"} />
            ))}
            <div
              className="rating-stars-container--overlay"
              style={{
                width:
                  value === 0
                    ? "100%"
                    : value === 1
                    ? "80%"
                    : value === 2
                    ? "60%"
                    : value === 3
                    ? "40%"
                    : value === 4
                    ? "20%"
                    : "0%",
              }}
            />
          </div>
        );
      },
    },
    {
      name: "audience_stars",
      header: "Audience Profile",
      maxWidth: 100,
      filterEditor: NumberFilter,
      onRender: (cellProps, { data }) => {
        cellProps.style.display = "flex";
        cellProps.style.textAlign = "center";
      },
      render: ({ value }) => {
        return (
          <div className="rating-stars-container">
            {Array.from(Array(5).keys()).map((_, i) => (
              <ChartIcon logo={"StarIcon"} />
            ))}
            <div
              className="rating-stars-container--overlay"
              style={{
                width:
                  value === 0
                    ? "100%"
                    : value === 1
                    ? "80%"
                    : value === 2
                    ? "60%"
                    : value === 3
                    ? "40%"
                    : value === 4
                    ? "20%"
                    : "0%",
              }}
            />
          </div>
        );
      },
    },
    {
      name: "user_followers_count",
      header: "Followers",
      minWidth: 100,
      maxWidth: 110,
      type: "number",
      onRender: (cellProps, { data }) => {
        cellProps.style.display = "flex";
        cellProps.style.textAlign = "center";
      },
      render: ({ value }) => <span> {numeral(value).format("0,0")} </span>
    },
  ]; 

  const filterValue = [
    { name: "user_name", operator: "contains", type: "string", value: "" },
    {
      name: "profession_group_display_name",
      operator: "inlist",
      type: "select",
      value: "",
    },
    {
      name: "speciality_disease_name",
      operator: "eq",
      type: "select",
      value: null,
    },
    {
      name: "disease_second_interest_name",
      operator: "eq",
      type: "select",
      value: null,
    },
  ];
  return (
    <div className="dol-ranking-table">
      {load === false ? (
        <DataFilterTable
          dataSource={data}
          columns={columns}
          filterValue={filterValue}
          enableFiltering={false}
          enablePagination={false}
        />
      ) : (
        <div className="dol-ranking-table--loading-animation">
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
