import React, { useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux'

import { getCongressDrugTreemap, setTMCongressLoadingAnimation} from '../../Action';
import { TreeMapSentiment, LoadingAnimation} from '../../components';

import {setAllFilterTreemap} from '../../utils'

import './style/graph.css'

export function TreeMapCongress() {
  const dispatch = useDispatch();

  const data = useSelector (state =>  state.congress.congressTreemapDrug);
  const name = useSelector (state => state.congress.congressName);
  const date = useSelector (state => state.congress.congressYears);
  const load = useSelector (state => state.config.treempaCongressTDLoading);
  
  const disease = useSelector ( state => state.diease.diseaseDisplay);
  const type = useSelector ( state => state.diease.drugTypeDisplay);
  const MOADisplay= useSelector ( state => state.diease.MOADisplay);
  const target = useSelector ( state => state.diease.drugTargetDisplay);
  const depth = useSelector (state => state.diease.statusDrugDisplay);
  const theraFranchise = useSelector(state => state.diease.theraFranchiseDisplay);

  useEffect(() => {
      if (name !== null){
        dispatch(getCongressDrugTreemap(name.id_conference, date))
        dispatch(setTMCongressLoadingAnimation(true))
    }
  }, [name,date, dispatch]);

  return (
    <div className="treemap_congress_fix_position">
    {load === false ? (
      <TreeMapSentiment 
        data={setAllFilterTreemap(data, disease , type, MOADisplay, depth, theraFranchise, target)} 
        filter={'drug'}
      /> 
    ) : (
      <div className="loading-animation" style={{ height: "350px"}}>
        <LoadingAnimation type={'circular'}/>
      </div>
    )}
    </div> 
  );
}

