import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import { ModalNeedSocialAccess, ChartIcon, LrxBtn } from "../../components";
import { is_departement_is_social } from "../../utils";
import dolprofile from "../../../Asset/dolprofile.png";
import { useNavigate } from "react-router-dom";

import { InfoPannel } from "../../layout";
import {
  DolWordCloud,
  DolGraphics,
  MUIChevron,
  DolLrxChips,
} from "../../components";
import {} from "../../utils";
import {} from "../../Action";

export function DolProfilePannel() {
  const { id } = useParams();
  const user = useSelector((state) => state.auth.auth_name);
  const [is_open, set_open] = useState(false);
  const handleChange = (open) => set_open(open);
  const navigate = useNavigate();

  useEffect(() => {}, []);

  const go_to_rank_page = () => {
      navigate(`/dol/ranking`);
  };

  return (
    <div>
      {is_departement_is_social(user) ? (
        <div className="dol-profile-pannel">
          <div className="congress_pannel_header">
            <div onClick={go_to_rank_page}>
              {" "}
              {""} <MUIChevron />{" "}
            </div>
            <div className="congress_pannel_header-center-title">
              ranking
              <DolLrxChips />
            </div>
          </div>
          <InfoPannel drugId={id} value={3} />
          <div className="dol-profile-pannel__details-container">
            <DolGraphics dolProfileId={id} />
            <DolWordCloud dolProfileId={id} />
          </div>
        </div>
      ) : (
        <div className="locked-screen-trending-pannel">
          <ModalNeedSocialAccess more_info={is_open} onChange={handleChange}/>
          <img className="locked-screen-trending-pannel__img" src={dolprofile} alt="background-dolprofile"/>
          <div className="locked-screen-trending-pannel--icon-button">
            <div className="locked-screen-trending-pannel--icon-button__icon">
              <ChartIcon logo={"LockedIcon"} />
            </div>
            <div className="need-premium-modal__validate">
              <LrxBtn
                action_btn={() => handleChange(true)}
                logo={"ActionSucess"}
                text_btn={"More Info"}
                my_className={"need-premium-modal__validate--btn"}
              /> 
            </div>            
          </div>
        </div>
      )}
    </div>
  );
}
