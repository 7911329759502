import React, { useState } from "react";
import { MuiModal, LrxBtnAction, ChartIcon, LrxBtn } from "../../../components";

export function ModalNeedPremiumAccount(props) {
  const { logo, text_btn } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const go_to = (id) => {
    const win = window.open(id, "_blank");
    if (win != null) win.focus();
  };

  return (
    <div>
      <LrxBtnAction
        action_btn={() => handleOpen()}
        logo={logo}
        text_btn={text_btn}
      />
      <MuiModal open={open} onClose={handleClose}>
        <div className="need-premium-modal">
          <div className="need-premium-modal__header">
            <h2 className="need-premium-modal__header--main-title">
              {" "}
              You have reached your limit of download.
              <br />
              The paywall is working!
            </h2>
            <h3 className="need-premium-modal__header--sub-title">
              Try to get around this paywall by upgrade your account
            </h3>
          </div>
          <div className="need-premium-modal__explain">
            <h5 className="need-premium-modal__explain__text">
              Unlock the full potential of our platform with{" "}
              <span className="lrx-color-text">Premium access</span>{" "}
            </h5>
            <div className="need-premium-modal__explain__box">
              <div className="">
                <span className="need-premium-modal__explain__box--logo">
                  <ChartIcon logo={"saveIcon"} />
                </span>
                <h5>Unlimited downloads</h5>
              </div>
              <div className="">
                <span className="need-premium-modal__explain__box--logo">
                  <ChartIcon logo={"calendarIcon"} />
                </span>
                <h5>Daily update </h5>
              </div>
              <div className="">
                <span className="need-premium-modal__explain__box--logo">
                  <ChartIcon logo={"Favicon"} />
                  <h5>bookmarking</h5>
                </span>
              </div>
            </div>
          </div>
          <div className="need-premium-modal__validate">
            <LrxBtn
              action_btn={() => go_to("https://contact.lend-rxtech.com/")}
              logo={"ActionSucess"}
              text_btn={"Upgrade Account"}
              my_className={"need-premium-modal__validate--btn"}
            />
          </div>
        </div>
      </MuiModal>
    </div>
  );
}
