import React from "react";
import {useSelector } from 'react-redux'
import {Link} from '@mui/material'
import { format } from 'date-fns'

export function OtherNameBox(){
    const info  = useSelector (state => state.drug.drugInfo);

    function get_other_name(info) {
        let  name = [info.drug_brand_name, info.drug_inn, info.drug_display_name].concat(info.name)
        const f = name.filter((item, index )=>  name.indexOf(item) ===index) 
        return (f.filter(name => name !== "-1" && name !== '-' ) )
    }

    return (
    <div className="info-panel-box_name">
        <div className="info-pannel-content"> <span className="info-pannel_box_name_subtitle">Other names : {' '}</span>{get_other_name(info.drugInfo).map((el, i)=> 
          <span  key={i}>
          {el + ' / '}
        </span>)}
        </div>

        <div className="info-pannel-content"> <span className="info-pannel_box_name_subtitle">Company : {' '}</span>
        <span className="title-link"> 
        {info.drugCompany.lead.map((company, i) =>  
          (<Link key={i} color="inherit" href={`/company/${company.id_company}`}>
            { company.company_display_name + '\xa0\xa0\xa0\xa0\xa0\xa0\xa0'} </Link>))
          }
        </span>
        </div>

        <div className="info-pannel-content"> <span className="info-pannel_box_name_subtitle">Company partner : {' '}</span>
        <span> 
        {info.drugCompany.partner.length !== 0 ? info.drugCompany.partner.map((company, i) =>  
          (<Link key={i} color="inherit" href={`/company/${company.id_company}`}>
            { company.company_display_name + '\xa0\xa0\xa0\xa0\xa0\xa0\xa0'} </Link>)) : '-'
          }
        </span>
        </div>

        <div className="info-pannel-content"> <span className="info-pannel_box_name_subtitle">Current phase : {' '}</span>

        { (info.drugInfo.date && info.drugInfo.date.toLowerCase() !== '-')? 
        <span>
        {info.drugInfo.drug_current_phase } (since {format(new Date(info.drugInfo.date), 'yyyy-MM-dd')})</span> : 
        <span>preclinical</span>
        }
        </div>
    </div>
      );
}