import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useLocation } from "react-router-dom";

import { IconButton, Avatar, Menu, MenuItem } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import logo from "../../../../Asset/Logo_black_white.png";

import { handleLogout, checkConnection } from "../../../Action";

export function Header() {
  const [connected, setCo] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const name = useSelector((state) =>
    state.auth.auth_name ? state.auth.auth_name : "no_name"
  );
  const location = useLocation();

  const [anchorElUser, setAnchorElUser] = useState(null);

  const settings = [name.username, name.group, "Contact Us", "Logout"];

  const logout = () => {
    dispatch(handleLogout());
    setCo(false);
    navigate("/");
  };

  const go_to = (id) => {
    const win = window.open(id, "_blank");
    if (win != null) win.focus();
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  useEffect(() => {
    dispatch(checkConnection());
    const t = localStorage.getItem("token");
    if (t) {
      setCo(true);
    } else {
      setCo(false);
      navigate("/");
    }
  }, [navigate, dispatch, connected]);

  return (
    <div className="header">
      <NavLink
        className="header__title"
        to={"/home"}
        style={{ textDecoration: "none" }}
      >
        {" "}
        <span className="header__title--title"> DOLTRACKER by </span>
        <img src={logo} alt="Logo" className="header__title--logo" />{" "}
      </NavLink>{" "}
      <div className="header__navbar">
        <NavLink
          exact
          to={"/home"}
          className={
            location.pathname === "/home" ||
            location.pathname.startsWith("/home")
              ? "header__navbar--link active"
              : "header__navbar--link"
          }
        >
          HOME
        </NavLink>
        <NavLink
          exact
          to={"/trending"}
          className={
            location.pathname === "/trending" ||
            location.pathname.startsWith("/trending")
              ? "header__navbar--link active"
              : "header__navbar--link"
          }
        >
          TRENDING
        </NavLink>
        <NavLink
          exact
          to={"/drug/-1"}
          className={
            location.pathname === "/drug/-1" ||
            location.pathname.startsWith("/drug")
              ? "header__navbar--link active"
              : "header__navbar--link"
          }
        >
          THERAPEUTICS
        </NavLink>
        <NavLink
          exact
          to={"/company/-1"}
          className={
            location.pathname === "/company/-1" ||
            location.pathname.startsWith("/company/")
              ? "header__navbar--link active"
              : "header__navbar--link"
          }
        >
          COMPANY
        </NavLink>
        <NavLink
          exact
          to={"/congress"}
          className={
            location.pathname === "/congress"
              ? "header__navbar--link active"
              : "header__navbar--link"
          }
        >
          CONGRESS
        </NavLink>
        <NavLink
          exact
          to={"/competitive"}
          className={
            location.pathname === "/competitive"
              ? "header__navbar--link active"
              : "header__navbar--link"
          }
        >
          COMPETITIVE INTELLIGENCE
        </NavLink>
        <NavLink
          exact
          to={"/dol/ranking"}
          className={
            location.pathname === "/dol/ranking"
              ? "header__navbar--link active"
              : "header__navbar--link"
          }
        >
          D O L
        </NavLink>
      </div>
      <div>
        <IconButton
          onClick={handleOpenUserMenu}
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircleIcon />
        </IconButton>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {settings.map((setting, index) => (
            <MenuItem
              key={index}
              onClick={handleCloseUserMenu}
              disabled={index === 0 || index === 1}
            >
              {index === 0 ? (
                <span>
                  {" "}
                  <Avatar /> {setting}{" "}
                </span>
              ) : index === 1 ? (
                <span>Account : {setting} </span>
              ) : index === 2 ? (
                <span onClick={() => go_to("https://contact.lend-rxtech.com/")}> {setting} </span>
              ) : <span onClick={() => logout()}> {setting }</span>}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </div>
  );
}
