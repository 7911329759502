import React from 'react';
import { useDispatch} from 'react-redux'
import { setMyDrug} from '../../../../Action';

import { Treemap, Tooltip, ResponsiveContainer} from "recharts";
import { useNavigate  } from "react-router-dom";

const CustomizedContent = (props) => {
  const { depth, x, y, width, height, index, name, color, root} = props;
  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        style={{
          fill: color,
          fillOpacity: 0.9,
          stroke: "#fff",
          strokeWidth: 10 / (depth + 1e-10),
          strokeOpacity: 1 / (depth + 1e-10)
        }} 
      />
      {depth !== 1 ? (
        <text
          x={x + width / 2}
          y={y + height / 2 + 7}
          textAnchor="middle"
          fill="#fff"
          fontSize={10}
          letter-spacing= {1.2}
        >
          {name}
        </text>
      ) : null}
       {index === 0 ? <text  x={x + 35} y={y + 18} fill="#fff"  fillOpacity={0.9}  textAnchor= 'middle' > {root.name}</text>: "" }
    </g>
  );
};

export function TreeMapSentiment(props) {
  const {data, filter} = props
  const navigate = useNavigate ();
  const dispatch = useDispatch();

  const CustomTooltip = (props) => {
    const {active, payload} = props;
    if (active && payload && payload.length) {
      
      return (
          <p className="label"  onClick={go_to} >{`${payload[0].payload.name} : ${payload[0].value}`}</p>
      );
    }  
    return null;
  };
  
  const go_to = (e) => {

    if (filter === 'navigation') {
      if(e.id_drug) {
        navigate(`/drug/${e.id_drug}`);
      }
      else if(e.id_company) {
        navigate(`/company/${e.id_company}`);
      }
    }
    else if (filter === 'drug' ) {
      dispatch(setMyDrug({id : e.id_drug, name : e.name}));
    }

  }

  return ( 
  <ResponsiveContainer width='100%' height={350} >
     <Treemap
      data={data}
      dataKey="size"
      stroke="white"
      fill="#707070"
      content={<CustomizedContent/>}
      onClick={go_to}
      margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
      >
      <Tooltip content={CustomTooltip} />
      </Treemap>
  </ResponsiveContainer>
  );
}

