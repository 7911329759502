import * as d3 from "d3";



function calculatePoint(i, intervalSize, colorRangeInfo) {
  var { colorStart, colorEnd, useEndAsStart } = colorRangeInfo;
  return useEndAsStart
    ? colorEnd - i * intervalSize
    : colorStart + i * intervalSize;
}

export function interpolateColors(dataLength) {
  //const colorScale = d3.scaleOrdinal( d3.schemePaired );
  const colorScale = d3.interpolateSpectral;
  const colorRangeInfo = {
    colorStart: 0.1,
    colorEnd: 1,
    useEndAsStart: false,
  };

  var { colorStart, colorEnd } = colorRangeInfo;
  var colorRange = colorEnd - colorStart;
  var intervalSize = colorRange / dataLength;
  var i, colorPoint;
  var colorArray = [];

  for (i = 0; i < dataLength; i++) {
    colorPoint = calculatePoint(i, intervalSize, colorRangeInfo);
    colorArray.push(colorScale(colorPoint));
  }
  return colorArray;
}


export function interpolateColors10(dataLength) {
  const colorScale = d3.interpolateSpectral;
  const colorRangeInfo = {
    colorStart: 0.1,
    colorEnd: 1,
    useEndAsStart: false,
  };

  var { colorStart, colorEnd } = colorRangeInfo;
  var colorRange = colorEnd - colorStart;
  var intervalSizeSuppten = colorRange / 10;
  var i, colorPoint;
  var colorArray = [];

    for (i = 0; i < 10; i++) {
      colorPoint = calculatePoint(i, intervalSizeSuppten, colorRangeInfo);
      colorArray.push(colorScale(colorPoint));
    }
    for (i = 10; i < dataLength; i++) {
      colorArray.push("#cfcfcf");
    }
  return colorArray;
}

