import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { DataTableInteraction, LoadingAnimation } from "../../components";

import { filterDataDrug, get_filter_key } from "../../utils";
import { getAllDrugCompetitive, setcompetitiveDataLoadingAnimation } from "../../Action";

export function CompetitiveVolumeTable(props) {
  const { my_className } = props;
  const dispatch = useDispatch();
  const depth = useSelector((state) => state.diease.statusDrugDisplay);
  //const MOA = useSelector ( state => state.diease.MOADisplay);
  const type = useSelector((state) => state.diease.drugTypeDisplay);
  const disease = useSelector((state) => state.diease.diseaseDisplay);
  const theraFranchise = useSelector(
    (state) => state.diease.theraFranchiseDisplay
  );
  const targetDisplay = useSelector((state) => state.diease.drugTargetDisplay);
  const alldrug = useSelector((state) => state.drug.nameDrug);
  const data = filterDataDrug(
    alldrug,
    depth,
    disease,
    type,
    [],
    theraFranchise,
    targetDisplay
  );
  const load = useSelector((state) => state.config.tabCompetitiveDataLoading);

  useEffect(() => {
    if (alldrug && alldrug.length === 0) {
      dispatch(getAllDrugCompetitive());
      dispatch(setcompetitiveDataLoadingAnimation(true));
    }
  }, [dispatch, alldrug]);

  const columns = [
    {
      name: "drug_true_name",
      header: "Drug",
      defaultFlex: 1,
      minWidth: 100,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("drug_true_name", data),
      },
    },
  ];

  const filterValue = [
    {
      name: "drug_true_name",
      operator: "inlist",
      type: "select",
      value: "",
    },
  ];

  return (
    <div className={my_className}>
      {load === false ? (
        <DataTableInteraction
          dataSource={data}
          columns={columns}
          filterValue={filterValue}
          defaultFilterName={[]}
        />
      ) : (
        <div className="loading-animation" style={{height: "100%"}}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
