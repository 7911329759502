import React from "react"

import GaugeChart from 'react-gauge-chart'

export function GaugeChartVolume(props)  {
    const {dateSelected} = props

    function get_gauge (nb){
      return nb / 100
    }

    return (
        <GaugeChart
        id={`uph-chart`}
        nrOfLevels={4}
        percent={get_gauge(dateSelected)}
        hideText
        colors={['#F63538', '#3F4C53', '#30CC5A']}
      />
    );
  }
