import React from "react";
import { useSelector } from "react-redux";
import {
  filterDataDrugWithConditionNoClean,
  get_nb_drug_by_phase_by_years,
} from "../../../utils";

export function DetailsCompetitivePhaseBox() {
  const depth = useSelector((state) => state.diease.statusDrugDisplay);
  const MOA = useSelector((state) => state.diease.MOADisplay);
  const target = useSelector((state) => state.diease.drugTargetDisplay);
  const type = useSelector((state) => state.diease.drugTypeDisplay);
  const theraFranchise = useSelector(
    (state) => state.diease.theraFranchiseDisplay
  );
  const data_active = useSelector(
    (state) => state.competitive.timeChartcompetitiveData
  );
  const data_concat =
    data_active.length !== 0
      ? data_active[0].concat(data_active[1].slice(1))
      : [];

  const dataAD = data_concat
    ? filterDataDrugWithConditionNoClean(
        data_concat,
        depth,
        [],
        type,
        MOA,
        theraFranchise,
        target
      )
    : [];

  const result = get_nb_drug_by_phase_by_years(2024, dataAD);
  const keyOrder =  [ "approved","nda/bla",  "phase 3", "phase 2", "phase 1"];

  return (
    <div className="info-pannel__company-pannel__details">
      {keyOrder.map((keyName, i) => (
        keyName !== "name" && result.hasOwnProperty(keyName) && (
          <div className="info-pannel__competitive-pannel__details" key={i}>
            <div className="info-pannel__competitive-pannel__details--rank">
              {result[keyName]}
            </div>
            <div>{keyName}</div>
          </div>
        )
      ))}
    </div>
  );
  
  
}
