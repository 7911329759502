import React from "react";
import { useSelector } from "react-redux";
import { VerticalBarChart, LoadingAnimation } from "../../components";

import {
  filterDataDrugWithConditionNoClean,
  interpolateColors,
  get_nb_drug_by_target_by_years,
} from "../../utils";

import "./style/competitive_details.css";

export function CompetitiveDetailsByTarget(props) {
  const data_active = useSelector(
    (state) => state.competitive.timeChartcompetitiveData
  );
  const data_concat =
    data_active.length !== 0
      ? data_active[0].concat(data_active[1].slice(1))
      : [];

  const uniqueValues = [];
  const filteredArrays = [];

  data_concat.forEach((subArray) => {
    const firstValue = subArray[0];
    if (!uniqueValues.includes(firstValue)) {
      uniqueValues.push(firstValue);
      filteredArrays.push(subArray);
    }
  });

  const load = useSelector((state) => state.config.graphCompetitiveDataDrugLoading);
  const depth = useSelector((state) => state.diease.statusDrugDisplay);
  const MOA = useSelector((state) => state.diease.MOADisplay);
  const target = useSelector((state) => state.diease.drugTargetDisplay);
  const type = useSelector((state) => state.diease.drugTypeDisplay);
  const theraFranchise = useSelector(
    (state) => state.diease.theraFranchiseDisplay
  );

  function getUniqueKeys(objects) {
    const uniqueKeys = objects.reduce((keys, obj) => {
      const objKeys = Object.keys(obj);
      objKeys.forEach((key) => {
        if (key !== "name" && !keys.includes(key)) {
          keys.push(key);
        }
      });
      return keys;
    }, []);

    return uniqueKeys;
  }

  const dataAD = data_concat
    ? filterDataDrugWithConditionNoClean(
        data_concat,
        depth,
        [],
        type,
        MOA,
        theraFranchise,
        target
      )
    : [];

  const set_color = (size) => {
    return interpolateColors(size);
  };

  const tabsYear = ["2016", "2017", "2018", "2019", "2020", "2021", "2022"];
  const transformedArray = [];
  for (const year of tabsYear) {
    const result = get_nb_drug_by_target_by_years(parseInt(year), dataAD);
    const entries = Object.entries(result);
    entries.sort((a, b) => b[1] - a[1]);
    const top15Elements = entries.slice(0, 10);
    const formattedData = top15Elements.reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
    transformedArray.push(formattedData);
  }

  const datakeys = getUniqueKeys(transformedArray);

  return (
    <div className="all-drug-company-details-by-phase--details-graph">
      {load === false ? (
        <VerticalBarChart
          data={transformedArray}
          keys={datakeys}
          colors={set_color(datakeys.length)}
        />
      ) : (
        <div style={{height: "316px", width: "400px", marginBottom: "84px", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
