import React from "react";
import { MuiModal,ChartIcon, LrxBtn } from "../../../components";
import trendingIcon from "../../../../Asset/trending-topic.png";
import sentimentIcon from "../../../../Asset/sentiment-analysis.png";
import socialIcon from "../../../../Asset/pie-chart.png";

export function ModalNeedSocialAccess(props) {
  const {more_info, onChange} = props;

  // const go_to = (id) => {
  //   const win = window.open(id, "_blank");
  //   if (win != null) win.focus();
  // }; 

  return (
    <div>
      <MuiModal open={more_info}  onClose={() => onChange(false)}>
        <div className="need-premium-modal">
          <div className="need-premium-modal--cross-container">
            <div className="need-premium-modal--cross-container__cross-icon" onClick={() => onChange(false)}>
              <ChartIcon logo={"CrossIcon"}/>
            </div>            
          </div>
          <div className="need-premium-modal__header">
            <h2 className="need-premium-modal__header--main-title">
              {" "}
              Access to the "Social" tab on our data visualization platform requires special authorization. 
              <br />
              This feature is designed to provide a deeper dive into social analytics, offering insights into audience engagement, trends, and sentiment analysis.
            </h2>
            <h3 className="need-premium-modal__header--sub-title">
            To gain access to this valuable tool, please contact us
            </h3>
          </div>
          <div className="need-premium-modal__explain">
            <h5 className="need-premium-modal__explain__text">
              Unlock the full potential of our platform with{" "}
              <span className="lrx-color-text">Social access</span>{" "}
            </h5>
            <div className="need-premium-modal__explain__box">
              <div className="need-premium-modal__explain__box__container">
                <span className="need-premium-modal__explain__box--logo">
                  <img src={socialIcon} alt="icon-social-analytics"/>
                </span>
                <h5>Social Analytics</h5>
              </div>
              <div className="need-premium-modal__explain__box__container">
                <span className="need-premium-modal__explain__box--logo">
                <img src={sentimentIcon} alt="icon-sentiment-analysis"/>
                </span>
                <h5>Sentiment Analysis</h5>
              </div>
              <div className="need-premium-modal__explain__box__container">
                <span className="need-premium-modal__explain__box--logo">
                  <img src={trendingIcon} alt="icon-trending"/>
                </span>                  
                <h5>Trends</h5>
              </div>
            </div>
          </div>
          <div className="need-premium-modal__validate">
            <LrxBtn
              action_btn={() => (window.location.href = "mailto:hello@lend-rxtech.com")}
              logo={"ActionSucess"}
              text_btn={"Contact Us"}
              my_className={"need-premium-modal__validate--btn"}
            />
          </div>
        </div>
      </MuiModal>
    </div>
  );
}
