import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMyTheraFranchise, removeMyTheraFranchise } from "../../../Action";
import { LrxMenuInput } from "../../../components";
import { getParamId } from "../../../utils";

import { Tooltip } from "@mui/material";
import { IconButton } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

export function BtnTheraFranchiseDisplay() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const my_option = useSelector((state) => state.diease.nameTheraFranchise);
  const my_theraFranchise = useSelector(
    (state) => state.diease.theraFranchiseDisplay
  );

  const updateURLParamsRef = useRef();
  const [searchValue, setSearchValue] = useState("");
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  updateURLParamsRef.current = (paramName, paramValue) => {
    const params = new URLSearchParams(window.location.search);
    params.set(paramName, paramValue);
    const nouvelleURL = `${window.location.pathname}?${params}`;
    navigate(nouvelleURL);
  };

  const paramtherapeuticFranchise = getParamId(
    "therapeutics_franchise",
    params
  );

  useEffect(() => {
    const id_thf = paramtherapeuticFranchise.split(";");
    for (let i = 0; i < id_thf.length; ++i) {
      const thId = parseInt(id_thf[i]);
      if (thId) {
        const c = my_option.filter(
          (item) => item.id_therapeutic_franchise === thId
        );
        if (c) {
          dispatch(setMyTheraFranchise(c[0]));
        }
      }
    }
  }, [dispatch, my_option, paramtherapeuticFranchise]);

  useEffect(() => {
    updateURL();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, my_theraFranchise]);

  const updateURL = (event) => {
    let disease_sent = my_theraFranchise
      ? my_theraFranchise
          .map(function (el) {
            return el["id_therapeutic_franchise"];
          })
          .join(";")
      : [];
    updateURLParamsRef.current("therapeutics_franchise", disease_sent);
  };

  const handleChange = (event) => {
    const itemName = event.target.name;
    const selectedItem = my_option.find(
      (item) => item.therapeutic_franchise_name === itemName
    );

    if (my_theraFranchise.includes(selectedItem)) {
      dispatch(removeMyTheraFranchise(selectedItem.id_therapeutic_franchise));
    } else {
      dispatch(setMyTheraFranchise(selectedItem));
    }
  };

  const clearFilter = () => {
    my_theraFranchise.forEach((item) => {
      dispatch(removeMyTheraFranchise(item.id_therapeutic_franchise));
    });
  };

  const theraFranchiseListFiltered = my_option.filter((user) =>
    user.therapeutic_franchise_name.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <div className="control-pannel-search-entity">
      <LrxMenuInput
        action_input={setSearchValue}
        name={"Search for a therapeutic franchise"}
      />
      <h3 className="control-pannel-search-entity--nb-select-filter">
        {" "}
        {theraFranchiseListFiltered.length === 0
          ? "Therapeutic franchise not found !"
          : theraFranchiseListFiltered.length +
            " therapeutics franchises available"}{" "}
      </h3>
      <h3 className="control-pannel-search-entity--nb-select-filter">
        {" "}
        {my_theraFranchise.length === 0
          ? null
          : my_theraFranchise.length +
            " therapeutic(s) franchise(s) selected"}{" "}
      </h3>
      {my_theraFranchise.length === 0 ? null : (
        <Tooltip title="Delete">
          <IconButton onClick={clearFilter}>
            <RestartAltIcon />
          </IconButton>
        </Tooltip>
      )}
      <div className="control-pannel-search-entity__list-object-filter">
        <div class="control-pannel-search-entity__list-object-filter--form">
          <form>
            {my_theraFranchise.map((item, index) => (
              <div key={index}>
                <label>
                  <input
                    type="checkbox"
                    onChange={handleChange}
                    name={item.therapeutic_franchise_name}
                    checked={my_theraFranchise.includes(item)}
                  ></input>
                  <span>{item.therapeutic_franchise_name}</span>
                </label>
              </div>
            ))}
          </form>
        </div>
        <div class="control-pannel-search-entity__list-object-filter--form">
          <form>
            {theraFranchiseListFiltered.map((item, index) =>
              my_theraFranchise.includes(
                item
              ) ? null : (
                <div key={index}>
                  <label>
                    <input
                      type="checkbox"
                      onChange={handleChange}
                      name={item.therapeutic_franchise_name}
                      checked={my_theraFranchise.includes(item)}
                    ></input>
                    <span>{item.therapeutic_franchise_name}</span>
                  </label>
                </div>
              )
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
