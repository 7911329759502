
export function is_group_is_premium(user) {
    if ( user && user.group === 'Premium') {
        return true
    }
    return false
}
    

export function is_departement_is_social(user) {
    if ( user && user.departement ) {
        if (user.departement.includes("viatrisBD"))
            return false
    }
    return true
}