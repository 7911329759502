const initialState = {
  treemap: [],
  data: [],
  companyInfo: {},
  databyId: [],
  displayed: 100,
  nameCompany: [],
  allDrug: [],
  allDrugTab: [],
  RSSTab: [],
  catalystTab: [],
  nctCompany: [],
  nctCompanyTab: [],
  detailsbyPhase : []
};

export function getCompanyBQ(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "GET_ALL_TT_DATA":
      nextState = {
        ...state,
        data: action.value,
      };
      return nextState || state;

    case "GET_ALL_COMPANY":
      nextState = {
        ...state,
        nameCompany: action.value,
      };
      return nextState || state;

    case "GET_DATA_BY_COMPANY":
      nextState = {
        ...state,
        databyId: action.value,
      };
      return nextState || state;

    case "GET_INFO_BY_COMPANY":
      nextState = {
        ...state,
        companyInfo: action.value,
      };
      return nextState || state;

    case "GET_DATA_TREEMAP_TT":
      nextState = {
        ...state,
        treemap: action.value,
      };
      return nextState || state;

    case "GET_ALL_COMPANY_DRUG":
      nextState = {
        ...state,
        allDrug: action.value,
      };
      return nextState || state;

    case "GET_ALL_COMPANY_DRUG_TABLE":
      nextState = {
        ...state,
        allDrugTab: action.value,
      };
      return nextState || state;

    case "SET_NB_COMPANY_DISPLAY":
      nextState = {
        ...state,
        displayed: action.value,
      };
      return nextState || state;
    case "GET_RSS_COMPANY_TAB":
      nextState = {
        ...state,
        RSSTab: action.value,
      };
      return nextState || state;
    case "GET_CATALYST_COMPANY_TAB":
      nextState = {
        ...state,
        catalystTab: action.value,
      };
      return nextState || state;

    case "GET_NCT_COMPANY_GRAPH":
      nextState = {
        ...state,
        nctCompany: action.value,
      };
      return nextState || state;

    case "GET_NCT_COMPANY_TAB":
      nextState = {
        ...state,
        nctCompanyTab: action.value,
      };
      return nextState || state;

    case "GET_DETAILS_COMPANY_GRAPH":
      nextState = {
        ...state,
        detailsbyPhase: action.value,
      };
      return nextState || state;

    case "ERROR_DATA_COMPANY":
      return state;

    default:
      return state;
  }
}
