import React from "react";
import {useSelector } from 'react-redux'

export function SpecDrugBox(){
    const info  = useSelector (state => state.drug.drugInfo);
    return (
      <div>
        <div className="info-pannel-content"> 
          <span className="info-pannel_box_name_subtitle"> Therapeutic franchise : </span>
          <span> {info.drugInfo.therapeutic_franchise_name === 'unknown' ? '-' : info.drugInfo.therapeutic_franchise_name}</span>
        </div>
        {/*<div className="info-pannel-content"> 
          <span className="info-pannel_box_name_subtitle">   disease : </span>
          <span> {info.drugInfo.disease_display_name}</span>
    </div>*/}
        <div className="info-pannel-content"> 
          <span className="info-pannel_box_name_subtitle"> Drug type :</span>
          <span> {info.drugInfo.drug_type === 'unknown' ? '-' : info.drugInfo.drug_type}</span>
        </div>
        <div className="info-pannel-content"> 
          <span className="info-pannel_box_name_subtitle"> Target : </span>
          <span> {info.drugInfo.target === 'unknown' ? '-' : info.drugInfo.target}</span>
        </div>
      </div>)
}