import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Tooltip } from "@mui/material";
import { IconButton } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import {
  BtnMarketCapDisplay,
  BtnPhaseDisplay,
  DatePickerMonth,
  BtnDiseaseDisplay,
  BtnDrugTypeDisplay,
  /*BtnDrugMOADisplay,*/
  BtnDrugTargetDisplay,
  BtnDOLPersonasDisplay,
  /*BtnProfessionDisplay,*/
  BtnProfessionGroupDisplay,
  SelectAllDrug,
  SelectAllCompany,
  SelectCongressDisplay,
  BtnTheraFranchiseDisplay,
  BtnLocationDisplay,
  DatePickerCongress,
  MuiCollapse,
  MuiRating,
} from "../../components";

import {
  filter_by_theraFranchise,
  filterDataDrug,
  //filter_profession_by_profession_group,
} from "../../utils";

import {
  resetDisplay,
  getAllDisease,
  getAllTheraFranchise,
  getAllTarget,
  getAllDrugType,
  getAllDrug,
  get_all_speciality,
  get_all_profession_group,
  get_all_location,
  setGlobalLoadingAnimation,
  set_filter_ranking_dol_user,
} from "../../Action";
import logo from "../../../Asset/logoLend-RX.png";

const CONTENT_SELECTED = {
  DashboardDrug: 0,
  DashboardCompany: 1,
  DrugPage: 2,
  CompanyPage: 3,
  CongressPage: 4,
  CompetitivePage: 5,
  HomePage: 6,
  DashboardDOL: 7,
};

export function ControlPannel(props) {
  const { value } = props;
  const listRef = useRef(null);
  const contentWrapperRef = useRef(null);
  const dispatch = useDispatch();
  const [is_open_filter, setOpenFilter] = useState({
    Drug: false,
    "Current Phase": false,
    "Therapeutics Franchises": false,
    "Biological Target": false,
    Diseases: false,
    MOA: false,
    "Drug Type": false,
    Personas: false,
    Specialty: false,
    "Profession Group": false,
    Location: false,
  });
  const [openPeriod, setOpenPeriod] = useState(false);

  const handleChange = (open, index) => {
    const resetObject = Object.keys(is_open_filter).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {});
    if (open === "Period") {
      setOpenPeriod(!openPeriod);
      setOpenFilter({
        ...resetObject,
      });
    } else {
      setOpenFilter({
        ...resetObject,
        [open]: !is_open_filter[open],
      });
      setOpenPeriod(false);
    }
  };
  const listColumnsStars = ["Research", "Active", "Impact", "Audience Profile"];

  const my_option = useSelector((state) => state.dol.ranking_dol_filter);
  const depth = useSelector((state) => state.diease.statusDrugDisplay);
  const alldrug = useSelector((state) => state.drug.nameDrug);
  const my_theraFranchise = useSelector(
    (state) => state.diease.theraFranchiseDisplay
  );
  const nb_theraFranchise = useSelector(
    (state) => state.diease.nameTheraFranchise
  );
  //const personas = useSelector((state) => state.dol.list_personas);
  const my_disease = useSelector((state) => state.diease.diseaseDisplay);
  const disease = useSelector((state) => state.diease.nameDisease);
  const nb_disease = filter_by_theraFranchise(disease, my_theraFranchise);
  const my_type = useSelector((state) => state.diease.drugTypeDisplay);
  const nb_type = useSelector((state) => state.diease.nameDrugType);
  const my_target = useSelector((state) => state.diease.drugTargetDisplay);
  const target = useSelector((state) => state.diease.nameDrugTarget);
  const nb_target = useSelector((state) => state.diease.nameDrugTarget);
  const nb_company = useSelector((state) => state.company.nameCompany);
  //const my_dol_speciality = useSelector(
  //  (state) => state.diease.speciality_user
  //);
  const profession = useSelector((state) => state.diease.speciality_name);
  const my_dol_profession_group = useSelector(
    (state) => state.diease.profession_group_user
  );
  const nb_profession_group = useSelector(
    (state) => state.diease.profession_group_name
  );
  const my_dol_location = useSelector((state) => state.diease.location_user);
  const nb_location = useSelector((state) => state.diease.location_name);
  //const nb_profession = filter_profession_by_profession_group(
  //  profession,
  //  my_dol_profession_group
  //);
  const nb_drug = filterDataDrug(
    alldrug,
    depth,
    my_disease,
    my_type,
    [],
    my_theraFranchise,
    my_target
  );

  const [ratingValue, setRatingValue] = useState(my_option);

  useEffect(() => {
    dispatch(set_filter_ranking_dol_user(ratingValue));
  }, [dispatch, ratingValue]);

  useEffect(() => {
    if (nb_theraFranchise && nb_theraFranchise.length === 0) {
      dispatch(getAllTheraFranchise());
    }
  }, [dispatch, nb_theraFranchise]);

  useEffect(() => {
    if (disease && disease.length === 0) {
      dispatch(getAllDisease());
    }
  }, [dispatch, disease]);

  useEffect(() => {
    if (target && target.length === 0) {
      dispatch(getAllTarget());
    }
  }, [dispatch, target]);

  useEffect(() => {
    if (nb_type && nb_type.length === 0) {
      dispatch(getAllDrugType());
    }
  }, [dispatch, nb_type]);

  useEffect(() => {
    if (alldrug && alldrug.length === 0) {
      dispatch(getAllDrug());
    }
  }, [dispatch, alldrug]);

  useEffect(() => {
    if (profession && profession.length === 0) {
      dispatch(get_all_speciality());
    }
  }, [dispatch, profession]);

  useEffect(() => {
    if (nb_profession_group && nb_profession_group.length === 0) {
      dispatch(get_all_profession_group());
    }
  }, [dispatch, nb_profession_group]);

  useEffect(() => {
    if (nb_location && nb_location.length === 0) {
      dispatch(get_all_location());
    }
  }, [dispatch, nb_location]);

  useEffect(() => {
    if (
      nb_theraFranchise &&
      nb_theraFranchise.length > 0 &&
      disease &&
      disease.length > 0 &&
      target &&
      target.length > 0 &&
      nb_type &&
      nb_type.length > 0 &&
      alldrug &&
      alldrug.length > 0 &&
      nb_profession_group &&
      nb_profession_group.length > 0 &&
      profession &&
      profession.length > 0 &&
      nb_location &&
      nb_location.length > 0
    ) {
      dispatch(setGlobalLoadingAnimation(false));
    }
  }, [
    dispatch,
    nb_theraFranchise,
    disease,
    target,
    nb_type,
    alldrug,
    nb_profession_group,
    profession,
    nb_location,
  ]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        contentWrapperRef.current &&
        !contentWrapperRef.current.contains(event.target)
      ) {
        setOpenFilter(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [contentWrapperRef]);

  const menu_contente = [
    /*DashBoardDrug*/
    [
      {
        name: "Period",
        rendercontent: <DatePickerMonth type={"period"} />,
        nb_selected: 0,
        total_nb: 0,
      },
      {
        name: "Therapeutics",
        rendercontent: (
          <SelectAllDrug
            name={"Search for a drug"}
            value={0}
            is_filter={false}
          />
        ),
        nb_selected: 0,
        total_nb: nb_drug.length,
      },
      {
        name: "Current Phase",
        rendercontent: <BtnPhaseDisplay />,
        nb_selected: depth.length,
        total_nb: 0,
      },
      {
        name: "Therapeutics Franchises",
        rendercontent:
          nb_theraFranchise.length !== 0 ? <BtnTheraFranchiseDisplay /> : null,
        nb_selected: my_theraFranchise.length,
        total_nb: nb_theraFranchise.length,
      },
      {
        name: "Diseases",
        rendercontent: nb_disease.length !== 0 ? <BtnDiseaseDisplay /> : null,
        nb_selected: my_disease.length,
        total_nb: nb_disease.length,
      },
      {
        name: "Biological Target",
        rendercontent: nb_target.length !== 0 ? <BtnDrugTargetDisplay /> : null,
        nb_selected: my_target.length,
        total_nb: nb_target.length,
      },
      //{ name : 'MOA', rendercontent:  <BtnDrugMOADisplay/>, nb_selected : my_MOA.length, total_nb : nb_MOA.length},
      {
        name: "Drug Type",
        rendercontent: nb_type.length !== 0 ? <BtnDrugTypeDisplay /> : null,
        nb_selected: my_type.length,
        total_nb: nb_type.length,
      },
      {
        name: "Personas",
        rendercontent: <BtnDOLPersonasDisplay />,
        nb_selected: 0,
        total_nb: 0,
      },
    ],
    /*DashBoardCompany*/
    [
      {
        name: "Period",
        rendercontent: <DatePickerMonth type={"period"} />,
        nb_selected: 0,
        total_nb: 0,
      },
      {
        name: "Company",
        rendercontent: (
          <SelectAllCompany name={"Search for a company"} value={1} />
        ),
        nb_selected: 0,
        total_nb: nb_company.length,
      },
      {
        name: "Market Cap",
        rendercontent: <BtnMarketCapDisplay />,
        nb_selected: 0,
        total_nb: 0,
      },
      {
        name: "Personas",
        rendercontent: <BtnDOLPersonasDisplay />,
        nb_selected: 0,
        total_nb: 0,
      },
    ],
    /*DrugPage*/
    [
      {
        name: "Period",
        rendercontent: <DatePickerMonth type={"period"} />,
        nb_selected: 0,
        total_nb: 0,
      },
      {
        name: "Therapeutics",
        rendercontent: (
          <SelectAllDrug name={"Search a drug"} value={0} is_filter={false} />
        ),
        nb_selected: 0,
        total_nb: nb_drug.length,
      },
      {
        name: "Current Phase",
        rendercontent: <BtnPhaseDisplay />,
        nb_selected: depth.length,
        total_nb: 0,
      },
      {
        name: "Therapeutics Franchises",
        rendercontent:
          nb_theraFranchise.length !== 0 ? <BtnTheraFranchiseDisplay /> : null,
        nb_selected: my_theraFranchise.length,
        total_nb: nb_theraFranchise.length,
      },
      {
        name: "Diseases",
        rendercontent: nb_disease.length !== 0 ? <BtnDiseaseDisplay /> : null,
        nb_selected: my_disease.length,
        total_nb: nb_disease.length,
      },
      {
        name: "Biological Target",
        rendercontent: nb_target.length !== 0 ? <BtnDrugTargetDisplay /> : null,
        nb_selected: my_target.length,
        total_nb: nb_target.length,
      },
      //{ name : 'MOA', rendercontent:  <BtnDrugMOADisplay/>, nb_selected : my_MOA.length, total_nb : nb_MOA.length},
      {
        name: "Drug Type",
        rendercontent: nb_type.length !== 0 ? <BtnDrugTypeDisplay /> : null,
        nb_selected: my_type.length,
        total_nb: nb_type.length,
      },
      {
        name: "Personas",
        rendercontent: <BtnDOLPersonasDisplay />,
        nb_selected: 0,
        total_nb: 0,
      },
    ],
    /*CompanyPage*/
    [
      {
        name: "Period",
        rendercontent: <DatePickerMonth type={"period"} />,
        nb_selected: 0,
        total_nb: 0,
      },
      {
        name: "Company",
        rendercontent: (
          <SelectAllCompany name={"Search for a company"} value={1} />
        ),
        nb_selected: 0,
        total_nb: nb_company.length,
      },
      {
        name: "Market Cap",
        rendercontent: <BtnMarketCapDisplay />,
        nb_selected: 0,
        total_nb: 0,
      },
      {
        name: "Current Phase",
        rendercontent: <BtnPhaseDisplay />,
        nb_selected: depth.length,
        total_nb: 0,
      },
      {
        name: "Therapeutics Franchises",
        rendercontent:
          nb_theraFranchise.length !== 0 ? <BtnTheraFranchiseDisplay /> : null,
        nb_selected: my_theraFranchise.length,
        total_nb: nb_theraFranchise.length,
      },
      {
        name: "Diseases",
        rendercontent: nb_disease.length !== 0 ? <BtnDiseaseDisplay /> : null,
        nb_selected: my_disease.length,
        total_nb: nb_disease.length,
      },
      {
        name: "Biological Target",
        rendercontent: nb_target.length !== 0 ? <BtnDrugTargetDisplay /> : null,
        nb_selected: my_target.length,
        total_nb: nb_target.length,
      },
      //{ name : 'MOA', rendercontent:  <BtnDrugMOADisplay/>, nb_selected : my_MOA.length, total_nb :nb_MOA.length},
      {
        name: "Drug Type",
        rendercontent: nb_type.length !== 0 ? <BtnDrugTypeDisplay /> : null,
        nb_selected: my_type.length,
        total_nb: nb_type.length,
      },
    ],
    /*CongressPage*/
    [
      {
        name: "Period",
        rendercontent: <DatePickerCongress />,
        nb_selected: 0,
        total_nb: 0,
      },
      {
        name: "Congress",
        rendercontent: <SelectCongressDisplay />,
        nb_selected: 0,
        total_nb: 0,
      },
      {
        name: "Current Phase",
        rendercontent: <BtnPhaseDisplay />,
        nb_selected: depth.length,
        total_nb: 0,
      },
      {
        name: "Therapeutics Franchises",
        rendercontent:
          nb_theraFranchise.length !== 0 ? <BtnTheraFranchiseDisplay /> : null,
        nb_selected: my_theraFranchise.length,
        total_nb: nb_theraFranchise.length,
      },
      {
        name: "Diseases",
        rendercontent: nb_disease.length !== 0 ? <BtnDiseaseDisplay /> : null,
        nb_selected: my_disease.length,
        total_nb: nb_disease.length,
      },
      {
        name: "Biological Target",
        rendercontent: nb_target.length !== 0 ? <BtnDrugTargetDisplay /> : null,
        nb_selected: my_target.length,
        total_nb: nb_target.length,
      },
      //{ name : 'MOA', rendercontent:  <BtnDrugMOADisplay/> , nb_selected : my_MOA.length, total_nb :nb_MOA.length},
      {
        name: "Drug Type",
        rendercontent: nb_type.length !== 0 ? <BtnDrugTypeDisplay /> : null,
        nb_selected: my_type.length,
        total_nb: nb_type.length,
      },
      {
        name: "Personas",
        rendercontent: <BtnDOLPersonasDisplay />,
        nb_selected: 0,
        total_nb: 0,
      },
    ],
    /*CompetitivePage*/
    [
      {
        name: "Therapeutics Franchises",
        rendercontent:
          nb_theraFranchise.length !== 0 ? <BtnTheraFranchiseDisplay /> : null,
        nb_selected: my_theraFranchise.length,
        total_nb: nb_theraFranchise.length,
      },
      {
        name: "Current Phase",
        rendercontent: <BtnPhaseDisplay />,
        nb_selected: depth.length,
        total_nb: 0,
      },
      {
        name: "Diseases",
        rendercontent: nb_disease.length !== 0 ? <BtnDiseaseDisplay /> : null,
        nb_selected: my_disease.length,
        total_nb: nb_disease.length,
      },
      {
        name: "Biological Target",
        rendercontent: nb_target.length !== 0 ? <BtnDrugTargetDisplay /> : null,
        nb_selected: my_target.length,
        total_nb: nb_target.length,
      },
      //{ name : 'MOA', rendercontent:  <BtnDrugMOADisplay/>, nb_selected : my_MOA.length, total_nb : nb_MOA.length},
      {
        name: "Drug Type",
        rendercontent: nb_type.length !== 0 ? <BtnDrugTypeDisplay /> : null,
        nb_selected: my_type.length,
        total_nb: nb_type.length,
      },
      {
        name: "Personas",
        rendercontent: <BtnDOLPersonasDisplay />,
        nb_selected: 0,
        total_nb: 0,
      },
    ],
    /*HomePage*/
    [
      {
        name: "Therapeutics",
        rendercontent: (
          <SelectAllDrug
            name={"Search for a drug"}
            value={0}
            is_filter={false}
          />
        ),
        nb_selected: 0,
        total_nb: nb_drug.length,
      },
      {
        name: "Company",
        rendercontent: (
          <SelectAllDrug name={"Search for a company"} value={1} />
        ),
        nb_selected: 0,
        total_nb: nb_company.length,
      },
      {
        name: "Personas",
        rendercontent: <BtnDOLPersonasDisplay />,
        nb_selected: 0,
        total_nb: 0,
      },
    ],
    /*DashboardDOL*/
    [
      {
        name: "Profession Group",
        rendercontent: <BtnProfessionGroupDisplay />,
        nb_selected: my_dol_profession_group.length,
        total_nb: nb_profession_group.length,
      },
      /*{
        name: "Profession",
        rendercontent: <BtnProfessionDisplay />,
        nb_selected: my_dol_speciality.length,
        total_nb: nb_profession.length,
      },*/
      {
        name: "Location",
        rendercontent: <BtnLocationDisplay />,
        nb_selected: my_dol_location.length,
        total_nb: nb_location.length,
      },
      {
        name: "Therapeutics Franchises",
        rendercontent:
          nb_theraFranchise.length !== 0 ? <BtnTheraFranchiseDisplay /> : null,
        nb_selected: my_theraFranchise.length,
        total_nb: nb_theraFranchise.length,
      },
      {
        name: "Diseases",
        rendercontent: nb_disease.length !== 0 ? <BtnDiseaseDisplay /> : null,
        nb_selected: my_disease.length,
        total_nb: nb_disease.length,
      },
      {
        name: "Biological Target",
        rendercontent: nb_target.length !== 0 ? <BtnDrugTargetDisplay /> : null,
        nb_selected: my_target.length,
        total_nb: nb_target.length,
      },
      {
        name: "Drug Type",
        rendercontent: nb_type.length !== 0 ? <BtnDrugTypeDisplay /> : null,
        nb_selected: my_type.length,
        total_nb: nb_type.length,
      },
      {
        name: "Personas",
        rendercontent: <BtnDOLPersonasDisplay is_dol_page={true} />,
        nb_selected: 0,
        total_nb: 0,
      },
    ],
  ];

  const resetBtn = () => {
    dispatch(resetDisplay());
  };
  return (
    <div className="control-pannel" ref={contentWrapperRef}>
      <div className="">
        <h2 className="control-pannel--title">FILTERS</h2>
        <div>
          <ul className="controlPannel-filters" ref={listRef}>
            {menu_contente[CONTENT_SELECTED[value]][0].name === "Period" ? (
              <li
                className={`controlPannel-box ${openPeriod ? "selected" : ""}`}
              >
                <div>
                  <div className="controlPannel-box-container">
                    <div className="controlPannel-box-name">
                      <h4 className="controlPannel-box-name-title"> Period</h4>{" "}
                    </div>
                    <div className="controlPannel-box-expandIcon-filter">
                      <IconButton
                        className={`controlPannel-box-expandIcon-filter-period ${
                          openPeriod ? "rotated" : ""
                        }`}
                        onClick={() => handleChange("Period", 0)}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </li>
            ) : null}
            {menu_contente[CONTENT_SELECTED[value]][0].name === "Period" ? (
              <MuiCollapse is_open={openPeriod}>
                {menu_contente[CONTENT_SELECTED[value]][0].rendercontent}
              </MuiCollapse>
            ) : null}
            {menu_contente[CONTENT_SELECTED[value]][
              menu_contente[CONTENT_SELECTED[value]].length - 1
            ].name === "Personas" ? (
              <li
                className={`controlPannel-box ${
                  is_open_filter["Personas"] ? "selected" : ""
                }`}
              >
                <div>
                  <div className="controlPannel-box-container">
                    <div className="controlPannel-box-name">
                      <h4 className="controlPannel-box-name-title">
                        {" "}
                        Personas
                      </h4>{" "}
                    </div>
                    <div className="controlPannel-box-expandIcon-filter">
                      <IconButton
                        className={`controlPannel-box-expandIcon-filter-period ${
                          is_open_filter["Personas"] ? "rotated" : ""
                        }`}
                        onClick={() =>
                          handleChange(
                            "Personas",
                            menu_contente[CONTENT_SELECTED[value]].length - 1
                          )
                        }
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </li>
            ) : null}
            {menu_contente[CONTENT_SELECTED[value]][
              menu_contente[CONTENT_SELECTED[value]].length - 1
            ].name === "Personas" ? (
              <MuiCollapse is_open={is_open_filter["Personas"]}>
                {
                  menu_contente[CONTENT_SELECTED[value]][
                    menu_contente[CONTENT_SELECTED[value]].length - 1
                  ].rendercontent
                }
              </MuiCollapse>
            ) : null}
            {menu_contente[CONTENT_SELECTED[value]].map((content, index) =>
              content.name === "Period" ||
              content.name === "Personas" ? null : (
                <li
                  key={index}
                  className={`controlPannel-box ${
                    is_open_filter[content.name] ? "selected" : ""
                  }`}
                >
                  <div>
                    <div className="controlPannel-box-container">
                      <div className="controlPannel-box-name">
                        <h4 className="controlPannel-box-name-title">
                          {" "}
                          {content.name}
                          <span className="controlPannel-box-total_selected">
                            {content.total_nb !== 0
                              ? "  (" + content.total_nb + ")"
                              : null}
                          </span>
                        </h4>{" "}
                      </div>
                      <div className="controlPannel-box-expandIcon-filter">
                        <span className="controlPannel-box-nb_selected">
                          {" "}
                          {content.nb_selected !== 0
                            ? content.nb_selected
                            : null}{" "}
                        </span>
                        <IconButton
                          className={`controlPannel-box-expandIcon-filter ${
                            is_open_filter[content.name] ? "rotated" : ""
                          }`}
                          onClick={() => handleChange(content.name, index)}
                        >
                          <ExpandMoreIcon />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                </li>
              )
            )}
            {CONTENT_SELECTED[value] === 7 ? (
              <li className="controlPannel-box">
                <div className="controlPannel-box-container-ratings">
                  <div className="controlPannel-box-name">
                    <h4 className="controlPannel-box-name-title">
                      {" "}
                      Personas' Ratings
                    </h4>{" "}
                  </div>
                  <div className="personas-filter-container__list-columns-stars">
                    {listColumnsStars.map((content, index) => (
                      <div
                        className="personas-filter-container__list-columns-stars__stars-container"
                        key={index}
                      >
                        <h2>
                          {content}
                          <span className="controlPannel-box-total_selected">
                            {ratingValue[content] !== null
                              ? "  (+" + ratingValue[content] + ")"
                              : null}
                          </span>
                        </h2>
                        <MuiRating
                          name={content}
                          value={ratingValue[content]}
                          onChange={(event, newValue) => {
                            const new_state = { ...ratingValue };
                            new_state[event.target.name] = newValue;
                            setRatingValue(new_state);
                          }}
                          size="small"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </li>
            ) : null}
          </ul>
        </div>
        <div className="controlPannel-filter-reset-button">
          <Tooltip title="Delete">
            <IconButton onClick={resetBtn}>
              <RestartAltIcon />
            </IconButton>
          </Tooltip>
        </div>
        <img src={logo} alt="Logo" className="logo_tendance" />
      </div>
      <div>
        {menu_contente[CONTENT_SELECTED[value]].map((content, i) =>
          content.name === "Period" || content.name === "Personas" ? null : (
            <div
              className={
                is_open_filter[content.name] ? `content-wrapper` : "invisible"
              }
            >
              {content.rendercontent}
              <div className="controlPannel-box-expandIcon-filter-detail">
                <IconButton
                  className={`controlPannel-box-expandIcon-filter ${
                    is_open_filter[content.name] ? "rotated" : ""
                  }`}
                  onClick={() => handleChange(content.name)}
                >
                  <ExpandMoreIcon />
                </IconButton>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}
