import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getWorldCloudDolProfileData, setdataDOLProfileWorldCloudAnimation } from "../../Action";
import {
  DiseaseDolWorldCloud,
  MuiCard,
  NctDolWorldCloud,
  TherapeuticsDolWorldCloud,
  AllDrugCompanyDetailByPhaseLegend,
  LrxDolYearChips
} from "../../components";

export function DolWordCloud(props) {
  const {dolProfileId} = props;
  const dispatch = useDispatch();
  
  const year = useSelector((state) => state.dol.dolprofile_graph_year);
  
  useEffect(() => {
    dispatch(getWorldCloudDolProfileData(dolProfileId, year));
    dispatch(setdataDOLProfileWorldCloudAnimation(true));
  }, [dispatch, dolProfileId, year]);

  return(
    <div className="dol-profile-pannel__details-container__graphs-and-wordclouds-container">
      <div className="dol-profile-pannel__details-container__graphs-and-wordclouds-container__item-display">
        <h2 className="dol-profile-pannel__details-container__graphs-and-wordclouds-container__item-display--title">
          Clinical Trials <LrxDolYearChips />
        </h2>
        <MuiCard mui_class={""}>
          <NctDolWorldCloud />
          <AllDrugCompanyDetailByPhaseLegend is_nct={true} />
        </MuiCard>
      </div>
      <div className="dol-profile-pannel__details-container__graphs-and-wordclouds-container__item-display">
        <h2 className="dol-profile-pannel__details-container__graphs-and-wordclouds-container__item-display--title">
          Therapeutics <LrxDolYearChips />
        </h2>
        <MuiCard mui_class={""}>
          <TherapeuticsDolWorldCloud />
          <AllDrugCompanyDetailByPhaseLegend is_nct={false} />
        </MuiCard>
      </div>
      <div className="dol-profile-pannel__details-container__graphs-and-wordclouds-container__item-display">
        <h2 className="dol-profile-pannel__details-container__graphs-and-wordclouds-container__item-display--title">
          Diseases <LrxDolYearChips />
        </h2>
        <MuiCard mui_class={""}>
          <DiseaseDolWorldCloud />
        </MuiCard>
      </div>
    </div>
  );
}

