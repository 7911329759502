import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { format } from "date-fns";

import { DataFilterTable, LoadingAnimation } from "..";
import { get_filter_key } from "../../utils";
import { getRSSCompanyTab, setRSSCompanyTabAnimation } from "../../Action";

export function RSSCompanyTable(props) {
  const { filter_date, period, companyId, rsstabclassName, enableFiltering = true, enablePagination = true, rssCompanyValue } = props;
  const navigate = useNavigate();
  const data = useSelector((state) => state.company.RSSTab);
  const load = useSelector((state) => state.config.tabRSSCompanyLoading);
  const [dataRSS, setRSS] = useState(data);
  const dispatch = useDispatch();

  const filterFunctionDateWithPeriod = (item, date, daysAgo) => {
    const itemDate = new Date(item.published_at);
    return itemDate <= date && itemDate > daysAgo;
  };
  const getNewsOfTheDay = (dateD) => {
    if (period === "day") {
      const date_string = format(dateD, "yyyy-MM-dd");
      return data.filter((d) => d.published_at === date_string);
    } else if (period === "week") {
      const sevenDays = new Date(dateD.getTime() - 7 * 24 * 60 * 60 * 1000);
      return data.filter((item) =>
        filterFunctionDateWithPeriod(item, dateD, sevenDays)
      );
    } else if (period === "month") {
      const thirtyDays = new Date(dateD.getTime() - 30 * 24 * 60 * 60 * 1000);
      return data.filter((item) =>
        filterFunctionDateWithPeriod(item, dateD, thirtyDays)
      );
    } else if (period === "quarter") {
      const nineDays = new Date(dateD.getTime() - 90 * 24 * 60 * 60 * 1000);
      return data.filter((item) =>
        filterFunctionDateWithPeriod(item, dateD, nineDays)
      );
    }
  };

  useEffect(() => {
    if (period && filter_date) {
      setRSS(getNewsOfTheDay(filter_date));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter_date, period]);


  const go_to = (name, id) => {
    if (name === "drug") {
      navigate(`/drug/${id}`);
    }
    if (name === "link") {
      const win = window.open(id, "_blank");
      if (win != null) win.focus();
    }
  };  

  useEffect(() => {
    dispatch(getRSSCompanyTab(companyId));
    dispatch(setRSSCompanyTabAnimation(true));
  }, [companyId, dispatch]);

  useEffect(() => {
    if(rssCompanyValue === 0){
      dataRSS.length = 0;
    }
    else if(rssCompanyValue === 1){
      setRSS(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dispatch]);

  const columns = [
    {
      name: "published_at",
      header: "Date",
      maxWidth: 85,
      minWidth: 85,
    },
    {
      name: "drug_true_name",
      header: "Drug",
      defaultFlex: 1,
      minWidth: 100,
      render: ({ value, data }) => (
        <span className="title-link" onClick={() => go_to("drug", data.id_drug)}>{value}</span>
      ),
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("drug_true_name", data),
      },
    },
    {
      name: "disease_display_name",
      header: "Disease",
      defaultFlex: 1.5,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("disease_display_name", data),
      },
    },
    {
      name: "topics",
      header: "Topics",
      defaultFlex: 1.5,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("topics", data),
      },
    },
    {
      name: "title",
      header: "Title",
      defaultFlex: 5,
      render: ({ value, data }) => (
        <span className="title-link" onClick={() => go_to("link", data.link)}>{value}</span>
      ),
    },
  ];

  const filterValue = [
    { name: "drug_true_name", operator: "inlist", type: "select", value: "" },
    {
      name: "disease_display_name",
      operator: "inlist",
      type: "select",
      value: "",
    },
    { name: "title", operator: "contains", type: "string", value: "" },
    { name: "topics", operator: "inlist", type: "select", value: "" },
    { name: "sentiment", operator: "inlist", type: "select", value: "" },
  ];
  return (
    <div
      className={`rss-company-table ${rsstabclassName}${dataRSS.length !== 0 ? "--display " : "--no-display"}`}
      style={{ 
        height: 
          rsstabclassName === "company-graph-pannel__social-tabs__volume-container__rss-tab" 
            ? (dataRSS.length > 0 && dataRSS.length <= 5
                ? `${dataRSS.length * 40 + 40}px`
                : null      
              )
            : null
            }}
    >
      {load === false ? (
        dataRSS.length !== 0 ? (
          <DataFilterTable
            dataSource={dataRSS}
            columns={columns}
            filterValue={filterValue}
            defaultFilterName={[]}
            enableFiltering={enableFiltering}
            enablePagination={enablePagination}
          />
        ) : null
      ) : (
        <div className="loading-animation" style={{height:"100%"}}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
