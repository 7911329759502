import React from "react";
import { useNavigate } from "react-router-dom";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';



export function MUItoogleBtn(props){
    const {action_btn, setbtn, which_btn} = props
    const navigate = useNavigate();
    const handleAlignment = (event, btn_number) => {
        setbtn(btn_number);
        updateURLParams('periods', btn_number);
    };

    const updateURLParams = (paramName, paramValue) => {
      const params = new URLSearchParams(window.location.search);
      params.set(paramName, paramValue);
      const nouvelleURL = `${window.location.pathname}?${params.toString()}`;
      navigate(nouvelleURL);
    };

  return (
    <ToggleButtonGroup
      value={which_btn}
      exclusive
      onChange={handleAlignment}
      size='small'
    >
    {action_btn.map((btn, i) =>       
    <ToggleButton  key={i} value={i}>
         <ViewQuiltIcon />
         {btn}
      </ToggleButton>
    )}
    </ToggleButtonGroup>
  );
}