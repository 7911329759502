import React from "react";

import "./style//animation_point.css";

export function ThreePointAnimation() {
  return (
    <div className="load-row">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}
