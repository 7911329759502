import { api } from "../config";
import { format } from "date-fns";

export const getAllCongress = () => async (dispatch) => {
  try {
    const { data } = await api.get("data/congress");
    dispatch({
      type: "GET_ALL_CONGRESS",
      value: data.data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_CONGRESS",
      value: err,
    });
  }
  dispatch({
    type: "SET_CONGRESS_ALL_CARDS_LOADING_ANIMATION",
    value: false,
  });
};

export const getRSSCongressTab = (congressId, year) => async (dispatch) => {
  try {
    const { data } = await api.get(`data/RSS/congress/${congressId}`, {
      params: { year: year},
    });
    dispatch({
      type: "GET_RSS_CONFERENCE",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DRUG",
      value: err,
    });
  }
  dispatch({
    type: "SET_RSS_TAB_LOADING_ANIMATION",
    value: false,
  });
};

export const setCongressYears = (selectedDate) => async (dispatch) => {
  dispatch({
    type: "SET_CONGRESS_YEARS",
    value: selectedDate,
  });
};

export const setCongressName = (name) => async (dispatch) => {
  dispatch({
    type: "SET_CONGRESS_NAME",
    value: name,
  });
};

export const getCongressDrugTreemap = (name, years) => async (dispatch) => {
  try {
    const { data } = await api.get(`data/volume/congress/${name}/treemap`, {
      params: { date: format(new Date(years), "yyyy-MM-dd") },
    });
    dispatch({
      type: "GET_CONGRESS_TREEMAP",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_CONGRESS",
      value: err,
    });
  }
  dispatch({
    type: "SET_TM_CONGRESS_LOADING_ANIMATION",
    value: false,
  });
};

export const getCongressDrug = (name, years) => async (dispatch) => {
  try {
    const { data } = await api.get(`data/volume/congress/${name}/trend`, {
      params: { date: format(new Date(years), "yyyy-MM-dd") },
    });
    dispatch({
      type: "GET_CONGRESS_DRUG",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_CONGRESS",
      value: err,
    });
  }
  dispatch({
    type: "SET_TAB_CONGRESS_DRUG_LOADING_ANIMATION",
    value: false,
  });
};

export const getCongressbyDrug =
  (id_conference, id_drug) => async (dispatch) => {
    try {
      const { data } = await api.get(
        `data/volume/congress/${id_conference}/drug/${id_drug}`
      );
      dispatch({
        type: "GET_DRUG_CONFERENCE",
        value: data,
      });
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_CONGRESS",
        value: err,
      });
    }
    dispatch({
      type: "SET_DRUG_CONFERENCE_ANIMATION",
      value: false,
    });
  };
