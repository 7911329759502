import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  AllDrugCongressTable,
  TreeMapCongress,
  LegendTreemap,
  AllCongressCard,
  DrugCongressPannel,
  PublicationTable,
  MuiCard,
  MUIChevron,
  LrxChips,
  LoadingAnimation,
  ModalNeedSocialAccess,
  ChartIcon,
  LrxBtn,
  RSSCongressTable,
  LrxMenuInput,
} from "../../components";

import {
  filter_by_theraFranchise,
  filter_by_years,
  is_departement_is_social,
} from "../../utils";

import {
  setCongressName,
  getAllCongress,
  setMyDrug,
  setCongressAllCardsLoadingAnimation,
} from "../../Action";

import "./style/congress_graph_pannel.css";
import congress from "../../../Asset/congress-page.png";

export function CongressGraphPannel() {
  const name = useSelector((state) => state.congress.congressName);
  const date = useSelector((state) => state.congress.congressYears);
  const drug = useSelector((state) => state.diease.drugDisplay);
  const user = useSelector((state) => state.auth.auth_name);
  const load = useSelector((state) => state.config.congressAllCardsLoading);
  const theraFranchise = useSelector(
    (state) => state.diease.theraFranchiseDisplay
  );
  const nameItem = filter_by_years(
    filter_by_theraFranchise(
      useSelector((state) => state.congress.congressAllName),
      theraFranchise
    ),
    new Date(date).getFullYear()
  );
  const dateNews = useSelector((state) => state.config.date);

  const dispatch = useDispatch();

  useEffect(() => {
    if (nameItem && nameItem.length === 0) {
      dispatch(getAllCongress());
      dispatch(setCongressAllCardsLoadingAnimation(true));
    }
  }, [dispatch, nameItem]);

  const resetConferenceName = (e) => {
    dispatch(setCongressName(null));
  };

  const resetDrugName = (e) => {
    dispatch(setMyDrug(null));
  };

  const [is_open, set_open] = useState(false);
  const handleChange = (open) => set_open(open);

  const [searchValue, setSearchValue] = useState("");

  const congressListFiltered = nameItem.filter((user) =>
    user.conference_name.toLowerCase().includes(searchValue.toLowerCase())
  );

  return is_departement_is_social(user) ? (
    name != null ? (
      drug == null ? (
        <div>
          <div className="congress_pannel_header">
            <div onClick={resetConferenceName}>
              {" "}
              {""} <MUIChevron />{" "}
            </div>
            <div className="congress_pannel_header-center-title">
              {" "}
              {name != null ? name.conference_name : null}{" "}
              {new Date(date).getFullYear()} <LrxChips />
            </div>
          </div>
          <div className="congress_pannel_page">
            <MuiCard mui_class="treemap-card" mui_value={1}>
              <TreeMapCongress filtre={-1} filter={"drugDisplay"} />
              <br />
              <LegendTreemap legend_value={1} />
            </MuiCard>
            <MuiCard mui_class="">
              <AllDrugCongressTable />
            </MuiCard>
            <MuiCard mui_class={""}>
            <h2>Press Releases</h2>
              <RSSCongressTable
                congressId={name.id_congress}
                year={name.year}
              />
            </MuiCard>
          </div>
        </div>
      ) : (
        <div>
          <div className="congress_pannel_header">
            <div onClick={resetDrugName}>
              {" "}
              <MUIChevron />{" "}
            </div>
            <div className="congress_pannel_header-center-title">
              {" "}
              {drug.name}{" "}
            </div>
          </div>
          <div className="congress_pannel_details">
            <div className="drug-pannel_radar">
              <h2> {drug.name} during all congresses</h2>
              <MuiCard mui_class="">
                <DrugCongressPannel name={name} drugId={drug.id} />
              </MuiCard>
            </div>
            <div className="drug-pannel_tabshared">
              <h2>
                {" "}
                Shared Content{" "}
                {new Date(dateNews).toLocaleDateString("zh-Hans-CN")}
              </h2>
              <MuiCard mui_class="">
                {
                  <PublicationTable
                    drugId={drug.id}
                    if_history={false}
                    which_btn={0}
                    action_btn={[""]}
                  />
                }
              </MuiCard>
            </div>
          </div>
        </div>
      )
    ) : (
      <div>
        <div className="congress_pannel_header-container">
          <div className="congress_pannel_header-center-title">
            Medical conferences
          </div>
          <div className="congress_pannel_header-search-bar">
            <LrxMenuInput
              action_input={setSearchValue}
              name={"Search for a conference"}
              sx={{width:"550px"}}
            />
            <div className="congress_pannel_header-container--chips-container">
              <LrxChips is_congress_cards={true}/>
            </div>            
            <h3 style={{display: load === false ? null : "None"}}>
              {" "}
              {congressListFiltered.length === 0
                ? "Conference not found !"
                : congressListFiltered.length + " conference(s) available"}{" "}
            </h3>
          </div>          
        </div>
        {load === false ? (
          <div className="congress_pannel-container-all-congress">
            {congressListFiltered.map((el, i) => (
              <AllCongressCard key={i} content={el} setSearchValue={setSearchValue}/>
            ))}
          </div>
        ) : (
          <div
            className="loading-animation"
            style={{ height: "60vh", width: "100%" }}
          >
            <LoadingAnimation type={"linear"} />
          </div>
        )}
      </div>
    )
  ) : (
    <div className="locked-screen-trending-pannel">
      <ModalNeedSocialAccess more_info={is_open} onChange={handleChange} />
      <img
        className="locked-screen-trending-pannel__img"
        src={congress}
        alt="background-congress"
      />
      <div className="locked-screen-trending-pannel--icon-button">
        <div className="locked-screen-trending-pannel--icon-button__icon">
          <ChartIcon logo={"LockedIcon"} />
        </div>
        <div className="need-premium-modal__validate">
          <LrxBtn
            action_btn={() => handleChange(true)}
            logo={"ActionSucess"}
            text_btn={"More Info"}
            my_className={"need-premium-modal__validate--btn"}
          />
        </div>
      </div>
    </div>
  );
}
