import React from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css'

import "@inovua/reactdatagrid-community/theme/blue-light.css";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/index.css";

const gridStyle = { 
  minHeight: '100%',   
  fontFamily: 'Lato',
  fontSize: 11,
};
export function DataFilterTable(props) {
    const {columns, dataSource, filterValue, enableFiltering , enablePagination , customsFilter = {}} = props

    const filterTypes = Object.assign({}, ReactDataGrid.defaultProps.filterTypes, {
      customsFilter
    });

    return <ReactDataGrid
    idProperty="id"
    scrollTopOnFilter={false}
    columns={columns}
    dataSource={dataSource}
    style={gridStyle}
    enableFiltering={enableFiltering}
    filterTypes={filterTypes}
    defaultFilterValue={filterValue}
    pagination={enablePagination}
    is_ok={"oui"}
  />
}