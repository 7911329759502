import React from "react";
import { useSelector } from "react-redux";
import { LoadingAnimation, DataFilterTable } from "..";
import { useNavigate } from "react-router-dom";
import { get_filter_key, filterDataDrug } from "../../utils";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import BoolFilter from "@inovua/reactdatagrid-community/BoolFilter";

export function PublicationAlertTable(props) {
  const { defaultFilterName } = props;
  const navigate = useNavigate();
  const d = useSelector((state) => state.drug.publicationTD);
  const load = useSelector((state) => state.config.tabAlertPublicationLoading);

  const disease = useSelector((state) => state.diease.diseaseDisplay);
  const depth = useSelector((state) => state.diease.statusDrugDisplay);
  const type = useSelector((state) => state.diease.drugTypeDisplay);
  const theraFranchise = useSelector(
    (state) => state.diease.theraFranchiseDisplay
  );
  const target = useSelector((state) => state.diease.drugTargetDisplay);
  const data = filterDataDrug(
    d,
    depth,
    disease,
    type,
    [],
    theraFranchise,
    target
  );

  const customsFilter = {
    name: "customsFilter",
    operators: [
      {
        name: "filterWithName",
        fn: ({ value }) => {
          for (const element of defaultFilterName) {
            if (value.includes(element)) {
              return true;
            }
          }
          return false;
        },
      },
    ],
  };

  const go_to = (name, id) => {
    if (name === "drug") {
      navigate(`/drug/${id}`);
    }
    if (name === "link") {
      const win = window.open(id, "_blank");
      if (win != null) win.focus();
    }
  };

  const columns = [
    {
      name: "created_at",
      header: "First Time Shared",
      defaultFlex: 1,
      minWidth: 100,
      maxWidth: 160,
    },
    {
      name: "drug_true_name",
      header: "Drug",
      defaultFlex: 1,
      minWidth: 75,
      maxWidth: 110,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("drug_true_name", data),
      },
      render: ({ value, data }) => (
        <span className = "title-link" onClick={() => go_to("drug", data.id_drug)}>{value}</span>
      ),
    },
    {
      name: "titles",
      header: "Title",
      defaultFlex: 1,
      minWidth: 140,
      filterEditor: BoolFilter,
      render: ({ value, data }) => (
        <span className="title-link" onClick={() => go_to("link", data.link)}>{value}</span>
      ),
    },
    {
      name: "count",
      header: "Count",
      minWidth: 60,
      maxWidth: window.innerWidth === 1280 ? 68 : 88,
      type:"number",
      onRender: (cellProps, { data }) => {
        cellProps.style.display = "flex";
        cellProps.style.textAlign = 'center';
      },
    },
  ];

  const filterValue = [
    {
      name: "titles",
      operator: "filterWithName",
      type: "customsFilter",
      value: true,
    },
    { name: "drug_true_name", operator: "inlist", type: "select", value: "" },
  ];

  return (
    <div className="publication-alert-table">
      {load === false ? (
        <DataFilterTable
          dataSource={data}
          columns={columns}
          filterValue={filterValue}
          customsFilter={customsFilter}
          enableFiltering={false}
          defaultFilterName={defaultFilterName}
        />
      ) : (
        <div className="loading-animation" style={{height: "100%"}}>
          {" "}
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
