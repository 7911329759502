import React from "react";
import { useSelector } from "react-redux";
import { VerticalBarChart } from "../../components";

function dropDuplicates(data) {
  const uniqueData = data.reduce((result, [drug, phase, style, start, end]) => {
    const existingIndex = result.findIndex(
      ([existingDrug]) => existingDrug === drug
    );
    if (existingIndex !== -1) {
      const [, existingPhase] = result[existingIndex];
      if (phaseIsMoreAdvanced(phase, existingPhase)) {
        result[existingIndex] = [drug, phase, style, start, end];
      }
    } else {
      result.push([drug, phase, style, start, end]);
    }
    return result;
  }, []);
  return uniqueData;
}

function phaseIsMoreAdvanced(newPhase, existingPhase) {
  const phaseOrder = {
    "phase 1": 1,
    "phase 2": 2,
    "phase 3": 3,
  };
  return phaseOrder[newPhase] > phaseOrder[existingPhase];
}

function transformData(data) {
  const transformedData = [];
  for (let i = 1; i < data.length; i++) {
    const [, phase, , , end] = data[i];
    const year = new Date(end).getFullYear();
    const yearIndex = transformedData.findIndex((item) => item.name === year);
    if (yearIndex !== -1) {
      const phaseCount = transformedData[yearIndex][phase] || 0;
      transformedData[yearIndex][phase] = phaseCount + 1;
    } else {
      const newYearData = {
        name: year,
        "phase 3": 0,
        "phase 2": 0,
        "phase 1": 0,
      };
      newYearData[phase] = 1;
      transformedData.push(newYearData);
    }
  }
  transformedData.sort((a, b) => a.name - b.name);
  return transformedData;
}

export function CompetitiveDetailsInactiveByPhase(props) {
  const dataAD = useSelector(
    (state) => state.competitive.timeChartcompetitiveData[1]
  );
  const load = false;
  const uniqueData = dropDuplicates(dataAD);
  const transformedData = transformData(uniqueData);

  const datakeys = ["phase 1", "phase 1/phase 2", "phase 2", "phase 3"];

  const colors = ["#ad345c", "#e2823d", "#e2823d", "#27789E"];

  return !load ? (
    <div className="company-graph-pannel__treatement-tabs__details-graph__phase-graph--chart">
      <VerticalBarChart
        data={transformedData}
        keys={datakeys}
        colors={colors}
      />{" "}
    </div>
  ) : null;
}
