import React from "react";
import { useDispatch } from "react-redux";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Bar,
  Cell,
} from "recharts";

import { setDateRange } from "../../../../Action";

const get_color = (sentiment) => {
  if (sentiment === undefined) {
    return "#cfcfcf"
  }
  const colormetric = {
    "#f63333": -1,
    "#f49999": -0.1,
    "#cfcfcf": 0,
    "#94deb4": 0.1,
    "#2abc68": 1,
  };
  let my_color = "#cfcfcf";
  let biggest = 10;
  for (const color in colormetric) {
    let diff = Math.abs(colormetric[color] - sentiment);
    if (diff < biggest) {
      biggest = diff;
      my_color = color;
    }
  }
  return my_color;
};

export function LineChartVolume(props) {
  const { data, dataRSS, period } = props;
  const dispatch = useDispatch();

  //const CustomizedDot = (props) => {
  //   const { cx, cy,payload, value } = props;
  //   if (value/*> 0.3 || value < (-0.3)*/) {
  //     if (value >= 0)
  //       return (
  //         <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="green" viewBox="0 0 1024 1024">
  //            <circle cx="300" cy="300" r="300"/>
  //           {/*<path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />*/}
  //         </svg>
  //     );
  //     if (value < 0)
  //     return (
  //       <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="red" viewBox="0 0 1024 1024">
  //         <circle cx="300" cy="300" r="300"/>
  //         {/*<path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />*/}
  //       </svg>
  //     );
  //   }
  //   return (null);
  // };

  const filterFunctionDateWithPeriod = (item, date, daysAgo) => {
    const itemDate = new Date(item.published_at);
    return itemDate <= date && itemDate > daysAgo;
  };

  const getNewsOfTheDay = (label) => {
    const dateD = new Date(label);
    if (period === "day") {
      return dataRSS.filter((d) => d.published_at === label).length;
    } else if (period === "week") {
      const sevenDays = new Date(dateD.getTime() - 7 * 24 * 60 * 60 * 1000);
      return dataRSS.filter((item) =>
        filterFunctionDateWithPeriod(item, dateD, sevenDays)
      ).length;
    } else if (period === "month") {
      const thirtyDays = new Date(dateD.getTime() - 30 * 24 * 60 * 60 * 1000);
      return dataRSS.filter((item) =>
        filterFunctionDateWithPeriod(item, dateD, thirtyDays)
      ).length;
    } else if (period === "quarter") {
      const nineDays = new Date(dateD.getTime() - 90 * 24 * 60 * 60 * 1000);
      return dataRSS.filter((item) =>
        filterFunctionDateWithPeriod(item, dateD, nineDays)
      ).length;
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    const nb_news = getNewsOfTheDay(label);
    if (active && payload && payload.length) {
      return (
        <div className="line-chart-volume__custom-tooltip">
          <div>
            <h3 className="line-chart-volume__custom-tooltip--tooltip-label">{`${label}`}</h3>
            <h3 className="line-chart-volume__custom-tooltip--tooltip-title">{`${payload[0].value} tweets`}</h3>
          </div>
          <div className="line-chart-volume__custom-tooltip--tooltip-alert">
            {nb_news !== 0 ? `${nb_news} news` : null}
          </div>
        </div>
      );
    }

    return null;
  };

  function change_date(e) {
    if (e) {
      dispatch(setDateRange(new Date(e.activeLabel)));
    }
  }

  return (
    <ResponsiveContainer width="100%" height={200}>
      <ComposedChart
        data={data}
        onClick={change_date}
        margin={{
          top: 15,
          right: 20,
          left: 20,
          bottom: 15,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" angle={-15} textAnchor="end" scale="band" />
        <YAxis yAxisId="left" />
        {/*<YAxis yAxisId="right" orientation="right"  domain={[-1, 1]}   />*/}
        <Tooltip
          wrapperStyle={{ backgroundColor: "white", outline: "none" }}
          content={CustomTooltip}
        />
        <Bar yAxisId="left" dataKey="volume_1" barSize={30}>
          {data.map((entry, index) => {
            return (
              <Cell key={`cell-${index}`} fill={get_color(entry.sentiment_1)} />
            );
          })}
        </Bar>
        {/*<Line yAxisId="right" type="monotone" dataKey="sentiment_1" dot={false} />*/}
      </ComposedChart>
    </ResponsiveContainer>
  );
}
