import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { LoadingAnimation } from "../../components";
import {
  filterNCTPhaseCondition,
  filterNCTPhaseConditionDisease,
} from "../../utils";
import { TimeLineChart } from "../../components";
import { getNCTCompanyGraph, setNCTCompanyGraphAnimation } from "../../Action";

export function NCTCompanyGraph(props) {
  const { companyId } = props;
  const dispatch = useDispatch();
  const load = useSelector((state) => state.config.graphNCTCompanyLoading);
  const depth = useSelector((state) => state.diease.statusDrugDisplay);
  //const disease = useSelector((state) => state.diease.diseaseDisplay);
  //const target = useSelector((state) => state.diease.drugTargetDisplay);
  const dataNCT = useSelector((state) => state.company.nctCompany);

  dataNCT.map((l, index) => {
    if (index !== 0) {
      l[3] = new Date(l[3]);
      l[4] = new Date(l[4]);
    }
    return [l[3], l[4]];
  });

  const filterData = dataNCT ? filterNCTPhaseConditionDisease(dataNCT) : [];
  let minStartDate = null;
  let maxEndDate = null;
  for (let i = 1; i < dataNCT.length; i++) {
    const event = dataNCT[i];
    const startDate = event[3];
    const endDate = event[4]; 
    if (!minStartDate || startDate < minStartDate) {
      minStartDate = startDate;
    }
    if (!maxEndDate || endDate > maxEndDate) {
      maxEndDate = endDate;
    }
  }
  
  useEffect(() => {
    dispatch(getNCTCompanyGraph(companyId));
    dispatch(setNCTCompanyGraphAnimation(true));
  }, [companyId, dispatch]);

  return (
      <div className="nct-disease-competitive-graph">
        {load === false ? (
          filterData.map((el) =>
            filterNCTPhaseCondition(el.data, depth).length === 1 ? null : (
              <div>
                <span className="nct-disease-competitive-graph--titre">
                  {el.id}
                </span>
                <TimeLineChart
                  dataAD={
                    el.data ? filterNCTPhaseCondition(el.data, depth) : []
                  }
                  content={"treatment"}
                  infoDrug={dataNCT}
                  TimeLineStart={minStartDate}
                  TimeLineEnd={maxEndDate}
                />
              </div>
            )
          )
        ) : (
          <div className="loading-animation" style={{height: "200px"}}>
            <LoadingAnimation type={"circular"} />
          </div>
        )}
      </div>
  );
}
