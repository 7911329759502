import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataByDrug,
  setGraphDrugLoadingAnimation,
  getDataByDrugDolDisease,
  getDataByDrugDol,
} from "../../Action";
import {
  LoadingAnimation,
  LineChartVolume,
  MUItoogleBtn,
  PhaseGraph,
} from "../../components";

export function SentimentVolumeDrugGraph(props) {
  const { drugId, setbtn, action_btn, which_btn } = props;
  const dispatch = useDispatch();
  const disease = useSelector((state) => state.diease.diseaseDisplay);
  const [dataDrug, setdataDrug] = useState("by_drug");

  const is_disease_is_selected = disease.length === 0 ? false : true;
  const personas = useSelector((state) => state.dol.list_personas);
  
  const foundKey = Object.keys(personas).find(key => personas[key] === true);
  const persona_name_selected = foundKey ? foundKey.toUpperCase() : null;
  const is_personas_selected = Object.values(personas).some(value => value === true);

  const load = useSelector((state) => state.config.graphDrugLoading);
  const databyId = useSelector((state) => state.drug.databyId[dataDrug]);

  const dataRSS = useSelector((state) => state.drug.RSSTab);
  function isNumeric(value) {
    return /^-?\d+$/.test(value);
  }

  const startDate = databyId.length !== 0 ? databyId[0].date : null;
  const endDate =
    databyId.length !== 0 ? databyId[databyId.length - 1].date : null;

  useEffect(() => {
    if (drugId !== "-1" && isNumeric(drugId)) {
      if (is_disease_is_selected) {
        if (is_personas_selected) {
          dispatch(
            getDataByDrugDolDisease(drugId, disease, action_btn[which_btn], persona_name_selected)
          );
          setdataDrug("by_disease_dol");
        } else {
          dispatch(getDataByDrug(drugId, disease, action_btn[which_btn], true));
          setdataDrug("by_disease");
        }
      } else if (is_personas_selected) {
        dispatch(getDataByDrugDol(drugId, action_btn[which_btn], persona_name_selected));
        setdataDrug("by_dol");
      } else {
        dispatch(getDataByDrug(drugId, [], action_btn[which_btn], false));
        setdataDrug("by_drug");
      }
      dispatch(setGraphDrugLoadingAnimation(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drugId, disease, action_btn[which_btn], is_personas_selected, personas, dispatch]);

  return (
    <div className="sentiment-volume-drug-graph">
      <div style={{ paddingBottom: "5px" }}>
        <MUItoogleBtn
          action_btn={action_btn}
          setbtn={setbtn}
          which_btn={which_btn}
        />
      </div>
      {load === false ? (
        <div>
          <LineChartVolume
            data={databyId}
            dataRSS={dataRSS}
            period={action_btn[which_btn]}
          />
          <PhaseGraph startDate={startDate} endDate={endDate} drugId={drugId} />
        </div>
      ) : (
        <div className="loading-animation" style={{ height: "275px" }}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
