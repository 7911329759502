import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";

import { DataFilterTable, LoadingAnimation } from "..";
import { setNCTTabAnimation, getNCTTab } from "../../Action";
import { get_filter_key } from "../../utils";

export function NCTTable(props) {
  const { drugId } = props;
  const dispatch = useDispatch();
  const date = useSelector((state) => state.config.date);
  const data = useSelector((state) => state.drug.NCTTab);
  const load = useSelector((state) => state.config.tabNCTLoading);

  const go_to = (name, id) => {
    if (name === "nct") {
      const win = window.open(
        "https://www.clinicaltrials.gov/study/" + id,
        "_blank"
      );
      if (win != null) win.focus();
    }
  };

  useEffect(() => {
    if (drugId !== "-1") {
      dispatch(getNCTTab(drugId));
      dispatch(setNCTTabAnimation(true));
    }
  }, [date, drugId, dispatch]);

  const columns = [
    {
      name: "nct",
      header: "NCT Number",
      flex: 1,
      minWidth: 120,
      render: ({ value, data }) => (
        <span className="title-link" onClick={() => go_to("nct", data.nct)}>{value}</span>
      ),
    },
    {
      name: "disease_name",
      header: "Disease",
      flex: 3,
      minWidth: 150,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("disease_name", data),
      },
    },
    {
      name: "phase",
      header: "Phase",
      flex: 1,
      minWidth: 130,
      maxWidth: 170,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("phase", data),
      },
    },
    {
      name: "status",
      header: "Status",
      flex: 3,
      minWidth: 210,
      maxWidth: 340,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("status", data),
      },
    },
    {
      name: "start_date",
      header: "Start Date",
      flex: 1,
      minWidth: 112,
      maxWidth: window.innerWidth === 1280 ? 110 : 170,
    },
    {
      name: "expected_completion_date",
      header: "Completion Date",
      flex: 1.5,
      minWidth: 120,
      maxWidth: 170,
    },
  ];

  const filterValue = [
    { name: "status", operator: "inlist", type: "select", value: "" },
    { name: "phase", operator: "inlist", type: "select", value: "" },
    {
      name: "disease_name",
      operator: "inlist",
      type: "select",
      value: "",
    },
  ];

  return (
    <div style={{ height: 600, width: "100%",position:"relative" }}>
      {load === false ? (
        <DataFilterTable
          dataSource={data}
          columns={columns}
          enableFiltering={true}
          filterValue={filterValue}
          defaultFilterName={[]}
        />
      ) : (
        <div className="loading-animation" style={{height:"100%"}}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
