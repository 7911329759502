function my_filter_by_categorie(target, data, name_bdd, name_drug) {
  let result = [];
  const array_categorie = target.map((element) => {
    return element[name_bdd];
  });
  for (let i = 0; i < data.length; i++) {
    let drug_target = data[i][name_drug].split(" / ");
    for (let v = 0; v < drug_target.length; v++) {
      const td = array_categorie.findIndex(
        (item) => item.toLowerCase() === drug_target[v].toLowerCase()
      );
      if (td !== -1) {
        result.push(data[i]);
        break;
      }
    }
  }
  return result;
}

function my_filter_by_categorie_with_number(target, data, name_bdd, name_drug) {
  let result = [];
  const array_categorie = target.map((element) => {
    return element[name_bdd];
  });
  for (let i = 0; i < data.length; i++) {
    let drug_target = data[i][name_drug].split(" / ");
    for (let v = 0; v < drug_target.length; v++) {
      const td = array_categorie.findIndex((item) => item === drug_target[v]);
      if (td !== -1) {
        result.push(data[i]);
        break;
      }
    }
  }
  return result;
}

export function filter_area_competitive(data, depth) {
  let r = data.map((el) =>
    Object.fromEntries(
      Object.entries(el).filter(
        ([key]) =>
          key.split("/")[2] ===
          depth.find((element) => element === key.split("/")[2])
      )
    )
  );
  return r;
}

export function filterDataDrug(
  data,
  depth,
  disease,
  type,
  MOADisplay,
  theraFranchise,
  target
) {
  if (data.length === 0) {
    return data;
  }
  let r = data.filter(
    (e) =>
      e.drug_current_phase ===
      depth.find((element) => element === e.drug_current_phase)
  );
  if (theraFranchise.length !== 0) {
    r = my_filter_by_categorie(
      theraFranchise,
      r,
      "therapeutic_franchise_name",
      "therapeutic_franchise_name"
    );
  }
  if (disease.length !== 0) {
    r = my_filter_by_categorie(
      disease,
      r,
      "disease_display_name",
      "disease_display_name"
    );
  }
  if (target.length !== 0) {
    r = my_filter_by_categorie(target, r, "display_name", "target");
  }

  if (MOADisplay.length !== 0) {
    r = r.filter(
      (e) => e.moa === MOADisplay.find((element) => element.moa === e.moa)?.moa
    );
  }
  if (type.length !== 0) {
    r = my_filter_by_categorie(type, r, "drug_type", "drug_type");
  }
  return r;
}

export function setAllFilterTreemap(
  data,
  disease,
  type,
  MOADisplay,
  depth,
  theraFranchise,
  targetDisplay
) {
  let r = data.filter(
    (e) => e.name === depth.find((element) => element === e.name)
  );

  if (theraFranchise.length !== 0) {
    r = r.map((element) => {
      return {
        ...element,
        children: my_filter_by_categorie(
          theraFranchise,
          element.children,
          "therapeutic_franchise_name",
          "therapeutic_franchise_name"
        ),
      };
    });
  }
  if (disease.length !== 0) {
    r = r.map((element) => {
      return {
        ...element,
        children: my_filter_by_categorie(
          disease,
          element.children,
          "disease_display_name",
          "disease_display_name"
        ),
      };
    });
  }
  if (targetDisplay.length !== 0) {
    r = r.map((element) => {
      return {
        ...element,
        children: my_filter_by_categorie(
          targetDisplay,
          element.children,
          "display_name",
          "target"
        ),
      };
    });
  }
  if (MOADisplay.length !== 0) {
    r = r.map((element) => {
      return {
        ...element,
        children: element.children.filter(
          (subElement) =>
            subElement.moa ===
            MOADisplay.find((element) => element === subElement.moa)
        ),
      };
    });
  }
  if (type.length !== 0) {
    r = r.map((element) => {
      return {
        ...element,
        children: my_filter_by_categorie(
          type,
          element.children,
          "drug_type",
          "drug_type"
        ),
      };
    });
  }
  r = r.filter((el) => el.children.length !== 0);

  return r.map((element) => {
    return { ...element, children: element.children.slice(0, 50) };
  });
}

export function filterTabDataDrug(
  data,
  depth,
  disease,
  type,
  MOADisplay,
  theraFranchise,
  targetDisplay
) {
  let r = data;
  if (r.length === 0) {
    return [];
  }
  if (r.length >= 6) {
    if (theraFranchise.length !== 0) {
      r = my_filter_by_categorie_with_number(
        theraFranchise,
        r,
        "therapeutic_franchise_name",
        10
      );
    }
    if (disease.length !== 0) {
      r = my_filter_by_categorie_with_number(
        disease,
        r,
        "disease_display_name",
        6
      );
    }
    if (type.length !== 0) {
      r = my_filter_by_categorie_with_number(
        type,
        r,
        "drug_type",
        8
      );
    }
    /*if (MOADisplay.length !== 0) {
      r = data.filter(function(item) {
        if( MOADisplay.find(element => element.moa === item[9])){
          return item
        }
        return false
      });
    }*/
    if (targetDisplay.length !== 0) {
      r = my_filter_by_categorie_with_number(
        targetDisplay,
        r,
        "display_name",
        13
      );
      /*r = data.filter(function(item) {
        if( targetDisplay.find(element => element.display_name === item[12])){
          
          return item
        }
        return false
      });*/
    }
  }
  return r;
}

export function filternct(data, depth) {
  let r = data.filter(
    (e) => e[5] === depth.find((element) => element === e[5])
  );
  return r;
}

export function filterDataDrugWithCondition(
  data,
  depth,
  disease,
  type,
  MOADisplay,
  theraFranchise,
  targetDisplay
) {
  if (data.length === 1) {
    return data;
  }
  const data_buffer = data.slice(1);
  const data_filter = filterTabDataDrug(
    data_buffer,
    depth,
    disease,
    type,
    MOADisplay,
    theraFranchise,
    targetDisplay
  );
  let clean_data = data_filter.map((element) => {
    return element.slice(0, 5);
  });
  if (data[0]) {
    clean_data.unshift(data[0]);
  }
  return clean_data;
}

export function filterNCTPhaseCondition(data, depth) {
  if (!data) return ["fail"];
  if (data.length === 1) {
    return data;
  }
  const data_buffer = data.slice(1);
  const data_filter = filternct(data_buffer, depth);
  let clean_data = data_filter.map((element) => {
    return element.slice(0, 5);
  });
  if (data[0]) {
    clean_data.unshift(data[0]);
  }
  return clean_data;
}

export function filterNCTPhaseConditionDisease(data) {
  if (data.length === 0 || data.length === 1) {
    return data;
  }
  const data_buffer = data.slice(1);

  const finalData = [
    {
      id: data_buffer[0][7],
      data: [data[0]],
    },
  ];

  for (let i = 0; i < data_buffer.length; i++) {
    const id = data_buffer[i][7];

    let found = false;
    for (let j = 0; j < finalData.length; j++) {
      if (finalData[j].id === id) {
        found = true;
        finalData[j].data.push(data_buffer[i]);
        break;
      }
    }
    if (!found) {
      finalData.push({
        id: id,
        data: [data[0], data_buffer[i]],
      });
    }
  }

  return finalData;
}

export function filterDataDrugWithConditionNoClean(
  data,
  depth,
  disease,
  type,
  MOADisplay,
  theraFranchise,
  targetDisplay
) {
  if (data.length === 1) {
    return data;
  }
  const data_buffer = data.slice(1);
  const data_filter = filterTabDataDrug(
    data_buffer,
    depth,
    disease,
    type,
    MOADisplay,
    theraFranchise,
    targetDisplay
  );
  if (data[0]) {
    data_filter.unshift(data[0]);
  }
  return data_filter;
}

export function convertDate(date) {
  var yyyy = date.getFullYear().toString();
  var mm = (date.getMonth() + 1).toString();
  var dd = date.getDate().toString();

  var mmChars = mm.split("");
  var ddChars = dd.split("");

  return (
    yyyy +
    "-" +
    (mmChars[1] ? mm : "0" + mmChars[0]) +
    "-" +
    (ddChars[1] ? dd : "0" + ddChars[0])
  );
}

export function filter_by_years(array, years) {
  return array.filter((e) => e.year === years);
}

export function filter_by_theraFranchise(disease, theraFranchise) {
  if (theraFranchise.length !== 0) {
    return disease.filter((e) =>
      e.therapeutic_franchise_name.split(" / ").some((r) =>
        theraFranchise
          .map((element) => {
            return element.therapeutic_franchise_name;
          })
          .includes(r)
      )
    );
  }
  return disease;
}

export function f_milter_by_disease(MOA, diseasedeXavier) {
  if (diseasedeXavier.length !== 0) {
    return unique_MOA(
      MOA.filter((e) =>
        e.disease_display_name.includes(
          diseasedeXavier.map((element) => {
            return element.disease_display_name;
          })
        )
      )
    );
  }
  return unique_MOA(MOA);
}

export function f_milter_by_disease_aoe_drug(target, drug) {
  let target_in_drug = drug
    .map(function (el) {
      return el["target"];
    })
    .filter(function (item, pos, self) {
      return self.indexOf(item) === pos;
    });

  if (drug.length !== 0) {
    target = target.map((element) => {
      if (target_in_drug.includes(element.display_name) === true)
        return element;
      return null;
    });
  }
  target = target.filter(Boolean);
  return target;
}

export function filter_by_disease(MOA, diseasedeXavier, need_unique = 1) {
  if (diseasedeXavier.length !== 0) {
    return MOA.filter((e) =>
      e.disease_display_name.split(" / ").some((r) =>
        diseasedeXavier
          .map((element) => {
            return element.disease_display_name;
          })
          .includes(r)
      )
    );
  }
  return MOA;
}

export function unique_MOA(array) {
  array = array.filter(
    (value, index, self) => index === self.findIndex((t) => t.moa === value.moa)
  );
  return array;
}

export function unique_Drug(array) {
  array = array.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.id_drug === value.id_drug)
  );
  return array;
}

export function get_filter_key(name, data) {
  const drug_filter = data.reduce((element, p) => {
    if (element.filter((c) => c.id === p[name]).length) {
      return element;
    }
    element.push({
      id: p[name],
      label: data[p[name]] || p[name],
    });
    return element;
  }, []);
  return drug_filter;
}

export function filter_profession_by_profession_group(profesionData, proGroupSelected) {
  const listIdProGroup = [];
  proGroupSelected.map((e) => listIdProGroup.push(e.id_profession_group))

  if (proGroupSelected.length !== 0){
    return profesionData.filter((e) =>
      listIdProGroup.includes(e.id_profession_group)
    )
  }
  else {
    return profesionData;
  };
}