import React, {useEffect} from "react";
import { useDispatch } from "react-redux";
import { set_dolprofile_graph_year } from "../../../../Action";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export function SimpleLineChartVolume(props) {
  const { data } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(set_dolprofile_graph_year(0));
  }, [data, dispatch]);

  const handleChange = (event) => {
    if(event){
      dispatch(set_dolprofile_graph_year(event.activeLabel));
    };
  };
  
  return (
    <ResponsiveContainer width="100%" height={160}>
      <LineChart
        data={data}
        margin={{
          top: 20,
          right: 25,
          left: 15,
          bottom: 0,
        }}
        onClick={handleChange}
      >
        <CartesianGrid strokeDasharray="3 3"/>
        <XAxis dataKey="name" height={40}/>
        <YAxis width={30}  domain={[0, 5]}/>
        <Tooltip />
        <Line type="monotone" dot={true} dataKey="user" stroke="#F06739" strokeWidth={2} />
      </LineChart>
    </ResponsiveContainer>
  );
}
