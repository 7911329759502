import React from "react";
import { useNavigate } from "react-router-dom";

export function LrxCardDisplay(props) {
  const { go_to, tipsCard = false} = props;
  const navigate = useNavigate();

  const navigate_dashboard = (id) => {
    if (id === "drug") {
      navigate(`/drug/-1`);
    }
    if (id === "company") {
      navigate(`/company/-1`);
    }
    if (id === "congress") {
      navigate(`/congress`);
    }
    if (id === "competitive") {
      navigate(`/competitive`);
    }
    if (id === "trending") {
      navigate(`/trending`);
    }
    if (id === "dol") {
      navigate(`/dol/ranking`);
    }
  };
  return (
    <div onClick={() => navigate_dashboard(go_to)} className= { tipsCard ? "tips-card" : "card"}>
      <div className="card-details">{props.children}</div>
      { tipsCard ? null : <button className="card-button">More info</button> }
    </div>
  );
}
