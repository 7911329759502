import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { DataFilterTable, LoadingAnimation, ChartIcon } from "../../components";
import { get_filter_key } from "../../utils";
import {
  getPublications,
  setTabshareAnimation,
  setBigTabshareAnimation,
} from "../../Action";

import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";

export function PublicationTable(props) {
  const { drugId, if_history, which_btn, action_btn } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const date = useSelector((state) => state.config.date);
  const data = useSelector((state) => state.drug.publications);
  const batteryIcons = ["Battery25Icon","Battery50Icon","Battery75Icon","Battery100Icon"]
  const id_drug_competive = useSelector(
    (state) => state.competitive.drugChooseForCompetitive
  );
  const data_history = useSelector((state) => state.drug.Allpublications);
  const load = useSelector((state) =>
    !if_history ? state.config.tabShareLoading : state.config.BigtabShareLoading
  );

  const go_to = (name, id) => {
    if (name === "drug") {
      navigate(`/drug/${id}`);
    }
    if (name === "link") {
      const win = window.open(id, "_blank");
      if (win != null) win.focus();
    }
  };

  useEffect(() => {
    if (drugId === -1) {
      dispatch(getPublications(null, 0, id_drug_competive));
      dispatch(setBigTabshareAnimation(true));
    } else {
      if (!if_history) {
        dispatch(getPublications(date, drugId, [], action_btn[which_btn]));
        dispatch(setTabshareAnimation(true));
      } else {
        dispatch(getPublications(null, drugId, []));
        dispatch(setBigTabshareAnimation(true));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ dispatch, date, drugId, if_history, id_drug_competive, action_btn[which_btn] ]);
  const sumCount = data.reduce((sum, item) => sum + item.count, 0);
  const columns = [  
    {
      name: "count",
      header: "Count",
      type: 'number',
      defaultFlex: 1,
      minWidth: 70,
      maxWidth: 85,
      onRender: (cellProps, { data }) => {
        cellProps.style.display = "flex";
        cellProps.style.textAlign = 'center';
      },
      render: ({ value }) => {
        const percentage = (value*100)/sumCount;
        return (
          <div className="all-drug-trending-table__trendScore-and-SentimentChange-cells">
            <ChartIcon logo={ percentage >= 50 ? batteryIcons[3] 
                              : (percentage < 50 && percentage >= 25 ? batteryIcons[2] 
                              : (percentage < 25 && percentage >= 10 ? batteryIcons[1] : batteryIcons[0]))} />
            <p>{value}</p>
          </div>
        );
      }
    },
    {
      name: "source",
      header: "Source",
      maxWidth: 250,
      minWidth: 200,
      defaultFlex: 1,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("source", data),
      },
    },
    {
      name: "source_type",
      header: "Source Type",
      maxWidth: 200,
      minWidth: 170,
      defaultFlex: 1,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("source_type", data),
      },
    },
    {
      name: "titles",
      header: "Title",
      defaultFlex: 1,
      render: ({ value, data }) => (
        <span className="title-link" onClick={() => go_to("link", data.link)}>{value}</span>
      ),
    },
  
  ];

  const columnsAll = [
    {
      name: "created_at",
      header: "First Time Shared",
      maxWidth: 165,
      minWidth: 165,
      defaultFlex: 1,
    },
    {
      name: "drug_true_name",
      header: "Drug",
      defaultFlex: 1,
      minWidth: 95,
      maxWidth: 95,
      render: ({ value, data }) => (
        <span className="title-link" onClick={() => go_to("drug", data.id_drug)}>{value}</span>
      ),
    },
    {
      name: "source",
      header: "Source",
      minWidth: 150,
      maxWidth: 380,
      defaultFlex: 1,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("source", data_history),
      },
    },
    {
      name: "titles",
      header: "Title",
      defaultFlex: 1,
      minWidth: 100,
      render: ({ value, data }) => (
        <span className="title-link" onClick={() => go_to("link", data.link)}>{value}</span>
      ),
    },
    {
      name: "count",
      header: "Count",
      maxWidth: 90,
      minWidth: 90,
      defaultFlex: 1,
      type: "number",
      onRender: (cellProps, { data }) => {
        cellProps.style.display = "flex";
        cellProps.style.textAlign = 'center';
      },
    },
  ];

  const filterValue = [
    { name: "titles", operator: "contains", type: "string", value: "" },
    { name: "source_type", operator: "inlist", type: "select", value: "" },
    { name: "source", operator: "inlist", type: "select", value: "" },
  ];

  return (
    <div style={{ position: "relative", height: 598, width: "100%" }}>
      {load === false ? (
        <DataFilterTable
          dataSource={!if_history ? data : data_history}
          columns={!if_history ? columns : columnsAll}
          filterValue={filterValue}
          defaultFilterName={[]}
        />
      ) : (
        <div className="loading-animation" style={{height: "100%"}}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
