import React from "react";
import { useNavigate } from "react-router-dom";

import { MuiCard } from "../../components";

import "./style/drug_card.css";

export function DrugCard(props) {
  const { content } = props;
  const navigate = useNavigate();

  const selectThisDrug = (content) => {
    navigate(`/drug/${content.id_drug}`);
  };

  return (
    <div className="drug_card-content" onClick={() => selectThisDrug(content)}>
      <MuiCard mui_class={"drug_card-mui-card"}>
        <div className="drug_card-title-drug"> {content.drug_true_name} </div>
        <div>
          {" "}
          <span className="drug_card-title-spec">company name: </span>
          {content.company_display_name}{" "}
        </div>
        <div>
          {" "}
          <span className="drug_card-title-spec">status: </span>
          {content.drug_current_phase}{" "}
        </div>
        <div>
          {" "}
          <span className="drug_card-title-spec">therapeutic franchise: </span>
          {content.therapeutic_franchise_name}{" "}
        </div>
      </MuiCard>
    </div>
  );
}
