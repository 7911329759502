import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { set_dolprofile_graph_year } from "../../../../../Action";

function Chip(props) {
  const { label, action } = props;
  return (
    <div className="chip">
      <div className="chip__content">{label}</div>
      <button className="chip__button" onClick={action}>
        <div className="chip__button-line chip__button-line--first"></div>
        <div className="chip__button-line chip__button-line--second"></div>
      </button>
    </div>
  );
}

export function LrxDolYearChips() {
  const dispatch = useDispatch();
  const year = useSelector((state) => state.dol.dolprofile_graph_year);

  return(
    <span>
      {year !== 0 ? (
        <Chip
          key={0}
          label={year === 0 ? null : year}
          action={() => dispatch(set_dolprofile_graph_year(0))}
        />
      ) : null }
    </span>
  )
}