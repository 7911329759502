import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { removeMyDrugType, setMyDrugType } from "../../../Action";
import { LrxMenuInput } from "../../../components";
import { getParamId } from "../../../utils";

import { Tooltip } from "@mui/material";
import { IconButton } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";


export function BtnDrugTypeDisplay() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const my_option = useSelector((state) => state.diease.nameDrugType);
  const my_type = useSelector((state) => state.diease.drugTypeDisplay);

  const [searchValue, setSearchValue] = useState("");

  const updateURLParamsRef = useRef();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  updateURLParamsRef.current = (paramName, paramValue) => {
    const params = new URLSearchParams(window.location.search);
    params.set(paramName, paramValue);
    const nouvelleURL = `${window.location.pathname}?${params}`;
    navigate(nouvelleURL);
  };

  const paramtype = getParamId("type", params);

  useEffect(() => {
    const id_t = paramtype.split(";");
    for (let i = 0; i < id_t.length; ++i) {
      const tId = parseInt(id_t[i]);
      if (tId) {
        const c = my_option.filter((item) => item.id_drug_type === tId);
        if (c) {
          dispatch(setMyDrugType(c[0]));
        }
      }
    }
  }, [dispatch, my_option, paramtype]);

  useEffect(() => {
    updateURL();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, my_type]);

  const updateURL = (event) => {
    let disease_sent = my_type
      ? my_type
          .map(function (el) {
            return el["id_drug_type"];
          })
          .join(";")
      : [];
    updateURLParamsRef.current("type", disease_sent);
  };

  const handleChange = (event) => {
    const itemName = event.target.name;
    const selectedItem = my_option.find((item) => item.drug_type === itemName);

    if (my_type.includes(selectedItem)) {
      dispatch(removeMyDrugType(selectedItem.id_drug_type));
    } else {
      dispatch(setMyDrugType(selectedItem));
    }
  };

  const clearFilter = () => {
    my_type.forEach((item) => {
      dispatch(removeMyDrugType(item.id_drug_type));
    });
  };

  const drugTypeListFiltered = my_option.filter((user) =>
    user.drug_type.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <div className="control-pannel-search-entity">
      <LrxMenuInput
        action_input={setSearchValue}
        name={"Search for a drug type"}
      />
      <h3 className="control-pannel-search-entity--nb-select-filter">
        {" "}
        {drugTypeListFiltered.length === 0
          ? "Drug type not found !"
          : drugTypeListFiltered.length + " drug types available"}{" "}
      </h3>
      <h3 className="control-pannel-search-entity--nb-select-filter">
        {" "}
        {my_type.length === 0
          ? null
          : my_type.length + " drug type(s) selected"}{" "}
      </h3>
      {my_type.length === 0 ? null : (
        <Tooltip title="Delete">
          <IconButton onClick={clearFilter}>
            <RestartAltIcon />
          </IconButton>
        </Tooltip>
      )}
      <div className="control-pannel-search-entity__list-object-filter">
        <div class="control-pannel-search-entity__list-object-filter--form">
          <form>
            {my_type.map((item, index) => (
              <div key={index}>
                <label>
                  <input
                    type="checkbox"
                    onChange={handleChange}
                    name={item.drug_type}
                    checked={my_type.includes(item)}
                  ></input>
                  <span>{item.drug_type}</span>
                </label>
              </div>
            ))}
          </form>
        </div>
        <div class="control-pannel-search-entity__list-object-filter--form">
          <form>
            {drugTypeListFiltered.map((item, index) =>
              my_type.includes(item) ? null : (
                <div key={index}>
                  <label>
                    <input
                      type="checkbox"
                      onChange={handleChange}
                      name={item.drug_type}
                      checked={my_type.includes(item)}
                    ></input>
                    <span>{item.drug_type}</span>
                  </label>
                </div>
              )
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
