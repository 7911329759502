import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { LoadingAnimation } from "../../components";
import {
  filterNCTPhaseCondition,
  filterNCTPhaseConditionDisease,
} from "../../utils";
import { TimeLineChart } from "../../components";
import { getNCTDiseaseGraph, setNCTDiseaseGraphAnimation } from "../../Action";

export function NCTDiseaseGraph(props) {
  const dispatch = useDispatch();
  const load = useSelector((state) => state.config.graphNCTDiseaseLoading);
  const depth = useSelector((state) => state.diease.statusDrugDisplay);
  const disease = useSelector((state) => state.diease.diseaseDisplay);
  const target = useSelector((state) => state.diease.drugTargetDisplay);
  const dataNCT = useSelector((state) => state.drug.NCTgraph);

  dataNCT.map((l, index) => {
    if (index !== 0) {
      l[3] = new Date(l[3]);
      l[4] = new Date(l[4]);
    }
    return [l[3], l[4]];
  });

  const filterData = dataNCT ? filterNCTPhaseConditionDisease(dataNCT) : [];

  let minStartDate = null;
  let maxEndDate = null;
  for (let i = 1; i < dataNCT.length; i++) {
    const event = dataNCT[i];
    const startDate = event[3]; // Date de début (index 2)
    const endDate = event[4]; // Date de fin (index 3)
    if (!minStartDate || startDate < minStartDate) {
      minStartDate = startDate;
    }
    if (!maxEndDate || endDate > maxEndDate) {
      maxEndDate = endDate;
    }
  }

  useEffect(() => {
    dispatch(getNCTDiseaseGraph(disease, target));
    dispatch(setNCTDiseaseGraphAnimation(true));
  }, [disease, target, dispatch]);

  return (
    <div>
      <div className="nct-disease-competitive-graph">
        {load === false ? (
          filterData.map((el) => (
            filterNCTPhaseCondition(el.data, depth).length === 1 ? null :
            <div>
              <span className="nct-disease-competitive-graph--titre">{el.id}</span>
              <TimeLineChart
                dataAD={el.data ? filterNCTPhaseCondition(el.data, depth) : []}
                content={"treatment"}
                infoDrug={dataNCT}
                TimeLineStart={minStartDate}
                TimeLineEnd={maxEndDate}
              />
            </div>
          ))
        ) : (
          <div className="loading-animation" style={{ height: "300px" }}>
            <LoadingAnimation type={"circular"} />
          </div>
        )}
      </div>
    </div>
  );
}
