import React from "react";
import { useDispatch } from "react-redux";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  BarChart,
  Bar,
} from "recharts";

import { interpolateColors, interpolateColors10 } from "../../../../utils";
import { setDateRange } from "../../../../Action";

import "../style/area_chart.css";

export function AreaBarChartVolume(props) {
  const { data, display_all, is_percentage } = props;
  const dispatch = useDispatch();

  function change_date(e) {
    if (e) {
      dispatch(setDateRange(new Date(e.activeLabel)));
    }
  }

  const set_color = (size) => {
    return !display_all ? interpolateColors(size) : interpolateColors10(size);
  };

  let tab_color = [];
  if (data && data[0]) {
    tab_color = set_color(Object.keys(data[0]).length);
  }

  const top10LegendPayload = data && data[0] ? Object.keys(data[0]) : [];
  const legend = top10LegendPayload
    .slice(1, 11)
    .map((entry) => ({ value: entry, type: "line", id: entry }));

  const CustomLegend = ({ payload }) => (
    <ul className="" style={{ listStyle: "none", paddingLeft: 50 }}>
      {payload.map((entry, index) => (
        <li
          key={`item-${index}`}
          style={{ display: "inline-block", marginRight: "10px" }}
        >
          <div className="container">
            <div
              className="carre"
              style={{ backgroundColor: tab_color[index] }}
            >
              {" "}
            </div>
            <div style={{ paddingLeft: "5px" }}>{entry.value}</div>
          </div>
        </li>
      ))}
      {!display_all ? null : (
        <li
          key={`item-last`}
          style={{ display: "inline-block", marginRight: "10px" }}
        >
          <div className="container">
            <div className="carre" style={{ backgroundColor: "#cfcfcf" }}>
              {" "}
            </div>
            <div style={{ paddingLeft: "5px" }}>other</div>
          </div>
        </li>
      )}
    </ul>
  );

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        data={data && data[0] ? data.slice(1) : []}
        onClick={change_date}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        stackOffset={!is_percentage ? "expand": "none"}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="week" />
        <YAxis />
        <Tooltip />
        <Legend height={36} payload={legend} content={<CustomLegend />} />
        {data && data[0]
          ? Object.keys(data[0]).map((keyName, i) =>
              i !== 0 ? (
                <Bar dataKey={keyName} stackId="a" fill={tab_color[i - 1]} />
              ) : null
            )
          : null}
      </BarChart>
    </ResponsiveContainer>
  );
}
