import React from "react";

import "./style/global_animation.css";

export function GlobalLrxAnimation() {
  return (
    <div class="spinnerContainer">
      <div class="spinner"></div>
      <div class="loader">
        <p>loading</p>
        <div class="words">
          <span class="word">Targets</span>
          <span class="word">Diseases</span>
          <span class="word">Therapeutics</span>
          <span class="word">hashtags</span>
        </div>
      </div>
    </div>
  );
}