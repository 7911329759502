import React from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate } from "react-router-dom";
import { ChartIcon } from "../../../../components";

   const  active_tabs = {
      border: '1px solid #D3D3D3',
      borderRadius: '5px',
      background:'#f16739',
      minHeight:'40px',
      height:'40px',
      textTransform: "none",
      z_index:999,
      position:'relative',
     '&.Mui-selected' : {
      color: '#FFFFFF',
     }
    }
     const  unactive_tabs= {
      border: '1px solid #D3D3D3',
      borderRadius: '5px',
      background:'#FFFFFF',
      minHeight:'40px',
      height:'40px',
      textTransform: "none",
    }

  function LinkTab(props) {
    return (
      <Tab
        {...props}
      />
    );
  }

export function MuiTabsNavigation (props){
  const {setValue, type_enum, type_of_content, is_premium_user} = props
  const navigate = useNavigate();

  const updateURLParams = (paramName, paramValue) => {
    const params = new URLSearchParams(window.location.search);
    params.set(paramName, paramValue);
    const nouvelleURL = `${window.location.pathname}?${params.toString()}`;
    navigate(nouvelleURL);
  };

  const handleChange = (event, newValue) => {
    event.preventDefault()
    setValue(newValue);
    updateURLParams('tabs', newValue);
  };

    return (
      <div>
          <Tabs 
               sx={{
                textTransform: "none",
                float : 'right',
                minHeight:'40px',
                height:'40px'
              }}
            value={type_of_content}
            onChange={handleChange}
          >
            {type_enum.map((type, index)  => (
              <LinkTab 
                key={index}
                icon={
                  is_premium_user ? 
                    null
                  : (
                    type === "Trending Drug" || type === "Trending Ticker" || type === "Alerts" || type === "Social" ?
                      <div style={{width:"17px", marginLeft:"6px"}}>
                        <ChartIcon logo={"LockedIcon"}/>
                      </div>
                    : null
                  )
                }
                iconPosition="end" 
                label={type}
                sx={type_of_content === index ? active_tabs : unactive_tabs} 
              />
          ))}
          </Tabs>
      </div>
    );
}