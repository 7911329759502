import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {Root} from "./App/config"


const root = ReactDOM.createRoot(document.querySelector("#root"));
root.render(
  <Root/>
);

reportWebVitals();
