import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import SnackbarProvider from "react-simple-snackbar";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { configureStore } from "../config/configureStore";
//import { ErrorBoundary } from "./Error_handler";
import App from "./../App";

export function Root() {
  const { store, persistor } = configureStore();
  const theme = createTheme();

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <SnackbarProvider>
              <React.StrictMode>
                <App />
              </React.StrictMode>
            </SnackbarProvider>
          </Router>
        </PersistGate>
      </ThemeProvider>
    </Provider>
  );
}
