import React from "react";

import {Collapse} from  '@mui/material';


export function MuiCollapse(props){
  const {is_open} = props 
  return (
    <Collapse in={is_open} timeout="auto" unmountOnExit>
    {props.children}
    </Collapse>
  );
}