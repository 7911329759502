import React from "react";
import { useSelector } from "react-redux";

export function RankBoxCompany() {
  const info = useSelector((state) => state.company.companyInfo);
  const nb = info.rank ? info.rank : "#";
  return (
    <div>
      <div className="info-pannel-content-rank">
        <div className="info-pannel-rank-number"> {nb}</div>
        <div> Rank </div>
      </div>
    </div>
  );
}
