import React from "react";
import { LrxCardDisplay } from "../../components";
import therapeutics from "../../../Asset/therapeuticsdashboard.png";
import company from "../../../Asset/companydashboard.png";
import competitive from "../../../Asset/competitivedashboard.png";
import congress from "../../../Asset/congressdashboard.png";
import trending from "../../../Asset/trendingdashboard.png";
import dolimage from "../../../Asset/doldashboard.png"
import tips from "../../../Asset/tipsdashboard.png";
import new1 from "../../../Asset/new.png";

export function ExplainVizPannel() {
  const titlecard = [
    {
      name: "Dashboard DOL",
      text: "Search and segment your digital audience with detailed Personas",
      image: dolimage,
      go_to : 'dol'
    },
    {
      name: "Dashboard Therapeutics",
      text: "Track the social media hype surrounding therapeutics and the companies behind them with our sentiment analysis tools",
      image: therapeutics,
      go_to : 'drug'
    },
    {
      name: "Dashboard Competitive Intelligence",
      text: "Review the competition in an indication with our comprehensive platform, offering data and analytics on therapeutics competing within a specific condition, using social media insights, enriched news, and clinical trial updates",
      image: competitive,
      go_to : 'competitive'
    },
    {
      name: "Dashboard Trending",
      text: "Get the full picture on therapeutics with access to data on biological targets, drug types, and ownership, plus social media engagement, clinical trial progress by condition, and sortable press releases from stakeholders",
      image: trending,
      go_to : 'trending'
    },
    {
      name: "DashBoard Company",
      text: "Get real-time updates on your favorite companies' portfolio and pipeline, stay informed about ongoing clinical trials, and track social media. Discover companies that match your search criteria for disease, technology, and phase of development",
      image: company,
      go_to : 'company'
    },
    {
      name: "Dashboard Congress",
      text: "Keep up with the latest on therapeutics by tracking online information shared by attendees of medical congresses",
      image: congress,
      go_to : 'congress'
    },
  ];

  return (
    <div className="explain-viz-pannel">
      <LrxCardDisplay tipsCard = {true}>
        {" "}
        <h2 className="text-title">{"Quick tips to use doltracker"}</h2>{" "}
        <p className="text-body">
          Doltracker uses AI and Natural Language Processing to gather and
          analyze multiple information sources. With its wealth of data and
          analytics, Doltracker offers various dashboards to organize it all :
        </p>
        <p className="text-body">
        Dashboards : <br />
          <ul>
          <li>Trending : capture Therapeutics buzz in social media</li>
          <li>Therapeutics : to get the full picture of a therapeutic</li>
          <li>Company : to stay inform on company’s portfolio and R&D pipeline</li>
          <li>Congress : to track social media during medical conference</li>
          <li>Competitive intelligence : to review the competition in a condition</li>
          </ul>
          Focus your search by type of information by
          clicking on TABS
        </p>
        <img className="text-image2" src={tips} alt="tabs" />
      </LrxCardDisplay>
      {titlecard.map((el) => (
        <LrxCardDisplay go_to={el.go_to}>
          {" "}
          {el.name === "Dashboard DOL" ?
            <div className="new-icon-container">
              <img className="new-icon" src={new1} alt='new feature' />  
            </div>
            : null
          }
          <img className="text-image" src={el.image} alt="dashboard" />
          <h2 className="text-title">{el.name}</h2>{" "}
          <p className="text-body">{el.text}</p>
        </LrxCardDisplay>
      ))}
    </div>
  );
}
