import React from "react";
import { useDispatch } from "react-redux";
import { setCongressName } from "../../Action";

import { MuiCard } from "../../components";

import "./style/congress_card.css";

export function AllCongressCard(props) {
  const { content, setSearchValue } = props;
  const dispatch = useDispatch();

  const selectThisCongress = (content) => {
    setSearchValue("");
    dispatch(setCongressName(content));
  };

  return (
    <div
      className="congress_card-content"
      onClick={() => selectThisCongress(content)}
    >
      <MuiCard mui_class={"congress_card-mui-card"}>
        <div className="company-card__mui-card__title">
        <div className="company-card__mui-card__title--company">
            {" "}
            {content.conference_name}
          </div>
          <div className="company-card__mui-card__title--logo-container">
            {" "}
            <img
              className="company-card__mui-card__title--logo-image"
              src={"https://logo.clearbit.com/https:/" + content.website}
              alt=""
            ></img>
          </div>
        </div>
        <div>
          {" "}
          <span className="congress_card-title-spec">
            Therapeutics Franchises :{" "}
          </span>{" "}
          {content.therapeutic_franchise_name}
        </div>
        <div>
          {" "}
          <span className="congress_card-title-spec">hastag : </span>#
          {content.acronym} {content.year}
        </div>
        {/*<div> <span className="congress_card-title-spec">Country :{' '} </span>{content.congress_country}</div>
                <div> <span className="congress_card-title-spec">Number of Tweets :{' '} </span> <span className="congress_card-important-info">{content.volume} tweets </span> </div>
    <div> <span className="congress_card-title-spec">Number of Drugs :{' '} </span> <span className="congress_card-important-info">{content.count} drugs </span> </div>*/}
      </MuiCard>
    </div>
  );
}
