import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LrxMenuInput } from "../../../components";
import { set_filter_location, remove_location } from "../../../Action";

import { Tooltip } from "@mui/material";
import { IconButton } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

export function BtnLocationDisplay() {
  const LocationName = useSelector((state) => state.diease.location_name)
  const myLocation = useSelector((state) => state.diease.location_user)
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();

  const locationListFiltered = LocationName.filter((user) =>
    user.location.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleChange = (event) => {
    const itemName = event.target.name;
    const selectedItem = LocationName.find(
      (item) => item.location === itemName
    );

    if (myLocation.includes(selectedItem)) {
      dispatch(remove_location(selectedItem.location));
    } else {
      dispatch(set_filter_location(selectedItem));
    }
  };

  const clearFilter = () => {
    myLocation.forEach((item) => {
      dispatch(remove_location(item.location));
    });
  };

  return (
    <div className="control-pannel-search-entity">
      <LrxMenuInput
        action_input={setSearchValue}
        name={"Search for a location"}
      />
      <h3 className="control-pannel-search-entity--nb-select-filter">
        {" "}
        {locationListFiltered.length === 0
          ? "Location not found !"
          : locationListFiltered.length + " location(s) available"}{" "}
      </h3>
      <h3 className="control-pannel-search-entity--nb-select-filter">
        {" "}
        {myLocation.length === 0
          ? null
          : myLocation.length + " location(s) selected"}{" "}
      </h3>
      {myLocation.length === 0 ? null : (
        <Tooltip title="Delete">
          <IconButton onClick={clearFilter}>
            <RestartAltIcon />
          </IconButton>
        </Tooltip>
      )}
      <div className="control-pannel-search-entity__list-object-filter">
        <div class="control-pannel-search-entity__list-object-filter--form">
          <form>
            {myLocation.map((item, index) => (
              <div key={index}>
                <label>
                  <input
                    type="checkbox"
                    onChange={handleChange}
                    name={item.location}
                    checked={myLocation.includes(item)}
                  ></input>
                  <span>{item.location}</span>
                </label>
              </div>
            ))}
          </form>
        </div>
        <div class="control-pannel-search-entity__list-object-filter--form">
          <form>
            {locationListFiltered.map((item, index) =>
              myLocation.includes(item) ? null : (
                <div key={index}>
                  <label>
                    <input
                      type="checkbox"
                      onChange={handleChange}
                      name={item.location}
                      checked={myLocation.includes(item)}
                    ></input>
                    <span>{item.location}</span>
                  </label>
                </div>
              )
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
