import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { LoadingAnimation } from "../../components";
import { getPhaseGraph, setGraphPhaseDrugAnimation } from "../../Action";
import Chart from "react-google-charts";

export function PhaseGraph(props) {
  const { drugId, startDate, endDate } = props;
  const dispatch = useDispatch();
  const load = useSelector((state) => state.config.graphPhaseDrugLoading);
  const dataAD = useSelector((state) => state.drug.graphPhaseTimeline);
  const [zoomStartDate] = useState(new Date(startDate));
  const [zoomEndDate] = useState(new Date(endDate));

  dataAD?.map((l, index) => {
    if (index !== 0) {
      l[3] = new Date(l[3]);
      l[4] = new Date(l[4]);
    }
    return [l[2], l[3]];
  });

  const adjustedData = dataAD
    ?.map((item, index) => {
      if (index === 0) {
        return item;
      }
      const startDate = new Date(item[3]);
      const endDate = new Date(item[4]);
      if (endDate < zoomStartDate || startDate > zoomEndDate) {
        return null;
      }
      const adjustedStartDate = Math.max(startDate, zoomStartDate);
      const adjustedEndDate = Math.min(endDate, zoomEndDate);
      if (adjustedEndDate > zoomEndDate) {
        item[4] = new Date(zoomEndDate);
      }
      item[3] = new Date(adjustedStartDate);
      item[4] = new Date(adjustedEndDate);
      return item;
    })
    .filter(Boolean);

  const option = {
    colors: [
      "#db3779",
      "#8542f4",
      "#18a0fb",
      "#04a7a2",
      "#f4a000",
      "#f0a8c5",
      "#b995f5",
      "#8ecffa",
      "#f5d18e",
    ],
    timeline: {
      barLabelStyle: { fontSize: 9 },
      showRowLabels: false,
    },
    hAxis: {
      textPosition: "none", // This will hide the horizontal axis
    },
  };

  useEffect(() => {
    dispatch(getPhaseGraph(drugId));
    dispatch(setGraphPhaseDrugAnimation(true));
  }, [drugId, dispatch]);

  return (
    <div className="custom-timeline-without-date">
      {load === false ? (
        <div
          style={{
            paddingLeft: "80px",
          }}
        >
          <Chart
            width={"99.5%"}
            height={100}
            chartType="Timeline"
            data={adjustedData}
            options={option}
            rootProps={{ "data-testid": "2" }}
          />
        </div>
      ) : (
        <div className="loading-animation" style={{height:"30.75px"}}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
