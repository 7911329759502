import React from "react";

import { ChartIcon } from "../../../../components";

export function LrxMenuInput(props) {
  const { action_input, name, sx } = props;
  return (
    <div className="lrx-menu-input" style={sx}>
      <ChartIcon logo={"SearchIcon"} />
      <input
        placeholder={name}
        type="search"
        className="lrx-menu-input--search-bar"
        onChange={(e) => action_input(e.target.value)}
      />
    </div>
  );
}
