import React from 'react';

/*import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';*/

import {ThreePointAnimation} from '../lrx_loader';
import {CardAnimation} from '../lrx_loader';


export function LoadingAnimation(props){
    const {type} = props

    if (type === 'linear')
    return (
        <ThreePointAnimation/>
    );
    if (type === 'circular')
    return (
        <ThreePointAnimation/>
    );
    if (type === 'card')
    return (
        <CardAnimation/>
    );
    return null
}


