import { format } from "date-fns";
import { api } from "../config";

export const getTTCompanyData = (date, period) => async (dispatch) => {
  try {
    const { data } = await api.get("data/volume/company/trend", {
      params: {
        date: format(new Date(date), "yyyy-MM-dd"),
        period: period,
      },
    });
    dispatch({
      type: "GET_ALL_TT_DATA",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_COMPANY",
      value: err,
    });
  }
  dispatch({
    type: "SET_DATA_TT_LOADING_ANIMATION",
    value: false,
  });
};

export const getDataByCompany = (name, period) => async (dispatch) => {
  try {
    const { data } = await api.get(`data/volume/company/${name}`, {
      params: {
        period: period,
      },
    });
    dispatch({
      type: "GET_DATA_BY_COMPANY",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_COMPANY",
      value: err,
    });
  }
  dispatch({
    type: "SET_GRAPH_LOADING_ANIMATION",
    value: false,
  });
};

export const getInfoCompany = (name) => async (dispatch) => {
  try {
    const { data } = await api.get(`data/company/${name}`);
    dispatch({
      type: "GET_INFO_BY_COMPANY",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_COMPANY",
      value: err,
    });
  }
  dispatch({
    type: "SET_INFO_LOADING_ANIMATION",
    value: false,
  });
};

export const getAllCompany = () => async (dispatch) => {
  try {
    const { data } = await api.get("data/company");
    dispatch({
      type: "GET_ALL_COMPANY",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_COMPANY",
      value: err,
    });
  }
};

export const getDataTreeMapTT =
  (date, nb_company, metric1, metric2, period = "week") =>
  async (dispatch) => {
    try {
      const { data } = await api.get("data/volume/company/treemap", {
        params: {
          date: format(new Date(date), "yyyy-MM-dd"),
          nb: nb_company,
          metric1: metric1,
          metric2: metric2,
          period: period,
        },
      });
      dispatch({
        type: "GET_DATA_TREEMAP_TT",
        value: data,
      });
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_COMPANY",
        value: err,
      });
    }
    dispatch({
      type: "SET_TMTT_LOADING_ANIMATION",
      value: false,
    });
  };

export const setNumberCompanyDisplay =
  (nb_drug_display) => async (dispatch) => {
    dispatch({
      type: "SET_NB_COMPANY_DISPLAY",
      value: nb_drug_display,
    });
  };

export const setFilterTT = (phase) => async (dispatch) => {
  dispatch({
    type: "SET_TREEMAP_TT_DEPTH",
    value: phase,
  });
};

export const getAllCompanyDrugTab = (filtre) => async (dispatch) => {
  try {
    const { data } = await api.get(`data/company/${filtre}/drug/table`, {
      params: {
        filtre: filtre,
      },
    });
    dispatch({
      type: "GET_ALL_COMPANY_DRUG_TABLE",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DRUG",
      value: err,
    });
  }
  dispatch({
    type: "SET_TAB_COMPANY_DRUG_LOADING_ANIMATION",
    value: false,
  });
};

export const getAllCompanyDrugGraph = (filtre) => async (dispatch) => {
  try {
    const { data } = await api.get(`data/company/${filtre}/drug`, {
      params: {
        filtre: filtre,
      },
    });
    dispatch({
      type: "GET_ALL_COMPANY_DRUG",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DRUG",
      value: err,
    });
  }
  dispatch({
    type: "SET_GRAPH_DRUG_COMPANY_LOADING_ANIMATION",
    value: false,
  });
};

export const getCatalystCompanyTab = (id, date) => async (dispatch) => {
  try {
    const { data } = await api.get(`data/company/${id}/catalyst/table`, {
      params: {
        filtre: id,
        date: format(new Date(date), "yyyy-MM-dd"),
      },
    });
    dispatch({
      type: "GET_CATALYST_COMPANY_TAB",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DRUG",
      value: err,
    });
  }
  dispatch({
    type: "SET_COMPANY_CATALYST_TAB_LOADING_ANIMATION",
    value: false,
  });
};

export const getNCTCompanyGraph = (companyId) => async (dispatch) => {
  try {
    const { data } = await api.get(`data/NCT/company/${companyId}/graph`);
    dispatch({
      type: "GET_NCT_COMPANY_GRAPH",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DRUG",
      value: err,
    });
  }
  dispatch({
    type: "SET_NCT_COMPANY_GRAPH_LOADING_ANIMATION",
    value: false,
  });
};

export const getNCTCompanyTab = (companyId) => async (dispatch) => {
  try {
    const { data } = await api.get(`data/NCT/company/${companyId}`);
    dispatch({
      type: "GET_NCT_COMPANY_TAB",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DRUG",
      value: err,
    });
  }
  dispatch({
    type: "SET_NCT_COMPANY_TAB_LOADING_ANIMATION",
    value: false,
  });
};

export const getCompanyDetailsGraph = (companyId) => async (dispatch) => {
  try {
    const { data } = await api.get(`data/company/${companyId}/details`);
    dispatch({
      type: "GET_DETAILS_COMPANY_GRAPH",
      value: data,
    });
  } catch (err) {
    dispatch({
      type: "ERROR_DATA_DRUG",
      value: err,
    });
  }
  dispatch({
    type: "SET_GRAPH_PHASE_COMPANY_LOADING_ANIMATION",
    value: false,
  });
};

export const getNCTCompanyDetailsGraph =
  (date, nb_drugs, filtre, metric1, metric2, period = "day") =>
  async (dispatch) => {
    try {
      const { data } = await api.get("data/volume/drug/treemap", {
        params: {
          date: format(new Date(date), "yyyy-MM-dd"),
          nb: nb_drugs,
          filtre: filtre,
          metric1: metric1,
          metric2: metric2,
          period: period,
        },
      });
      dispatch({
        type: "GET_DATA_TREEMAP_TD",
        value: data,
      });
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_DRUG",
        value: err,
      });
    }
    dispatch({
      type: "SET_TM_LOADING_ANIMATION",
      value: false,
    });
  };


  export const setNCTCompanyDetailsGraph = (companyId) => async (dispatch) => {
    try {
      const { data } = await api.get(`data/company/${companyId}/NCT/details`);
      dispatch({
        type: "GET_DETAILS_COMPANY_GRAPH",
        value: data,
      });
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_DRUG",
        value: err,
      });
    }
    dispatch({
      type: "SET_DETAILS_COMPANY_GRAPH_ANIMATION",
      value: false,
    });
  };
  
  export const getRSSCompanyTab = (companyId) => async (dispatch) => {
    try {
      const { data } = await api.get(`data/RSS/company/${companyId}`);
      dispatch({
        type: "GET_RSS_COMPANY_TAB",
        value: data,
      });
    } catch (err) {
      dispatch({
        type: "ERROR_DATA_DRUG",
        value: err,
      });
    }
    dispatch({
      type: "SET_RSS_COMPANY_TAB_LOADING_ANIMATION",
      value: false,
    });
  };