import React  from 'react';
import { useDispatch} from 'react-redux'
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';

import {setTDMetric1 } from '../../../Action'


  const colorSecondary = {
    "& .Mui-checked + .MuiSwitch-track": {
      backgroundColor: '#f16739'
    },
    "& .MuiSwitch-track" : {
      backgroundColor: '#f16739'
    },
    color: 'success.main',
   '& .MuiSwitch-thumb' : {
      backgroundColor: '#f16739'
    }
  }


export function SliderMetricsDisplay() {
  const dispatch = useDispatch();

  const [state, setState] = React.useState({
    sentiment: false,
    volumechange: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    if (state.sentiment)
        dispatch(setTDMetric1("volume_last_week"))
    else
    dispatch(setTDMetric1("deviation_volume_last_week_52"))
  };

  return (
      <div className='slider'>
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item>Sentiment</Grid>
          <Grid item>
            <Switch 
            color='default'
           sx={colorSecondary}
         value="sentiment" checked={state.sentiment} onChange={handleChange} name="sentiment" />
          </Grid>
          <Grid item>Trend Score</Grid>
        </Grid>
    </div>
  );
}