import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";

import { DataFilterTable, LoadingAnimation } from "../../components";
import { filterDataDrug, get_filter_key } from "../../utils";

import { getCongressDrug, setCongressTDLoadingAnimation } from "../../Action";

export function AllDrugCongressTable() {
  const sentimentFontColor = ["#555e68","#1e3628","#690505","#0b361d" ,"#4a0101"];
  const sentimentBackgroundColor = ["#d9d9d9","#94deb4","#f49999","#2abc68", "#fa5252"];
  const dispatch = useDispatch();
  const name = useSelector((state) => state.congress.congressName);
  const date = useSelector((state) => state.congress.congressYears);
  const navigate = useNavigate();

  const disease = useSelector((state) => state.diease.diseaseDisplay);
  const type = useSelector((state) => state.diease.drugTypeDisplay);
  const targetDisplay = useSelector((state) => state.diease.drugTargetDisplay);
  const theraFranchise = useSelector(
    (state) => state.diease.theraFranchiseDisplay
  );
  const phase = useSelector((state) => state.diease.statusDrugDisplay);
  const load = useSelector((state) => state.config.tabCongressDrugLoading);
  const nameitem = useSelector((state) => state.congress.congressDrug);
  const data = filterDataDrug(
    nameitem,
    phase,
    disease,
    type,
    [],
    theraFranchise,
    targetDisplay
  );

  useEffect(() => {
    if (name !== null) {
      dispatch(getCongressDrug(name.id_conference, date));
      dispatch(setCongressTDLoadingAnimation(true));
    }
  }, [name, date, dispatch]);

  const go_to = (name, id) => {
    if (name === "drug") {
      navigate(`/drug/${id}`);
    }
    if (name === "company") {
      navigate(`/company/${id}`);
    }
  };

  const columns = [
    {
      name: "drug_true_name",
      header: "Drug",
      defaultFlex: 1,
      minWidth: 140,
      render: ({ value, data }) => (
        <span className="title-link" onClick={() => go_to("drug", data.id_drug)}>{value}</span>
      ),
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("drug_true_name", data),
      },
    },
    {
      name: "last_phase",
      header: "Phase",
      minWidth: 110,
      maxWidth: 130,
      defaultFlex: 1,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("last_phase", data),
      },
    },
    {
      name: "disease_display_name",
      header: "Disease",
      defaultFlex: 1,
      minWidth: 190,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("disease_display_name", data),
      },
    },
    {
      name: "drug_type",
      header: "Drug Type",
      minWidth: 155,
      defaultFlex: 1,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("drug_type", data),
      },
    },
    {
      name: "company_display_name",
      header: "Company",
      defaultFlex: 1,
      minWidth: 110,
      render: ({ value, data }) => (
        <span className="title-link" onClick={() => go_to("company", data.id_company_lead)}>{value}</span>
      ),
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("company_display_name", data),
      },
    },
    {
      name: "sentiement",
      header: "Sentiment",
      minWidth: 110,
      maxWidth: 130,    
      defaultFlex: 1,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: get_filter_key("sentiement", data),
      },
      render: ({ value }) => {
        return (
          <div className="all-drug-trending-table--sentiment_cells"
            style={{
              color: value === "positive" ? sentimentFontColor[1] : (value === "negative" ? sentimentFontColor[2] : (value === "very postive" ? sentimentFontColor[3] : (value === "very negative" ? sentimentFontColor[4] : sentimentFontColor[0]))) ,
              background: value === "positive" ? sentimentBackgroundColor[1] : (value === "negative" ? sentimentBackgroundColor[2] : (value === "very postive" ? sentimentBackgroundColor[3] : (value === "very negative" ? sentimentBackgroundColor[4] : sentimentBackgroundColor[0]))) ,
            }}
          >
            {value}
          </div>
        );
      },
    },
    {
      name: "volume",
      header: "Volume",
      minWidth: 70,
      maxWidth: window.innerWidth === 1280 ? 70 : 90,
      defaultFlex: 1,
      onRender: (cellProps, { data }) => {
        cellProps.style.display = "flex";
        cellProps.style.textAlign = 'center';
      },
    },
  ];

  const filterValue = [
    { name: "drug_true_name", operator: "inlist", type: "select", value: "" },
    { name: "last_phase", operator: "inlist", type: "select", value: "" },
    {
      name: "disease_display_name",
      operator: "inlist",
      type: "select",
      value: "",
    },
    { name: "drug_type", operator: "inlist", type: "select", value: "" },
    {
      name: "company_display_name",
      operator: "inlist",
      type: "select",
      value: "",
    },
    { name: "sentiement", operator: "inlist", type: "select", value: "" },
  ];

  return (
    <div style={{ height: 500, width: "100%" }}>
      {load === false ? (
        <DataFilterTable
          dataSource={data}
          columns={columns}
          filterValue={filterValue}
          defaultFilterName={[]}
        />
      ) : (
        <div className="loading-animation" style={{ height: "500px"}}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
