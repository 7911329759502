import React from "react";

import { ChartIcon } from "../../../../components";

export function LrxBtn(props) {
  const { logo, action_btn, text_btn , my_className } = props;
  return (
    <button onClick={action_btn} className={`${my_className} lrx-btn`}>
      {" "}{text_btn}
      <div className="lrx-btn--icon">
        <span>
          <ChartIcon logo={logo} />
        </span>
      </div>
    </button>
  );
}
