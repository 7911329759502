export function get_nb_drug_by_years(data) {
  const tabsYear = [2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023];
  let largest = 0;

  for (const year of tabsYear) {
    let max_size = 0;

    for (const element of data) {
      const startYear = new Date(element[3]).getFullYear();
      const endYear = new Date(element[4]).getFullYear();

      if (startYear <= year && year <= endYear) {
        max_size++;
      }
    }

    if (max_size > largest) {
      largest = max_size;
    }
  }
  return largest;
}

export function get_nb_drug_by_phase_by_years(years, data) {
  if (!data) return {};
  const nb_drugs = {
    approved: 0,
    "nda/bla": 0,
    "phase 1": 0,
    "phase 2": 0,
    "phase 3": 0,
  };

  data.forEach((element) => {
    const startDate = new Date(element[3]);
    const endDate = new Date(element[4]);
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();

    if (startYear <= years && endYear >= years) {
      const phase = element[1];
      if (phase in nb_drugs) {
        nb_drugs[phase] += 1;
      }
    }
  });

  const drugByYears = { name: years };

  for (const key in nb_drugs) {
    if (nb_drugs[key] > 0) {
      drugByYears[key] = nb_drugs[key];
    }
  }
  return drugByYears;
}

export function get_nb_drug_by_target_by_years(years, data) {
  const drugByYears = {};

  if (data.length === 0) return {};

  for (let i = 0; i < data.length; i++) {
    const element = data[i];
    const startYear = new Date(element[3]).getFullYear();
    const endYear = new Date(element[4]).getFullYear();

    if (startYear <= years && endYear >= years) {
      let targets = element[13].split(" / ");
      for (let j = 0; j < targets.length; j++) {
        const target = targets[j];
        if (target !== "-") {
          if (drugByYears[target] === undefined) {
            drugByYears[target] = 1;
          } else {
            drugByYears[target]++;
          }
        }
      }
    }
  }

  Object.keys(drugByYears).forEach((key) => {
    if (drugByYears[key] < 2) delete drugByYears[key];
  });
  const dataClean = 
    {
      name: years,
      ...drugByYears,
    }

  return dataClean ;
}

export function get_nb_target_by_years(data) {
  let largest = 0;

  const years = [2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023];

  for (const year of years) {
    let max_size = 0;

    for (const element of data) {
      const startDate = new Date(element[3]);
      const endDate = new Date(element[4]);
      const startYear = startDate.getFullYear();
      const endYear = endDate.getFullYear();
      if (
        (startYear < year && endYear > year) ||
        (startYear <= year && endYear >= year)
      ) {
        if (element.hasOwnProperty("13") && element["13"] !== "-") {
          max_size++;
        }
      }
    }
    largest = Math.max(largest, max_size);
  }
  return largest;
}

export function get_nb_drug_by_target(data) {
  const drugByYears = {};
  const years = [2016, 2017, 2018, 2019, 2020, 2021, 2022];
  if (data.length === 1) return { keys: [], data: [{}] };
  for (const year of years) {
    for (let i = 1; i < data.length; i++) {
      const element = data[i];
      const startDate = new Date(element[3]);
      const endDate = new Date(element[4]);
      const startYear = startDate.getFullYear();
      const endYear = endDate.getFullYear();
      if (
        (startYear < year && endYear > year) ||
        (startYear <= year && endYear >= year)
      ) {
        let targets = element[13].split(" / ");
        for (let j = 0; j < targets.length; j++) {
          const target = targets[j];
          if (target !== "-") {
            if (drugByYears[target] === undefined) {
              drugByYears[target] = 1;
            } else {
              drugByYears[target]++;
            }
          }
        }
      }
    }
    Object.keys(drugByYears).forEach((key) => {
      if (drugByYears[key] < 2) delete drugByYears[key];
    });
  }

  const unique = Object.keys(drugByYears);

  return { keys: unique, data: drugByYears };
}
