import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getDataTreeMapTD, setTMLoadingAnimation } from "../../Action";
import {
  TreeMapSentiment,
  LoadingAnimation,
  MUItoogleBtn,
} from "../../components";

import { setAllFilterTreemap } from "../../utils";


/*function valuesToArrayD(obj) {
  return obj.map(function (el) { return el['disease_display_name']; });
}

function valuesToArrayM(obj) {
  return obj.map(function (el) { return el['moa']; });
}

function valuesToArrayTF(obj) {
  return obj.map(function (el) { return el['therapeutic_franchise_name']; });
}*/

export function TreeMapTrendingDrug(props) {
  const { filtre, setbtn, which_btn, action_btn } = props;
  const dispatch = useDispatch();
  const d = useSelector((state) => state.drug.treemap);
  const date = useSelector((state) => state.config.date);
  const load = useSelector((state) => state.config.treemapLoading);

  const disease = useSelector((state) => state.diease.diseaseDisplay);
  const type = useSelector((state) => state.diease.drugTypeDisplay);
  const MOADisplay = useSelector((state) => state.diease.MOADisplay);
  const target = useSelector((state) => state.diease.drugTargetDisplay);
  const depth = useSelector((state) => state.diease.statusDrugDisplay);
  const theraFranchise = useSelector(
    (state) => state.diease.theraFranchiseDisplay
  );
  const metric1 = useSelector((state) => state.config.TDmetric1);
  const metric2 = useSelector((state) => state.config.TDmetric2);
  const data = setAllFilterTreemap(
    d,
    disease,
    type,
    MOADisplay,
    depth,
    theraFranchise,
    target
  );

  useEffect(() => {
    if (filtre !== -1) {
      dispatch(
        getDataTreeMapTD(
          date,
          30,
          filtre,
          "volume_last_week",
          "sentiment",
          action_btn[which_btn]
        )
      );
      dispatch(setTMLoadingAnimation(true));
    } else {
      dispatch(
        getDataTreeMapTD(
          date,
          200,
          -1,
          metric1,
          "sentiment",
          action_btn[which_btn]
        )
      );
      dispatch(setTMLoadingAnimation(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, filtre, metric1, metric2, action_btn[which_btn], dispatch]);

  return (
    <div className="tree-map-trending-drug">
      <div className="tree-map-trending-drug--date-selector">
        {" "}
        <MUItoogleBtn
          action_btn={action_btn}
          setbtn={setbtn}
          which_btn={which_btn}
        />{" "}
      </div>
      {load === false ? (
        <div>
          <TreeMapSentiment data={data} filter={"navigation"} />
        </div>
      ) : (
        <div className="loading-animation" style={{ height: "350px" }}>
          <LoadingAnimation type={"circular"} />
        </div>
      )}
    </div>
  );
}
