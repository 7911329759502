import React from "react";
import { useSelector } from "react-redux";
import { VerticalBarChart, LoadingAnimation } from "../../components";

function compterPhasesParFranchise(tableau) {
  const resultat = {};

  tableau.forEach(({ name, phase }) => {
    const franchises = name.split(" / ");
    franchises.forEach((franchise) => {
      if (!resultat[franchise]) {
        resultat[franchise] = {};
      }
      resultat[franchise][phase] = (resultat[franchise][phase] || 0) + 1;
    });
  });
  return resultat;
}

function transformObject(obj) {
  const transformedArray = Object.entries(obj)
    .filter(([key, values]) => key !== "Pharmacology" && key !== "-")
    .map(([th, values]) => {
      return { name: th, ...values };
    });
  return transformedArray;
}

export function AllNCTTherapeuticFranchiseCompanyDetailByPhase(props) {
  const info = useSelector((state) => state.company.nctCompanyTab);
  const load = useSelector((state) => state.config.tabNCTCompanyLoading);

  const resultat = compterPhasesParFranchise(info);
  const transformedArray = transformObject(resultat);

  const datakeys = [
    "phase 1",
    "phase 1/phase 2",
    "phase 2",
    "phase 3",
    "nda/bla",
    "approved",
  ];

  const colors = [
    "#ad345c",
    "#e2823d",
    "#e2823d",
    "#27789E",
    "#2B6530",
    "#59AB46",
  ];
  return (
    <div className="company-graph-pannel__treatement-tabs__details-graph__phase-graph--chart">
      {load === false ? (
        <VerticalBarChart
          data={transformedArray}
          keys={datakeys}
          colors={colors}
        />
        ) : (
        <div className="loading-animation" style={{height:"400px", width:"400px"}}>
          <LoadingAnimation type={"circular"} />
        </div>
        )}  
    </div>
  );
}
