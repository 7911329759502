import React from "react";
import { useSelector } from "react-redux";
import Kalend, { CalendarView } from "kalend"; // import component
import { startOfDay, endOfDay } from "date-fns";
import "kalend/dist/styles/index.css"; // import styles

import { LoadingAnimation } from "../../components";


const color = {
  "PDUFA" : "#B3E2CD",
  "CONGRESS" : "#FDCDAC",
  "TRIAL_COMPLETION" : "#CBD5E8",
  "TRIAL_PRIMARY_COMPLETION" :"#F4CAE4",
  "TRIAL_START" : "#E6F5C9"
}
export function CatalystCompetitiveCalendar(props) {

  const data = useSelector((state) => state.company.catalystTab);
  const load = false;

  const RSScompanyCalendar = data.map(function (el, idx) {
    return  {
      id: idx,
      title: el['company_display_name'],
      startAt: startOfDay(new Date(el['catalyst_date'])).toISOString(), 
      endAt: endOfDay(new Date(el['catalyst_date'])).toISOString(),
      summary: el['catalyst_type'] +" for " + el['drug_display_name'],
      color: color[el['catalyst_type']],
      calendarID: "work",
    }
  });

  const onNewEventClick = (data) => {
    /*const msg = `New event click action\n\n Callback data:\n\n${JSON.stringify({
      hour: data.hour,
      day: data.day,
      startAt: data.startAt,
      endAt: data.endAt,
      view: data.view,
      event: "click event ",
    })}`;*/
    console.log(data);
  };

  const onEventClick = (data) => {
    /*const msg = `Click on event action\n\n Callback data:\n\n${JSON.stringify(
      data
    )}`;*/
    console.log(data);
  };

  const onEventDragFinish = (data) => {
    console.log(data);
  };

  return (
    <div style={{ height: 600, width: "80%", paddingTop: 30, paddingLeft:"20%" }}>
      {load === false ? (
        <Kalend
          kalendRef={props.kalendRef}
          onNewEventClick={onNewEventClick}
          initialView={CalendarView.MONTH}
          disabledViews={["threeDays","week","day"]}
          onEventClick={onEventClick}
          events={RSScompanyCalendar}
          initialDate={new Date().toISOString()}
          hourHeight={60}
          timezone={"Europe/Berlin"}
          onEventDragFinish={onEventDragFinish}
          onStateChange={props.onStateChange}
          selectedView={props.selectedView}
          showTimeLine={true}
          isDark={false}
          autoScroll={true}
        />
      ) : (
        <LoadingAnimation type={"circular"} />
      )}
    </div>
  );
}
