import React from "react";
import { ReactComponent as SaveIcon } from "../../../../Asset/CharteIcone/Icons-Actions-ic-actions-download.svg";
import { ReactComponent as HeartIcon } from "../../../../Asset/CharteIcone/ic-actions-heart.svg";
import { ReactComponent as CalendarIcon } from "../../../../Asset/CharteIcone/ic-actions-calendar.svg";
import { ReactComponent as Favicon } from "../../../../Asset/CharteIcone/Icons-Actions-ic-actions-add-ribbon.svg";
import { ReactComponent as ActionSuccessIconWhite } from "../../../../Asset/CharteIcone/Icons-Actions-ic-actions-multi-success.svg";
import { ReactComponent as DecreaseIcon } from "../../../../Asset/CharteIcone/Decrease-Icon-SVG-098767.svg";
import { ReactComponent as IncreaseIcon } from "../../../../Asset/CharteIcone/Increase-Icon-SVG-09878.svg";
import { ReactComponent as LittleIncreaseIcon } from "../../../../Asset/CharteIcone/littleincrease.svg";
import { ReactComponent as Battery25Icon } from "../../../../Asset/CharteIcone/battery-25.svg";
import { ReactComponent as Battery50Icon } from "../../../../Asset/CharteIcone/battery-50.svg";
import { ReactComponent as Battery75Icon } from "../../../../Asset/CharteIcone/battery-75.svg";
import { ReactComponent as Battery100Icon } from "../../../../Asset/CharteIcone/battery-100.svg";
import { ReactComponent as SearchIcon } from "../../../../Asset/CharteIcone/Icons-Actions-ic-actions-search.svg";
import { ReactComponent as LockedIcon } from "../../../../Asset/CharteIcone/locked.svg";
import { ReactComponent as CrossIcon } from "../../../../Asset/CharteIcone/ic-actions-close-simple.svg";
import { ReactComponent as StarIcon } from "../../../../Asset/CharteIcone/star-icon.svg";


const logo_name = {
  saveIcon: <SaveIcon />,
  hearthIcon: <HeartIcon />,
  calendarIcon: <CalendarIcon />,
  Favicon: <Favicon />,
  ActionSucess: <ActionSuccessIconWhite />,
  DecreaseIcon: <DecreaseIcon/>,  
  IncreaseIcon: <IncreaseIcon/>,
  LittleIncreaseIcon: <LittleIncreaseIcon/>,
  Battery25Icon: <Battery25Icon/>,
  Battery50Icon: <Battery50Icon/>,
  Battery75Icon: <Battery75Icon/>,
  Battery100Icon: <Battery100Icon/>,
  SearchIcon : <SearchIcon/>,
  LockedIcon: <LockedIcon/>,
  CrossIcon : <CrossIcon/>,
  StarIcon : <StarIcon/>

};

export function ChartIcon(props) {
  const { logo } = props;
  return logo_name[logo];
}
